var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WSection',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],attrs:{"margin":true}},[_c('WMaxWidth',{ref:"parent"},[_c('div',{staticClass:"chapters-list"},[_c('div',{staticClass:"chapters-list__item"},[_c('div',{ref:"nav",staticClass:"chapters-list__sticky"},[_c('h2',{staticClass:"chapters-list__title"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n            "),(_vm.title)?_c('sup',{staticClass:"chapters-list__count"},[_vm._v(_vm._s(_vm.countChapters))]):_vm._e()]),_vm._v(" "),_c('div',{ref:"navProgress",staticClass:"chapters-progress",style:('--progress-bar: calc(' +
                _vm.scrollProgress +
                '* var(--progress-height))')},[_c('ul',{staticClass:"chapters-nav"},_vm._l((_vm.progressNav),function(navItem,i){return _c('li',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.chapters[i]),expression:"chapters[i]"}],key:navItem.id,class:[
                  'chapters-nav__item',
                  { 'is-active': navItem.isActive }
                ],style:({ height: navItem.size })},[_c('button',{staticClass:"chapters-nav__action",on:{"click":function($event){return _vm.scrollToSection(i)}}},[_vm._v("\n                  "+_vm._s(navItem.title)+"\n                  "),(navItem.len > 1)?_c('small',{staticClass:"chapters-nav__count"},[_vm._v(_vm._s(navItem.len))]):_vm._e()])])}),0)])])]),_vm._v(" "),_c('div',{staticClass:"chapters-list__item"},_vm._l((_vm.chapters),function(chapter){return _c('div',{key:chapter.id,ref:"chapters",refInFor:true},[(chapter.largeListItems && chapter.largeListItems.length > 0)?_c('ul',{class:[
              'chapter-list',
              {
                'chapter-list--grid':
                  chapter.component == 'ChapterListPerson' ||
                  chapter.component == 'listGroup'
              },
              { 'is-active': _vm.navActive === chapter._uid },
              { 'no-padding-bottom': chapter.largeListItems.length === 1 }
            ]},_vm._l((chapter.largeListItems),function(item){return _c('li',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.observed),expression:"observed"}],key:item.id,staticClass:"chapter-list__item"},[_c(item.component,{directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],tag:"component",attrs:{"blok":item,"highlighted":true}})],1)}),0):_vm._e(),_vm._v(" "),_c('ul',{class:[
              'chapter-list',
              {
                'chapter-list--grid':
                  chapter.component == 'ChapterListPerson' ||
                  chapter.component == 'listGroup'
              },
              { 'is-active': _vm.navActive === chapter._uid },
              { 'no-padding-bottom': chapter.largeListItems.length === 1 }
            ]},_vm._l((chapter.listItems),function(item){return _c('li',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.observed),expression:"observed"}],key:item.id,staticClass:"chapter-list__item"},[_c(item.component,{directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],tag:"component",attrs:{"blok":item,"highlighted":false}})],1)}),0)])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }