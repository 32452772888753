<template>
  <div class="stages-wrapper">
    <div class="stages" ref="stages">
      <div
        v-for="(stage, key, index) in stages"
        :key="stage.id"
        class="stage"
        :class="{ 'stage--active': index <= activeStageNumber }"
      >
        <div
          class="stage__icon"
          :class="{ 'stage__icon--active': index <= activeStageNumber }"
          :ref="index === activeStageNumber ? 'stageActive' : null"
        >
          <IconCheckmark />
        </div>
        <label class="stage__label">{{ stage.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheckmark from "@/components/icons/IconCheckmark.vue";
export default {
  name: "CompTerraXProjectStage",
  components: {
    IconCheckmark,
  },
  props: {
    stage: {
      type: String,
      required: true,
      default: [],
    },
  },
  computed: {
    stages() {
      return this.$store.state.datasources["project-stages-of-development"]
        .entries;
    },
    activeStageNumber() {
      let index = -1;
      const entriesArr = Object.entries(this.stages);
      entriesArr.forEach(([stageKey, stageVal], stageIndex) => {
        if (stageVal.value === this.stage) {
          index = stageIndex;
          return;
        }
      });
      return index;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.createProcess();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    stage() {
      this.$nextTick(() => {
        this.scrollToActiveStage();
      });
    },
  },
  methods: {
    resize() {
      this.scrollToActiveStage();
    },
    scrollToActiveStage() {
      const stages = this.$refs.stages;
      const stagesWidth = stages.getBoundingClientRect().width;
      const stagesScrollLeft = stages.scrollLeft;

      const stageActive = this.$refs.stageActive && this.$refs.stageActive[0];
      if (!stageActive) return;
      const stageActiveWidth = stageActive.getBoundingClientRect().width;
      const stageActiveLeft = stageActive.getBoundingClientRect().left;

      const wantedScrollLeft =
        stagesScrollLeft +
        stageActiveLeft -
        (stagesWidth / 2 - stageActiveWidth / 2);
      stages.scroll({ left: wantedScrollLeft, behavior: "smooth" });
    },
    createProcess() {
      const stageActive = document.querySelectorAll(".stage--active");

      const stages = document.querySelectorAll(".stage");
      let amountOfActiveStages = stageActive.length;

      if (amountOfActiveStages > 1) {
        for (let i = 0; i < amountOfActiveStages; i++) {
          stageActive[i].classList.add("progress");
          if (i == 0) {
            stageActive[i].classList.add("start");
          }
          if (i + 1 == amountOfActiveStages) {
            stageActive[i].classList.add("current");
          }
          if (i + 1 == stages.length) {
            stageActive[i].classList.add("in-market");
          }
        }
      } else {
        stageActive[0].classList.add("progress");
        stageActive[0].classList.add("current");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stages-wrapper {
  position: relative;
  background-color: $color-blue;
  color: $color-white;
  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 0;
    height: 100%;
    width: 6.4rem;
    z-index: 1;
  }
  &::before {
    left: 0;
    @include scrimGradient($color-blue, $direction: "to right");
  }
  &::after {
    right: 0;
    @include scrimGradient($color-blue, $direction: "to left");
  }
}
.stages {
  white-space: nowrap;
  overflow-x: scroll;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.stage {
  position: relative;
  display: inline-block;
  margin-top: 2.8rem;
  margin-bottom: 5.6rem;
  @include poly-fluid-sizing(
    (margin-left, margin-right),
    (
      $break-mobile-portrait: 5rem,
      $break-desktop-large: 7rem,
    )
  );

  &__label {
    position: absolute;
    bottom: 0;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    padding-top: 1.2rem;
    white-space: nowrap;
    @extend %lead;
    opacity: 0.5;
  }

  &--active {
    // create line
    &::after {
      content: "";
      width: calc(
        110% + (calc(5rem + 20 * (100vw - 375px) / 1065)) +
          (calc(5rem + 20 * (100vw - 375px) / 1065))
      );
      position: absolute;
      height: 1px;
      top: 50%;
      background: white;
      right: 100%;
      z-index: 0;
      max-width: 165px;
      transform: translateY(-50%);
    }
    // dont show first line
    &:first-child {
      &::after {
        content: "";
        width: 0;
        position: absolute;
        height: 0px;
        display: none;
      }
    }
    // Second line is smaller
    &:nth-child(2) {
      &::after {
        width: calc(
          50% + (calc(5rem + 20 * (100vw - 375px) / 1065)) +
            (calc(5rem + 20 * (100vw - 375px) / 1065))
        );
        max-width: 150px;
      }
    }

    & .stage__label {
      opacity: 1;
    }

    &.progress {
      & .stage__icon {
        opacity: 0;
      }
      &.start {
        & .stage__icon {
          opacity: 1;
          display: block;
          &:after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: white;
            display: block;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 50%;
          }

          & svg {
            display: none;
          }
        }
      }

      &.current {
        & .stage__icon {
          border: 1px solid white;
          opacity: 1;
        }
      }

      &.in-market {
        & .stage__icon {
          border: 1px solid white;
          opacity: 1;
          background: white;
          color: $color-blue;
        }
      }
    }
  }
  &__icon {
    position: relative;
    width: 2.3rem;
    height: 2.3rem;
    border: none;
    border-radius: 999rem;
    opacity: 0;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
