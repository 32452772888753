<template>
  <span :class="['scroll-down-indicator', { hasScrolled }]"
    >Scroll down <IconArrowLeft
  /></span>
</template>

<script>
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
export default {
  name: "IScrollDownIndicator",
  components: {
    IconArrowLeft,
  },
  data() {
    return {
      hasScrolled: false,
    };
  },
  mounted() {
    this.init();
  },
  activated() {
    // Only used if keep-alive is used
    this.init();
  },
  deactivated() {
    // Only used if keep-alive is used
    window.removeEventListener("scroll", this.onScrolled);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrolled);
  },
  methods: {
    init() {
      window.addEventListener("scroll", this.onScrolled);
    },
    onScrolled() {
      this.hasScrolled = true;
      window.removeEventListener("scroll", this.onScrolled);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-down-indicator {
  @extend %lead;
  opacity: 0.5;
  transition: opacity 0.5s cubic-bezier($ease-out-cubic);
  pointer-events: none;
  display: flex;
  align-items: center;
  &.hasScrolled {
    opacity: 0;
  }
  svg {
    position: relative;
    margin-left: 1em;
    transform: rotate(90deg);
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
