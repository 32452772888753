<template>
  <WSection
    :margin="true"
    :class="$options.name"
    v-observe-visibility="observed"
  >
    <WMaxWidth :noPadding="hasLayoutProject">
      <div :class="!hasLayoutProject && 'C'" class="removeAnimation">
        <div class="disclaimerBox">
          <IText
            :label="blok.label"
            :content="blok.content"
            :buttons="blok.buttons"
            :inverted="false"
            :fontB1="true"
          ></IText>
        </div></div
    ></WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
import ILink from "@/components/items/I-Link.vue";

export default {
  name: "compDisclaimer",
  props: {
    blok: {
      type: Object,
      required: true
    },
    transition: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showAnimationFor: {
        a: false,
        b: false,
        c: false
      },
      hasLayoutProject: false
    };
  },
  methods: {
    isViewableNow(isVisible, entry, section) {
      this.showAnimationFor[section] = isVisible;
    },
    observed(isVisible, entry) {
      if (this.transition) {
        this.animateIn(isVisible, entry);
      }
    }
  },
  components: {
    WSection,
    WMaxWidth,
    IText,
    ILink
  },
  mounted() {
    // Check if current Layout is LayoutProject, if true then no padding
    this.hasLayoutProject = this.$store.state.layout === "LayoutProject";
  }
};
</script>
<style lang="scss" scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}
.dark {
  background-color: $color-astra-black;
  color: $color-white;
}
.W-MaxWidth > div {
  padding-left: 0 !important;
  padding-right: calc(2rem + 60 * (100vw - 375px) / 1065);
}
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas: "cont cont cont cont cont cont cont cont cont cont cont cont";

  @media (min-width: $break-mobile-landscape) {
    grid-template-areas: ". cont cont cont cont cont cont cont cont cont cont .";
  }

  @media (min-width: $break-tablet-landscape) {
    grid-template-areas: ". . cont cont cont cont cont cont cont cont . .";
  }

  @media (min-width: $break-desktop-small) {
    grid-template-areas: ". . . cont cont cont cont cont cont . . .";
  }

  > div {
    grid-area: cont;
  }
}

.disclaimerBox {
  border: 0.1rem solid $color-blue;
  background-color: $color-white;
  color: $color-blue;
  padding: 2.7rem 2.7rem;
}

// Removed animation from richtext
.removeAnimation {
  & /deep/ .richtext {
    transition: none;
  }
  & /deep/ .text__button-list {
    transition: none;
  }
}
</style>
