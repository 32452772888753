<template>
  <WSection :margin="true" :class="$options.name">
    <div :class="['C']">
      <div>
        <IText
          :label="blok.label"
          :content="blok.content"
          :inverted="false"
          :fontB1="true"
        ></IText>
      </div>
    </div>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import IText from "@/components/items/I-Text.vue";
export default {
  name: "compTerraXArticle",
  components: {
    WSection,
    IText,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas: "cont cont cont cont cont cont cont cont cont cont cont cont";

  > div {
    grid-area: cont;
  }
}
</style>
