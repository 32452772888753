<template>
  <main v-editable="blok" :key="blok._uid">
    <CompTerraXBanner :blok="blok" />
    <CompProjectStage :stage="blok.stageOfDevelopment" />

    <WMaxWidth class="wrapper article-wrapper">
      <article class="article">
        <WSection>
          <p class="subheading h4" v-observe-visibility="observed">
            {{ blok.shortDescription }}
          </p>
          <component
            v-for="item in blok.body"
            :key="item._uid"
            :blok="item"
            :is="item.component"
            v-editable="item"
          />
        </WSection>
      </article>
      <aside class="aside">
        <!--<div class="sticky-bottom-helper"></div>-->
        <WSection class="aside__content">
          <CompProjectAside :blok="blok" />
        </WSection>
      </aside>
    </WMaxWidth>
  </main>
</template>

<script>
import CompTerraXBanner from "@/components/terra-x/projectsAndInvestors/CompTerraXBanner";
import CompTerraXArticle from "@/components/terra-x/projectsAndInvestors/CompTerraXArticle";
import CompProjectStage from "@/components/terra-x/projectsAndInvestors/CompProjectStage";
import CompProjectDocumentation from "@/components/terra-x/projectsAndInvestors/CompProjectDocumentation";
import CompProjectOrganisation from "@/components/terra-x/projectsAndInvestors/CompProjectOrganisation";
import CompProjectFeaturedOn from "@/components/terra-x/projectsAndInvestors/CompProjectFeaturedOn";
import CompTeam from "@/components/terra-x/projectsAndInvestors/CompTeam";
import CompDisclaimer from "@/components/comps/CompDisclaimer";
import CompProjectAside from "@/components/terra-x/projectsAndInvestors/CompProjectAside";

import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";

export default {
  name: "LayoutProject",
  components: {
    CompTerraXBanner,
    CompArticle: CompTerraXArticle,
    CompProjectStage,
    CompProjectDocumentation,
    CompProjectFeaturedOn,
    CompProjectOrganisation,
    CompTeam,
    CompDisclaimer,
    CompProjectAside,
    WSection,
    WMaxWidth
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .wrapper > div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas:
    "side side side side side side side side side side side side"
    "main main main main main main main main main main main main";
  @media (min-width: $break-tablet-portrait) {
    grid-template-areas: "main main main main main main main side side side side side";
  }
  @media (min-width: $break-tablet-landscape) {
    grid-template-areas: ". main main main main main main . side side side side";
  }
}
.article {
  grid-area: main;
}
.aside {
  grid-area: side;
  &__content {
    position: sticky;
    top: 0;
    @media (min-width: $break-tablet-portrait) {
      margin-left: 2rem;
      position: sticky;
      @include poly-fluid-sizing(
        bottom,
        (
          $break-mobile-portrait: $nav-height - $padding-mobile,
          $break-desktop-large: $nav-height - $padding-desktop,
          $break-widescreen: $nav-height - $padding-widescreen
        )
      );
    }
    @media (min-width: $break-tablet-landscape) {
      margin-left: 0;
    }
    @media (min-width: $break-nav) {
      bottom: -$padding-desktop/2;
    }
  }
}
</style>
