<template>
  <script type="application/javascript" :src="blok.shorthandstories_url"></script>
</template>

<script>

export default {
  name: "LayoutShorthand",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
