<template>
  <WSection :margin="true" class="grid__wrap">
    <WMaxWidth>
      <div class="grid">
        <div class="grid__content">
          <IText
            :title="blok.title"
            :titleH3="true"
            :content="blok.description"
            :inverted="false"
            :fontB1="true"
          />
          <a
            target="_blank"
            :href="blok.link.filename"
            class="download-link"
            v-observe-visibility="observed"
            >{{ blok.linkText }}</a
          >
        </div>
        <div class="grid__slider" v-observe-visibility="observed">
          <RSlider :slides="blok.images" :autoPlay="autoPlay" />
        </div>
      </div>
    </WMaxWidth>
    <!-- <pre>{{ blok }}</pre> -->
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
import RSlider from "@/components/references/R-Slider.vue";

export default {
  name: "CompSealCarousell",
  components: {
    WSection,
    WMaxWidth,
    IText,
    RSlider
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  computed: {
    autoPlay() {
      let auto = false;
      if (this.blok.autoPlay === "true") {
        auto = true;
      }
      return auto;
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  grid-template-areas:
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2"
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1";

  max-width: 560px;
  margin: 0 auto;
  @media (min-width: $break-tablet-landscape) {
    max-width: none;
    margin: 0;
    grid-template-areas: "se1 se1 se1 se1 se1 se1  .  se2 se2 se2 se2 se2";
  }

  @media (min-width: $break-tablet-landscape) {
    grid-template-areas: " .  se1 se1 se1 se1 se1  .  se2 se2 se2 se2  . ";
  }
  &__wrap {
    @media (min-width: $break-tablet-landscape) {
      min-height: 100vh;
    }
  }
  &__slider {
    grid-area: se1;
    position: relative;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
  }
  &__content {
    padding-top: 3rem;
    padding-bottom: 3rem;
    grid-area: se2;
    align-self: center;
    & /deep/ h3 {
      margin-bottom: 0.8rem;
    }
  }
}
.download-link {
  display: inline-block;
  margin-top: 2.5rem;
  position: relative;
  color: $color-blue;
  text-transform: uppercase;
  font-size: 1.4rem;
  &:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C8.02219 0 6.08879 0.586489 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433283 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.80429 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.0645C8.40499 18.0645 6.8458 17.5915 5.5196 16.7054C4.1934 15.8193 3.15975 14.5598 2.54936 13.0862C1.93898 11.6126 1.77927 9.99105 2.09045 8.42669C2.40162 6.86233 3.16969 5.42537 4.29753 4.29752C5.42537 3.16968 6.86233 2.40161 8.42669 2.09044C9.99106 1.77927 11.6126 1.93897 13.0862 2.54936C14.5598 3.15974 15.8193 4.19339 16.7054 5.51959C17.5915 6.8458 18.0645 8.40499 18.0645 10C18.0645 12.1388 17.2149 14.1901 15.7025 15.7025C14.1901 17.2149 12.1388 18.0645 10 18.0645Z' fill='%23031F35'/%3E%3Cpath d='M10.9675 11.5353V5.16113H9.03206V11.5353L6.77399 9.27726L5.40625 10.645L9.31593 14.5547C9.49738 14.7359 9.9998 15.1603 9.9998 15.1603C9.9998 15.1603 10.5022 14.7359 10.6837 14.5547L14.5933 10.645L13.2256 9.27726L10.9675 11.5353Z' fill='%23031F35'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    position: absolute;
    right: -30px;
    top: -3px;
  }
}
</style>
