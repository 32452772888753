<template>
  <div v-if="blok.supportersList">
    <WListSimple
      :title="blok.label"
      :titleH3="false"
      :countIt="list.length"
      :listItems="list"
      :is-grid="true"
    >
      <template v-slot:default="{ listItem }">
        <!-- <IListItem :blok="listItem"/> -->
        <RPerson :blok="listItem" :highlighted="false" />
      </template>
    </WListSimple>
    <!-- <w-list-chapters
      :title="blok.label"
      :chapters="blok.supportersList"
    ></w-list-chapters> -->
  </div>
</template>

<script>
import WListSimple from "@/components/wrappers/W-ListSimple.vue";
import RPerson from "@/components/references/R-Person.vue";
export default {
  name: "compSupporters",
  components: {
    WListSimple,
    RPerson,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    list() {
      return this.blok.supportersList.content.listGroups.sort(
        this.dynamicSort("label")
      );
    },
  },
  methods: {
    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === "-" || property[0] === " ") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return (a, b) => {
        const one = a[property].toLowerCase();
        const two = b[property].toLowerCase();

        // If no label add last
        let result = -1;
        // Else sort as normal
        if (one && two) {
          result = one < two ? -1 : one > two ? 1 : 0;
        }

        return result * sortOrder;
      };
    },
  },
};
</script>
