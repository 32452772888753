<template>
  <div>
    <div class="main-outer">
      <div
        class="terra-x-nav-list-wrapper"
        :class="{ 'terra-x-nav-list-wrapper--globe-open': isGlobeOpen }"
      >
        <TerraXBackgroundBar />
        <TerraXNav>
          <div
            :class="[
              'terra-x-grid',
              { 'terra-x-grid--list': !isGlobeOpen },
              { 'terra-x-grid--filter': isFilterOpen }
            ]"
          >
            <div class="terra-x-grid__item" v-if="!isGlobeOpen">
              <TerraXList
                v-if="!isGlobeOpen && filteredData.output.length > 0"
                :x-type="blok.type"
                :x-data="filteredData.output"
                :x-interactive="true"
                :x-filter-active="false"
                @callback="listTalk"
              />
              <TerraXNoResults v-if="filteredData.output.length < 1" />
            </div>
          </div>
        </TerraXNav>
      </div>
    </div>
    <component
      v-for="(item, index) in blok.body"
      :key="item._uid"
      :blok="item"
      :isFirst="index === 0 ? true : false"
      :is="item.component"
      v-editable="item"
    />
  </div>
</template>

<script>
import TerraXBackgroundBar from "@/components/terra-x/TerraXBackgroundBar";
import TerraXNav from "@/components/terra-x/nav/TerraXNav";
import TerraXList from "@/components/terra-x/TerraXList.vue";
import TerraXNoResults from "@/components/terra-x/TerraXNoResults.vue";
import CompDisclaimer from "@/components/comps/CompDisclaimer";

export default {
  name: "LayoutTerraXFind",
  props: {
    blok: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    TerraXBackgroundBar,
    TerraXNav,
    TerraXList,
    TerraXNoResults,
    CompDisclaimer
  },
  computed: {
    query() {
      return this.$route.query;
    },
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    isFilterOpen() {
      return this.$store.getters["terrax/isFilterOpen"]();
    },
    filteredData() {
      return this.$store.getters["terrax/filteredData"]();
    }
  },
  watch: {
    query(newVal) {
      this.$store.dispatch("terrax/setStateFromQuery", newVal);
    }
  },
  mounted() {
    this.$store.commit("terrax/setQueryType", this.blok.type);
    this.$store.dispatch("terrax/setStateFromQuery", this.$route.query);
  },
  methods: {
    globeTalk() {},
    listTalk() {}
  }
};
</script>

<style lang="scss" scoped>
.terra-x {
  padding-top: 30px;
  min-height: calc(100vh - var(--nav-height) - #{$nav-height * 1});
}
.terra-x-grid {
  // padding: 0 20px;
  display: flex;
  flex: 0 auto;
  &--list {
    display: block;
    position: relative;
    // z-index: 2;
  }
  &__item {
    // z-index: 2;
    // &:first-child {
    //   max-height: 0;
    //   width: 0;
    //   transition: 0.3s width ease;
    //   overflow: hidden;
    //   .terra-x-grid--filter & {
    //     max-height: 500px;
    //     width: 40rem;
    //     max-width: 100%;
    //   }
    //   .terra-x-grid--list.terra-x-grid--filter & {
    //     max-height: 500px;
    //     width: 100%;
    //     max-width: 100%;
    //   }
    // }
  }
}
</style>
