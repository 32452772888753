<template>
  <div class="st-text">
    <IText
      :icon="blok.icon"
      :title="blok.title"
      :titleH3="true"
      :content="blok.description"
      :inverted="false"
      :fontB1="true"
    />
  </div>
</template>

<script>
import IText from "@/components/items/I-Text.vue";
export default {
  components: {
    IText,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.st-text {
  & /deep/ .text__icon {
    width: auto;
    height: 31px;
    @media (min-width: $break-mobile-landscape) {
      height: 40px;
    }
  }
}
</style>