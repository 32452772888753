<template>
  <iframe
    :src="vimeoUrl"
    :width="vimeoWidth"
    :height="vimeoHeight"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen=""
    :title="vimeoTitle"
  ></iframe>
</template>

<script>
export default {
  props: {
    vimeoId: {
      type: Number,
      default: 0
    },
    vimeoTitle: {
      type: String,
      default: "Vimeo video"
    },
    vimeoWidth: {
      type: Number,
      default: 0
    },
    vimeoHeight: {
      type: Number,
      default: 0
    }
  },
  computed: {
    vimeoUrl() {
      return (
        "https://player.vimeo.com/video/" +
        this.vimeoId +
        "?muted=1&amp;autoplay=1&amp;autopause=0&amp;controls=0&amp;loop=1&amp;app_id=122963&background=1"
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
