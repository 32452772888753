<template>
  <div
    :class="[$options.name, { notPlaying: !playing, hasPlayed: hasPlayed }]"
    v-observe-visibility="visibility"
    ref="videoSourceSet"
  >
    <video autoplay muted loop playsinline :src="src"></video>
    <WVideoPosterWrapper
      @click="onStartPlay"
      class="poster-wrapper"
      :showPoster="showPoster && !playing"
      :showPlayBtn="enablePlayBtn"
    >
      <img v-if="posterSrc" :src="posterSrc" class="poster" />
    </WVideoPosterWrapper>
  </div>
</template>

<script>
import WVideoPosterWrapper from "@/components/wrappers/W-VideoPosterWrapper.vue";
export default {
  name: "W-VideoSourceSet",
  components: {
    WVideoPosterWrapper,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    poster: {
      type: [String, Boolean],
      default: false,
    },
    shouldBePlaying: {
      type: Boolean,
      required: false,
      default: true,
    },
    enableAutoPlayInPowerSaveMode: {
      type: Boolean,
      default: false,
    },
    enablePlayBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoElement: null,
      playing: true,
      loading: false,
      hasPlayed: false,
      isVisible: false,
      showPoster: false,
    };
  },
  computed: {
    src() {
      return this.video.filename;
    },
    posterSrc() {
      return this.poster;
    },
    play() {
      return this.shouldBePlaying && this.isVisible;
    },
  },
  beforeMount() {},
  mounted() {
    // We need to find the video the old fashioned way, as we LazyHydrate it, so it is never accessible through vue
    setTimeout(() => {
      this.videoElement =
        this.$refs.videoSourceSet.getElementsByTagName("video")[0];

      if (this.videoElement.paused) {
        // this.notPlaying = true;
        this.playing = false;

        this.videoElement.addEventListener("suspend", this.onSuspend);
        this.videoElement.addEventListener("playing", this.onPlaying);
      } else {
        this.hasPlayed = true;
      }
    }, 100);
  },
  beforeDestroy() {
    if (this.videoElement) {
      this.videoElement.removeEventListener("suspend", this.onSuspend);
      this.videoElement.removeEventListener("playing", this.onPlaying);
      this.removeListeners();
    }
  },
  activated() {
    // Only used if keep-alive is used
  },
  deactivated() {
    // Only used if keep-alive is used
  },
  watch: {
    play(val) {
      if (val) {
        if (this.videoElement) {
          this.videoElement.play();
        }
      } else {
        if (this.videoElement) {
          this.videoElement.pause();
        }
      }
    },
  },
  methods: {
    onStartPlay() {
      console.log("onStartPlay");
      this.startLoop();
    },
    onPlaying() {
      console.log("onPlaying");
      this.hasPlayed = true;
      this.playing = true;
      this.showPoster = false;
      if (this.videoElement) {
        this.videoElement.removeEventListener("playing", this.onPlaying);
      }
      // remove event listeners when video has started playing
      this.removeListeners();
    },
    onSuspend() {
      console.log("onSuspend");
      this.playing = false;
      this.showPoster = true;
      // this.notPlaying = true;

      if (this.enableAutoPlayInPowerSaveMode) {
        this.addListeners();
      }
    },
    visibility(isVisible, entry) {
      this.isVisible = isVisible;
    },
    addListeners() {
      //console.log("addListeners")
      window.addEventListener("click", this.startLoop);
      window.addEventListener("touchstart", this.startLoop);
      window.addEventListener("touchend", this.startLoop);
      window.addEventListener("touchcancel", this.startLoop);
      window.addEventListener("touchmove", this.startLoop);
      window.addEventListener("scroll", this.startLoop);
    },
    removeListeners() {
      //console.log("removeListeners")
      window.removeEventListener("click", this.startLoop);
      window.removeEventListener("touchstart", this.startLoop);
      window.removeEventListener("touchend", this.startLoop);
      window.removeEventListener("touchcancel", this.startLoop);
      window.removeEventListener("touchmove", this.startLoop);
      window.removeEventListener("scroll", this.startLoop);
    },
    startLoop() {
      if (this.videoElement) {
        if (!this.videoElement.paused) {
          this.hasPlayed = true;
        } else {
          this.videoElement.play();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.W-VideoSourceSet {
  width: 100%;
  height: 100%;
  // background-color: mix(white, $color-blue-light, 80%);
  .poster {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 25%;
  }
  video {
    position: relative;
    width: 100%;
    height: 100%;
    //height: 100.1%; // Hack that removes a gap that sometimes appear at the bottom of videos set to cover. In this case it reveals the background of the parent.
    object-fit: cover;
    object-position: 50% 25%;
    pointer-events: none;
    //opacity: 0;

    &::-webkit-media-controls {
      display: none !important;
      -webkit-appearance: none;
    }
    &::-webkit-media-controls-play-button {
      display: none !important;
      -webkit-appearance: none;
    }
    &::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none;
    }
  }
  &.notPlaying {
    video {
      opacity: 0;
    }
    &.hasPlayed {
      video {
        //transition: opacity 0.5s cubic-bezier($ease-out-cubic);
        opacity: 1;
      }
    }
  }
  &.hasPlayed {
    .poster-wrapper {
      display: none;
    }
  }
}
</style>
