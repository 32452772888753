<template>
  <div class="scroll-indicator-mobile">
    <div class="scroll-indicator-mobile__content">
      <div class="scroll-indicator-mobile__content-line">
        <div class="scroll-indicator-mobile__content-line-inner" ref="indicator"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SealScrollCircle",
  data() {
    return {
      windowHeight: 0,
      docHeight: undefined,
      scrollY: undefined
    };
  },
  methods: {
    handleResize() {
      setTimeout(() => {
        this.setDocHeight();
      }, 100);
    },
    handleScroll() {
      this.handleScrollAnimationFrame();
    },
    handleScrollAnimationFrame() {
      if (window.scrollY < 0) {
        this.scrollY = 0;
      } else {
        this.scrollY = window.scrollY;
      }
      this.requestAnimationFrame();
    },
    setDocHeight() {
      const scrollParent = document.body.getBoundingClientRect();
      this.windowHeight = window.innerHeight - 200;
      this.docHeight = Number(scrollParent.height);
    },
    requestAnimationFrame() {
      let calcScrollAmount = 100 / (this.docHeight - window.innerHeight);
      let scrollIndicatorSize = calcScrollAmount * this.scrollY;

      if (scrollIndicatorSize > 100) {
        scrollIndicatorSize = 100;
      }
      this.$refs.indicator.style.width = scrollIndicatorSize + "%";
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);

    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.scroll-indicator-mobile {
  pointer-events: none;
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 1880px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  &__content {
    &-line {
      position: absolute;
      left: 0;
      top: 8rem;
      width: 100%;
      height: 3px;
      background: #f0f0f0;
      &-inner {
        position: relative;
        left: 0;
        top: 0;
        width: 0px;
        height: 3px;
        background: $color-blue;
        transition: width 0.15s linear;
      }
    }
  }
}
</style>
