<template>
  <a
    v-if="extUrl"
    class="link"
    :href="extUrl"
    target="_blank"
    rel="noopener"
    :class="`link__${iconType} link__${iconPosition}`"
    ><slot
  /></a>
  <nuxt-link
    v-else
    class="link"
    :to="url"
    :class="`link__${iconType} link__${iconPosition}`"
    ><slot
  /></nuxt-link>
</template>

<script>
export default {
  name: "",
  props: {
    extUrl: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    iconType: {
      type: String,
      default: "arrow",
      validator: value => {
        return ["arrow", "download", "external"].indexOf(value) !== -1;
      }
    },
    iconPosition: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  width: 100%;
  cursor: pointer;
  color: $color-blue;
  @media (min-width: $break-tablet-portrait) {
    background-position: right 2rem bottom 2rem;
    background-size: 2.4rem;
    background-repeat: no-repeat;
    transition: 0.3s ease;
  }
  &__top {
    @media (min-width: $break-tablet-portrait) {
      background-position: right 2rem top 2rem;
      background-size: 2rem;
      background-repeat: no-repeat;
      transition: 0.3s ease;
    }
  }
  &__arrow {
    @media (min-width: $break-tablet-portrait) {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg clip-path='url(%23arrowleft_2099)'%3E%3Cpath d='M0 13h20.2l-5.6 5.6L16 20l8-8-8-8-1.4 1.4 5.6 5.6H0v2z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='arrowleft_2099'%3E%3Cpath fill='currentColor' d='M0 0h24v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      &:hover {
        background-position: right bottom 2rem;
        &.link__top {
          background-position: right 1rem top 2rem;
        }
      }
    }
  }
  &__download {
    @media (min-width: $break-tablet-portrait) {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6 11.6L13 14.2V2H11V14.2L8.4 11.6L7 13L12 18L17 13L15.6 11.6Z' fill='currentColor'/%3E%3Cpath d='M18 22V20H6V22H18Z' fill='currentColor'/%3E%3C/svg%3E%0A");
      &:hover {
        background-position: right 2rem bottom 0;
      }
    }
  }
  &__external {
    $bg-ext-box: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='33px' height='33px' viewBox='0 0 33 33' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFrame 2417%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Frame-2417'%3E%3Crect id='Rectangle' fill-rule='nonzero' x='0' y='0' width='33' height='33'%3E%3C/rect%3E%3Cpath d='M21.5574,17.9444 L21.5574,21.5556 C21.5574,22.3533 20.9106,23 20.1127,23 L11.4447,23 C10.6468,23 10,22.3533 10,21.5556 L10,12.8889 C10,12.0911 10.6468,11.4444 11.4447,11.4444 L15.0564,11.4444 L15.0564,12.8889 L11.4447,12.8889 L11.4447,21.5556 L20.1127,21.5556 L20.1127,17.9444 L21.5574,17.9444 Z' id='Path' fill='%23031F35'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    $bg-ext-arrow: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='33px' height='33px' viewBox='0 0 33 33' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFrame 2417%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Frame-2417'%3E%3Crect id='Rectangle' fill-rule='nonzero' x='0' y='0' width='33' height='33'%3E%3C/rect%3E%3Cpolygon id='Path' fill='%23031F35' points='21.5551 12.4658 16.2872 17.7329 15.2656 16.7115 20.5336 11.4444 17.2211 11.4444 17.2211 10 22.9998 10 22.9998 15.7778 21.5551 15.7778'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    @media (min-width: $break-tablet-portrait) {
      background-image: $bg-ext-box, #{$bg-ext-arrow};
      background-size: 4rem, 4rem;
      background-position: right 2rem top 2rem, right 2rem top 2rem;
      &:hover {
        background-position: right 2rem top 2rem, right 1.8rem top 1.7rem;
      }
    }
  }
}
</style>
