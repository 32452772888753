var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WSection',{class:[
    'compBanner',
    {
      fullHeight: _vm.settingsFullHeight,
      halfHeight: _vm.settingsHalfHeight,
      locked: _vm.isLocked,
      isFirst: _vm.isFirst,
    } ],style:('--full-height: ' + _vm.innerHeight),attrs:{"margins":false}},[_c('WMaxWidth',[_c('div',{class:[
        'C',
        {
          colored: _vm.settingsBlueBackground,
          centered: _vm.settingsCentered,
          hasBackground: _vm.hasBackground,
        } ]},[_c('div',{staticClass:"graphic"},[(_vm.blok.graphic && _vm.blok.graphic.filename)?_c('img',{attrs:{"alt":_vm.blok.graphic.alt,"src":_vm.blok.graphic.filename}}):_vm._e()]),_vm._v(" "),_c('IText',{attrs:{"label":_vm.blok.label,"content":_vm.blok.content,"buttons":_vm.blok.buttons,"inverted":_vm.invertedUiColors,"fontB1":true}}),_vm._v(" "),_c('div',{staticClass:"scroll-indicator-wrapper"},[(this.$route.path == '/' && _vm.settingsFullHeight)?_c('IScrollDownIndicator'):_vm._e()],1)],1)]),_vm._v(" "),_c('WBackground',{attrs:{"type":'fill',"colored":_vm.settingsBlueBackground,"hasBackground":_vm.hasBackground}},[(
        _vm.blok.backgroundVideoLoopUrl && _vm.blok.backgroundVideoLoopUrl.vimeo_data
      )?_c('WVimeoLoopBanner',{attrs:{"vimeoData":_vm.blok.backgroundVideoLoopUrl.vimeo_data,"poster":_vm.blok.backgroundImage}}):_vm._e(),_vm._v(" "),(
        !_vm.blok.backgroundVideoLoopUrl.vimeo_data &&
        _vm.blok.backgroundImage &&
        _vm.blok.backgroundImage.filename
      )?_c('ILazyImage',{staticClass:"banner-image",attrs:{"filename":_vm.blok.backgroundImage.filename,"alt":_vm.blok.backgroundImage.alt,"sizes":"(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)","ratioW":16,"ratioH":9,"classes":"h100"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }