<template>
  <div class="accordion-wrapper">
    <WAccordion
      v-for="(accordion, index) in accordions"
      :key="accordion.title"
      :title="accordion.title"
      :open="openAccordion === index"
      @open="openAccordion = index"
      @close="openAccordion = -1"
      :class="accordion.title"
    >
      <div
        v-for="item in accordion.data"
        :key="item.id"
        class="accordion__item"
        :class="{ 'accordion__item--full-width': item.fullWidth }"
      >
        <span class="accordion__item-label">{{ item.title }}</span>
        <div v-if="item.sdgItems && item.sdgItems.length" class="sdg">
          <IconSdg
            v-for="sdgItem in item.sdgItems"
            :key="sdgItem.id"
            :sdgName="sdgItem"
            class="sdg__icon"
          />
        </div>
        <div v-else-if="item.keyContacts">
          <div
            v-for="contact in item.contacts"
            :key="contact.name"
            class="key-contact"
            :class="{ 'no-image': contact.portrait.filename == '' }"
          >
            <div
              class="key-contact__image"
              v-if="contact.portrait.filename != ''"
            >
              <img :src="contact.portrait.filename" />
            </div>
            <div class="key-contact__information">
              <div>
                <span class="bold">{{ contact.name }}</span>
              </div>
              <div>
                <span class="transparent">{{ contact.jobTitle }}</span>
              </div>
              <div>
                <span class="email">{{ contact.email }}</span>
              </div>
            </div>
          </div>
        </div>
        <span v-else class="body">{{ item.body || defaultEmpty }}</span>
      </div>
      <div class="scroll-to-team">
        <span>For investment inquiries please reach out to the</span>
        <span class="bold" @click="scrollToTeam()">key contacts</span>
      </div>
    </WAccordion>
    <a
      :href="websiteLink"
      target="blank"
      rel="noopener"
      class="website"
      :aria-disabled="!website"
    >
      <span class="website__title">Website</span>
      <span class="website__content">{{ website || defaultEmpty }}</span>
    </a>
  </div>
</template>

<script>
import WAccordion from "@/components/wrappers/W-Accordion.vue";
import IconSdg from "@/components/icons/IconSdgNew.vue";
export default {
  name: "CompTerraXAside",
  components: {
    WAccordion,
    IconSdg,
  },
  props: {
    accordions: {
      type: Array,
      required: true,
    },
    website: {
      type: String,
    },
  },
  data() {
    return {
      openAccordion: 0,
      defaultOpenAccordion: 0,
      defaultEmpty: "--",
      isMobileView: undefined,
    };
  },
  computed: {
    websiteLink() {
      const setHttps = (link) => {
        const regex = /^http[s]?\:\/\//gm;
        const hasProtocol = regex.test(link);
        return hasProtocol ? link : `https://${link}`;
      };
      const siteWithHttps = setHttps(this.website);
      return siteWithHttps;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.checkIfTeamSectionIsActive();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    isMobileView(newVal) {
      // close all accordions on mobile; open first accordion on desktop
      if (newVal) {
        this.openAccordion = -1;
      } else {
        this.openAccordion = this.defaultOpenAccordion;
      }
    },
  },
  methods: {
    resize() {
      const breakpoint = this.$breakpoints.tabletPortrait;
      // is used to set default accordion state
      this.isMobileView = window.innerWidth < breakpoint;
    },
    scrollToTeam() {
      document.querySelector(".scrollToThisTeam").scrollIntoView();
    },
    checkIfTeamSectionIsActive() {
      const accordions = this.accordions;
      let keyContactsWrap = document.querySelector(".team-contacts");
      let financialAccordion = document.querySelector(".Financial");
      let contactsAccordion = document.querySelector(".contacts");

      for (let i = 0; i < accordions.length; i++) {
        const accordionData = accordions[i].data;
        if (!accordionData) return;
        if (accordionData[0].keyContacts) {
          if (keyContactsWrap)
            keyContactsWrap.classList.add("scrollToThisTeam");
          if (financialAccordion) financialAccordion.classList.add("teamShown");
          if (contactsAccordion) contactsAccordion.classList.add("teamShown");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .accordion {
  &:not(:first-child) {
    margin-top: 2.5rem;
    @include poly-fluid-sizing(
      margin-top,
      (
        $break-mobile-portrait: 0.5rem,
        $break-desktop-large: 2.5rem,
      )
    );
  }
  &:not(:last-child) {
    margin-bottom: 2.5rem;
    @include poly-fluid-sizing(
      margin-bottom,
      (
        $break-mobile-portrait: 0.5rem,
        $break-desktop-large: 2.5rem,
      )
    );
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.9rem;
  }
  &__item {
    &--full-width {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  }
  &__item-label {
    display: block;
    margin-bottom: 0.5rem;
    @extend %lead;
    opacity: 0.5;
  }

  & .scroll-to-team {
    display: none;
  }

  &.Financial {
    &.teamShown {
      & .scroll-to-team {
        grid-column-end: span 2;
        display: block;
        cursor: pointer;

        & span {
          opacity: 0.5;

          &.bold {
            opacity: 1;
            font-weight: 600;
          }
        }
      }
    }
  }

  &.contacts {
    & .scroll-to-team {
      grid-column-end: span 2;
      display: block;
      cursor: pointer;
      display: none;

      & span {
        opacity: 0.5;

        &.bold {
          opacity: 1;
          font-weight: 600;
        }
      }
    }
  }
}

.sdg {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.6rem;
  &__icon {
    width: 100%;
  }
}

.website {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @extend %body3;
  background-color: $color-grey-light;
  padding: 1.4rem 2.7rem;
  cursor: pointer;
  &__title {
    font-weight: bold;
  }
  &__content {
    text-decoration: underline;
    overflow-wrap: anywhere;
  }
  &[aria-disabled="true"] {
    pointer-events: none;
    .website__content {
      text-decoration: none;
    }
  }
}

.contacts {
  display: none;

  &.teamShown {
    display: block;
    & .key-contact {
      display: grid;
      grid-template-columns: 70px 1fr;
      grid-gap: 1.5rem;
      margin-bottom: 1.5rem;

      &.no-image {
        grid-template-columns: 1fr;
      }

      &__image {
        position: relative;
        height: 70px;
        width: 70px;
        overflow: hidden;
        & img {
          width: 100%;
        }
      }
      &__information {
        & div {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;

          & .transparent {
            opacity: 0.5;
          }

          & .bold {
            font-weight: 600;
          }

          & .email {
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
