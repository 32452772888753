var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"terra-x-nav-types"},[_c('div',{staticClass:"terra-x-nav-types__wrapper"},[_c('nuxt-link',{staticClass:"terra-x-nav-types__link",attrs:{"to":{
        path: _vm.$urlResolver(_vm.$config.terraXProjectsPage),
        query: {
          globe: _vm.$store.state.terrax.routeQuery.globe,
        },
      }}},[_c('span',[_vm._v("Projects")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"terra-x-nav-types__link",attrs:{"to":{
        path: _vm.$urlResolver(_vm.$config.terraXInsurancePage),
        query: {
          globe: false,
        },
      }}},[_c('span',[_vm._v("Insurance")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }