<template>
  <div class="table-gradient">
    <div class="table-wrapper">
      <table class="table">
        <tr>
          <th>
            <button
              :class="[
                'toggle-investor',
                sortData.byName,
                { active: sortData.byName },
              ]"
              @click="toggleSorting('byName')"
            >
              Investor
            </button>
          </th>
          <th>
            <button
              :class="[
                'toggle-investor',
                sortData.byType,
                { active: sortData.byType },
              ]"
              @click="toggleSorting('byType')"
            >
              Investment type
            </button>
          </th>
          <th>
            <button
              :class="[
                'toggle-investor',
                sortData.byAvailable,
                { active: sortData.byAvailable },
              ]"
              @click="toggleSorting('byAvailable')"
            >
              Investment available
            </button>
          </th>
          <th>
            <button
              :class="[
                'toggle-investor',
                sortData.byReturn,
                { active: sortData.byReturn },
              ]"
              @click="toggleSorting('byReturn')"
            >
              Target return
            </button>
          </th>
        </tr>
        <tr v-for="project in sortedInvestors" :key="project.id">
          <td>
            <nuxt-link :to="$urlResolver(project.url)">
              <div class="investor-logo">
                <div class="investor-logo__image">
                  <ILazyImage
                    v-if="project.image && project.image.filename"
                    :filename="project.image.filename"
                    :alt="project.image.alt"
                    sizes="(min-width: 600px) 30vw, 100vw"
                    :ratioW="16"
                    :ratioH="16"
                  />
                </div>
                <div class="investor-logo__title">
                  {{ project.title }}
                </div>
              </div>
            </nuxt-link>
          </td>
          <td class="investment-type">
            {{
              formatArray(
                project.availableFinancing,
                "investors-available-financing"
              )
            }}
          </td>
          <td>{{ formatCurrency(project.investmentAvailable) }}</td>
          <td>{{ formatCurrency(project.targetReturn) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import asideDataFormatMixin from "~/components/terra-x/projectsAndInvestors/asideDataFormatMixin";
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "TerraXListInvestors",
  components: {
    ILazyImage,
  },
  mixins: [asideDataFormatMixin],
  props: {
    xData: {
      type: Array,
    },
  },
  data() {
    return {
      sortData: {
        byName: "",
        byType: "",
        byAvailable: "",
        byReturn: "",
      },
    };
  },
  methods: {
    toggleSorting(type) {
      if (this.sortData[type] === "desc") {
        this.sortData[type] = "asc";
      } else {
        this.sortData.byName = "";
        this.sortData.byType = "";
        this.sortData.byAvailable = "";
        this.sortData.byReturn = "";
        this.sortData[type] = "desc";
      }
    },
    callback() {
      this.$emit("callback", "some value");
    },
  },
  computed: {
    sortedInvestors() {
      let investors = this.xData;
      if (this.sortData.byName === "desc") {
        investors = this.xData.sort(function (a, b) {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
      } else if (this.sortData.byName === "asc") {
        investors = this.xData.sort(function (a, b) {
          if (a.title < b.title) {
            return 1;
          }
          if (a.title > b.title) {
            return -1;
          }
          return 0;
        });
      } else if (this.sortData.byType === "desc") {
        investors = this.xData.sort(function (a, b) {
          if (a.availableFinancing[0] < b.availableFinancing[0]) {
            return -1;
          }
          if (a.availableFinancing[0] > b.availableFinancing[0]) {
            return 1;
          }
          return 0;
        });
      } else if (this.sortData.byType === "asc") {
        investors = this.xData.sort(function (a, b) {
          if (a.availableFinancing[0] < b.availableFinancing[0]) {
            return 1;
          }
          if (a.availableFinancing[0] > b.availableFinancing[0]) {
            return -1;
          }
          return 0;
        });
      } else if (this.sortData.byAvailable === "desc") {
        investors = this.xData.sort(function (a, b) {
          if (a.investmentAvailable < b.investmentAvailable) {
            return -1;
          }
          if (a.investmentAvailable > b.investmentAvailable) {
            return 1;
          }
          return 0;
        });
      } else if (this.sortData.byAvailable === "asc") {
        investors = this.xData.sort(function (a, b) {
          if (a.investmentAvailable < b.investmentAvailable) {
            return 1;
          }
          if (a.investmentAvailable > b.investmentAvailable) {
            return -1;
          }
          return 0;
        });
      } else if (this.sortData.byReturn === "desc") {
        investors = this.xData.sort(function (a, b) {
          if (a.targetReturn < b.targetReturn) {
            return -1;
          }
          if (a.targetReturn > b.targetReturn) {
            return 1;
          }
          return 0;
        });
      } else if (this.sortData.byReturn === "asc") {
        investors = this.xData.sort(function (a, b) {
          if (a.targetReturn < b.targetReturn) {
            return 1;
          }
          if (a.targetReturn > b.targetReturn) {
            return -1;
          }
          return 0;
        });
      }

      return investors;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-investor {
  color: #fff;
  text-transform: uppercase;
  @extend %body3;
  opacity: 0.5;
  padding-right: 20px;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
  &.active {
    opacity: 1;
    position: relative;
    &:after {
      content: "";
      transition: 0.3s ease;
      top: 50%;
      margin-top: -4px;
      width: 12px;
      height: 7px;
      position: absolute;
      right: 0px;
      transform: rotate(0deg);
      background-position: right center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L6 5.5L11 0.500001' stroke='white'/%3E%3C/svg%3E");
    }
    &.desc:after {
      content: "";
      transform: rotate(180deg);
    }
  }
}
.table-gradient {
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2rem;
    top: 0;
    height: 100%;
    z-index: 2;
  }
  &::after {
    right: -2rem;
    background: linear-gradient(0.75turn, $color-blue, rgba($color-blue, 0));
  }
  &::before {
    left: -2rem;
    background: linear-gradient(-0.75turn, $color-blue, rgba($color-blue, 0));
  }
}
.table-wrapper {
  width: calc(100% + 4rem);
  animation: fadeInUp 500ms ease-in-out forwards; // keyframe in baseApp.scss
  margin-right: -2rem;
  margin-left: -2rem;
  padding-left: 2rem;
  overflow: auto;
  position: relative;

  @media (min-width: $break-mobile-landscape) {
    overflow: auto;
  }
}
.table {
  width: 100%;
  @extend %body2;
  min-width: 800px;

  & th {
    text-align: left;
    padding-bottom: 20px;
  }
  & td {
    padding-bottom: 10px;
  }
}
.investment-type {
  text-transform: capitalize;
}
.investor-logo {
  display: flex;
  align-items: center;
  &__image {
    width: 15px;
    height: 15px;
    background-color: #fff;
    margin-right: 10px;
  }
  &__title {
    text-decoration: none;
  }
}
a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
</style>
