<template>
  <div class="RPerson">
    <img
      v-if="blok.image || listHasImages"
      :src="blok.image ? blok.image : placeholderImage"
      alt="Person's Image"
      class="RPerson-image"
    />
    <div class="RPerson-label">{{ blok.label }}</div>
    <h3 :class="['RPerson-name', { highlighted: highlighted }]">
      {{ blok.name }}
    </h3>
    <p class="RPerson-title">{{ blok.title }}</p>
    <a v-if="blok.link" :href="blok.link" target="_blank" class="RPerson-link">
      <span class="RPerson-arrow"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "RPerson",
  props: {
    blok: {
      type: Object,
      required: true
    },
    highlighted: {
      type: Boolean
    },
    listHasImages: {
      type: Boolean,
      default: false
    },
    placeholderImage: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.RPerson {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  &-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  &-label {
    margin-right: 1em;
    font-size: 1.1rem;
    text-transform: none;
    @extend %lead;
  }
  &-name {
    @extend %body2;
    font-weight: 900;
    &.highlighted {
      @extend %headline4;
    }
  }
  &-title {
    margin: 0;
    opacity: 0.5;
    margin-right: 1em;
    font-size: 1.4rem;
    text-transform: none;
  }
  &-link {
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    &:hover {
      .RPerson-arrow {
        transition: all 0.25s cubic-bezier($ease-out-cubic);
        transform: translateX(10px);
      }
    }
  }
  &-arrow {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'%3E%3Cpath d='M0 13h20.2l-5.6 5.6L16 20l8-8-8-8-1.4 1.4 5.6 5.6H0v2z'/%3E%3C/svg%3E");
    transition: all 0.25s cubic-bezier($ease-out-cubic);
  }
}
</style>
