<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.026 7.34961H2V8.09961H8.62727C8.74526 7.84078 8.87856 7.59039 9.026 7.34961ZM8.02981 10.3496H2V11.0996H8.00069C8.00023 11.0665 8 11.0333 8 11C8 10.7807 8.01008 10.5638 8.02981 10.3496ZM8.40409 13.3496H2V14.0996H8.72192C8.60202 13.8572 8.49574 13.6069 8.40409 13.3496Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9976 15.553C17.0768 16.1739 15.9671 16.5364 14.7726 16.5364C11.5845 16.5364 9 13.9539 9 10.7682C9 7.58252 11.5845 5 14.7726 5C17.9607 5 20.5452 7.58252 20.5452 10.7682C20.5452 12.4775 19.8012 14.0131 18.6191 15.0694L22 18.4477L21.4472 19L17.9976 15.553ZM19.7635 10.7682C19.7635 13.5225 17.529 15.7553 14.7726 15.7553C12.0162 15.7553 9.78171 13.5225 9.78171 10.7682C9.78171 8.01392 12.0162 5.78112 14.7726 5.78112C17.529 5.78112 19.7635 8.01392 19.7635 10.7682Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSearchInvestors",
};
</script>

<style lang="scss" scoped></style>
