
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.586489 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433283 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.80429 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.0645C8.40499 18.0645 6.8458 17.5915 5.5196 16.7054C4.1934 15.8193 3.15975 14.5598 2.54936 13.0862C1.93898 11.6126 1.77927 9.99105 2.09045 8.42669C2.40162 6.86233 3.16969 5.42537 4.29753 4.29752C5.42537 3.16968 6.86233 2.40161 8.42669 2.09044C9.99106 1.77927 11.6126 1.93897 13.0862 2.54936C14.5598 3.15974 15.8193 4.19339 16.7054 5.51959C17.5915 6.8458 18.0645 8.40499 18.0645 10C18.0645 12.1388 17.2149 14.1901 15.7025 15.7025C14.1901 17.2149 12.1388 18.0645 10 18.0645Z"
      fill="currentColor"
    />
    <path
      d="M10.9673 11.5353V5.16113H9.03181V11.5353L6.77375 9.27726L5.40601 10.645L9.31568 14.5547C9.49714 14.7359 9.99955 15.1603 9.99955 15.1603C9.99955 15.1603 10.502 14.7359 10.6834 14.5547L14.5931 10.645L13.2254 9.27726L10.9673 11.5353Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCircleArrow",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: currentColor;
}
</style>