<template>
  <div class="RContact">
    <div
      class="RContact--image"
      v-if="blok.portrait.filename != null && blok.portrait.filename != ''"
    >
      <ILazyImage
        v-if="blok.portrait && blok.portrait.filename"
        class="RContact-portrait"
        :filename="blok.portrait.filename"
        :alt="blok.portrait.alt"
        sizes="(min-width: 320px) 70px"
        :ratioW="16"
        :ratioH="16"
      />
    </div>
    <div class="RContact--content">
      <p class="RContact-name">{{ blok.name }}</p>
      <div class="RContact-title">{{ blok.title }}</div>
      <a :href="`mailto:${blok.email}`" class="RContact-email" rel="noopener">
        {{ blok.email }}
      </a>
    </div>
  </div>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "RContact",
  components: {
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    highlighted: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.RContact {
  padding: 0;
  display: flex;
  flex: 1 auto;
  align-items: center;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 10px;
  }
  &--image {
    margin-right: 24px;
    min-width: 70px;
  }
  &-name {
    @extend %body3;
  }
  &-title {
    @extend %lead;
    opacity: 0.5;
  }
  &-email {
    @extend %body3;
    font-weight: bold;
  }
}
</style>
