<template>
  <main v-editable="blok" :key="blok._uid">
    <CompTerraXBanner :blok="blok" />

    <WMaxWidth class="wrapper">
      <article class="article">
        <component
          v-for="item in blok.body"
          :key="item._uid"
          :blok="item"
          :is="item.component"
          v-editable="item"
        />
      </article>
      <aside class="aside">
        <WSection class="aside__content">
          <!-- <CompProjectAside :blok="blok" /> -->
          <div class="aside__box" v-if="blok.underWrittenByIcon.length > 0">
            <p class="aside__title">
              <strong>{{ blok.underWrittenBy }}</strong>
            </p>
            <div class="aside__logo">
              <div
                v-for="item in blok.underWrittenByIcon"
                :key="item.id"
                class="aside__logo__item"
              >
                <ILazyImage
                  v-if="item && item.filename"
                  class="RTeamContact-underWritenByIcon"
                  :filename="item.filename"
                  :alt="item.alt"
                  sizes="(min-width: 320px) 300px"
                  :ratioW="16"
                  :ratioH="0"
                />
              </div>
              <!--  -->
            </div>
          </div>
          <div class="aside__box" v-if="blok.contacts.length > 0">
            <p class="aside__title">
              <strong>Key contacts</strong>
            </p>
            <RContact
              :blok="item"
              v-for="item in blok.contacts"
              :key="item.id"
            />
          </div>

          <div class="aside__box">
            <p class="aside__title">
              <strong>Sector focus</strong>
            </p>
            <p
              class="aside__sector"
              v-html="formatSector(blok.sectorFocus)"
            ></p>
          </div>
        </WSection>
      </aside>
    </WMaxWidth>
  </main>
</template>

<script>
import CompTerraXBanner from "@/components/terra-x/projectsAndInvestors/CompTerraXBanner";
import CompTerraXArticle from "@/components/terra-x/projectsAndInvestors/CompTerraXArticle";

import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import RContact from "@/components/references/R-Contact.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "LayoutProject",
  components: {
    CompTerraXBanner,
    CompArticle: CompTerraXArticle,
    WSection,
    WMaxWidth,
    RContact,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatSector(string) {
      const sector = string.split("_")[0];
      const subSector = string.split("_")[1];
      if (subSector == undefined) {
        const formatedSector =
          "<span class='sector-main'>" + sector.replace(/-/g, " ") + "</span>";

        return formatedSector;
      } else {
        const formatedSector =
          "<span class='sector-main'>" +
          sector.replace(/-/g, " ") +
          "</span>" +
          "<span class='sector-secondary'>" +
          subSector.replace(/-/g, " ") +
          "</span>";
        return formatedSector;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .wrapper > div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas:
    "side side side side side side side side side side side side"
    "main main main main main main main main main main main main";
  @media (min-width: $break-tablet-landscape) {
    grid-template-areas: "main main main main main main main . side side side side";
  }
  @media (min-width: $break-desktop-small) {
    grid-template-areas: ". main main main main main main . side side side side";
  }
}
.article {
  grid-area: main;
}
.aside {
  grid-area: side;
  &__content {
    @media (min-width: $break-tablet-portrait) {
      @include poly-fluid-sizing(
        bottom,
        (
          $break-mobile-portrait: $nav-height - $padding-mobile,
          $break-desktop-large: $nav-height - $padding-desktop,
          $break-widescreen: $nav-height - $padding-widescreen,
        )
      );
    }
    @media (min-width: $break-tablet-landscape) {
      margin-left: 0;
    }
    @media (min-width: $break-nav) {
      bottom: -$padding-desktop/2;
    }
  }
  &__title {
    margin-bottom: 3rem;
    @extend %body3;
  }
  &__logo {
    display: flex;
    flex-wrap: wrap;
    &__item {
      margin-right: 20px;
      margin-bottom: 20px;
      & /deep/ img {
        height: 43px;
        width: auto;
      }
    }
  }
  &__box {
    padding: 1.4rem 2.7rem;
    background-color: $color-grey-light;

    @media (min-width: $break-tablet-landscape) {
      margin-bottom: 28px;
    }
  }

  &__sector {
    & /deep/ span {
      &.sector-main {
        text-transform: capitalize;
      }
      &.sector-secondary {
        text-transform: capitalize;

        &::before {
          content: "|";
          position: relative;
          left: 0;
          padding: 0 5px;
        }
      }
    }
  }
}
</style>
