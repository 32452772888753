<template>
  <ILink
    class="REmail"
    :class="{
      'RUrl--no-thumbnail': !blok.thumbnail || !blok.thumbnail.filename,
    }"
    v-if="true"
    :extUrl="urlType"
    :url="convertUrl"
    iconType="arrow"
  >
    <ILazyImage
      v-if="blok.thumbnail && blok.thumbnail.filename"
      class="REmail-thumbnail"
      :filename="blok.thumbnail.filename"
      :alt="blok.thumbnail.alt"
      sizes="(min-width: 600px) 400px, 100vw"
      :ratioW="16"
      :ratioH="0"
    />
    <div class="REmail-content">
      <header class="REmail-header">
        <span v-if="blok.label" class="REmail-header-label">{{
          blok.label
        }}</span>
        <span v-if="date" class="REmail-header-date">{{ date }}</span>
      </header>
      <h3 class="REmail-title">{{ blok.title }}</h3>
      <p class="REmail-description" v-if="blok.description">
        {{ blok.description }}
      </p>
    </div>
  </ILink>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
import ILink from "@/components/items/I-Link.vue";
export default {
  name: "REmail",
  components: {
    ILazyImage,
    ILink,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      const dateString = this.blok.date.replace(/-/g, "/").replace(/T.+/, "");
      const d = new Date(dateString);
      const day = ("0" + d.getDate()).slice(-2);
      const month = d.toLocaleString("default", { month: "long" });
      const year = d.getFullYear();
      return this.onlyDisplayMonthAndYear
        ? `${month} ${year}`
        : `${day} ${month} ${year}`;
    },
    urlType() {
      return this.blok.newsletterUrl.linktype === "url"
        ? this.blok.newsletterUrl.cached_url
        : "";
    },
    convertUrl() {
      let urlLink = this.blok.newsletterUrl.cached_url;
      let urlType = this.blok.newsletterUrl.linktype;
      if (urlType === "story") {
        urlLink = "/" + this.blok.newsletterUrl.cached_url;
      }
      return urlLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.REmail {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  grid-template-areas:
    "img img img img img img img img img img img img"
    "txt txt txt txt txt txt txt txt txt txt txt txt";

  @media (min-width: $break-mobile-landscape) {
    grid-template-areas: "img img img img img txt txt txt txt txt txt txt";
  }
  @media (min-width: $break-tablet-portrait) {
    grid-template-areas: "img img img img txt txt txt txt txt txt txt . ";
  }
  &--no-thumbnail {
    grid-template-areas: "txt txt txt txt txt txt txt txt txt txt txt . ";
  }
  &-thumbnail {
    grid-area: img;
    max-width: 100%;
    height: 40vw;
    overflow: hidden;
    @media (min-width: $break-mobile-landscape) {
      height: 100%;
      margin-right: 2rem;
    }
    & /deep/ img {
      height: 100%;
      position: absolute;
      object-fit: cover;
      transform: scale(1);
      transition: 0.3s ease;
      @media (min-width: $break-tablet-portrait) {
        transform: scale(1.2);
      }
    }
  }
  &-date {
    @extend %lead;
  }
  &-content {
    grid-area: txt;
    padding: 1rem 0;
  }
  &-header {
    margin-top: 0.625em;
    margin-bottom: 0.625em;
    &-label {
      @extend %lead;
      font-weight: 900;
      margin-right: 1em;
    }
    &-date {
      @extend %lead;
    }
  }
  &-title {
    @extend %headline3;
  }
  @media (hover: hover) {
    &:hover {
      /deep/ img {
        transform: scale(1);
      }
    }
  }
  &-description {
    margin-top: 0.625em;
  }
}
</style>
