<template>
  <w-list-chapters
    :title="blok.title"
    :chapters="blok.chaptersList"
  ></w-list-chapters>
</template>

<script>
import WListChapters from "@/components/wrappers/W-ListChapters.vue";
export default {
  name: "compListGroup",
  components: {
    WListChapters,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
