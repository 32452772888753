<template>
  <WSection :margin="true" class="seal-grid__wrap">
    <WMaxWidth>
      <div :class="['seal-grid']">
        <div class="seal-grid__placeholder">
          <div class="ratio-1-1">
            <WVideoSourceSet
              v-if="blok.videoLoopFile && blok.videoLoopFile.filename"
              :video="blok.videoLoopFile"
              :poster="blok.Image.filename"
              :enablePlayBtn="true"
            />
            <!-- <WVimeoLoop
              v-if="blok.videoLoopUrl && blok.videoLoopUrl.vimeo_data"
              :vimeoUrl="blok.videoLoopUrl.vimeo_raw"
              :vimeoData="blok.videoLoopUrl.vimeo_data"
              :poster="blok.Image"
            /> -->
          </div>
        </div>
        <div class="seal-grid__content">
          <div
            v-for="item in blok.items"
            :key="item.id"
            class="seal-grid__content__item"
          >
            <component :is="item.component" :blok="item" />
          </div>
        </div>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import SealStickyText from "@/components/seal/SealStickyText";
import SealStickyBlockquote from "@/components/seal/SealStickyBlockquote";
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVimeoLoop from "@/components/wrappers/W-VimeoLoop.vue";
import WVideoSourceSet from "@/components/wrappers/W-VideoSourceSet.vue";

export default {
  name: "SealIntro",
  components: {
    SealStickyText,
    SealStickyBlockquote,
    WSection,
    WMaxWidth,
    WVimeoLoop,
    WVideoSourceSet,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.seal-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: #fff;

  @extend %grid-column-gap;
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";
  @media (min-width: $break-tablet-portrait) {
    max-width: none;
    grid-template-rows: initial;
    grid-template-areas: " se1 se1 se1 se1 se1 se1 .  se2 se2 se2 se2 se2 ";
    &.right {
      grid-template-areas: "se2 se2 se2 se2 se2  . se1 se1 se1 se1 se1 se1 ";
    }
  }

  @media (min-width: $break-desktop-small) {
    max-width: none;
    grid-template-rows: initial;
    grid-template-areas: " . se1 se1 se1 se1 . se2 se2 se2 se2 se2  . ";
    &.right {
      grid-template-areas: ". se2 se2 se2 se2 . se1 se1 se1 se1 se1 . ";
    }
  }
  &__placeholder {
    grid-area: se1;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (min-width: $break-tablet-portrait) {
      margin-top: 0px;
      margin-bottom: 0px;
      grid-area: se2;
      z-index: 100;
      top: 0;
      position: sticky;
      margin: 0;
      height: 100vh;
    }
  }
  &__content {
    grid-area: se2;
    @media (min-width: $break-tablet-portrait) {
      grid-area: se1;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      @media (min-width: $break-tablet-portrait) {
        min-height: 100vh;
        margin-bottom: 0px;
      }
    }
  }
}

.ratio-1-1 {
  padding-bottom: 100%;
  position: relative;
  background-blend-mode: multiply;
  @media (min-width: $break-tablet-portrait) {
    top: 50%;
    transform: translateY(-50%);
  }
  filter: unset;
  & /deep/ .image {
    position: absolute;
    filter: unset;
  }
  & /deep/ img {
    height: 100%;
    object-fit: cover;
  }
  & /deep/ .W-VimeoLoop,
  & /deep/ .W-VideoSourceSet {
    position: absolute;
  }
}
</style>
