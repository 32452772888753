<template>
  <div class="ceo-testimonials">
    <div class="hero__wrapper">
      <WSection :margin="true">
        <WMaxWidth>
          <div class="hero">
            <div class="hero__content">
              <IText
                :label="blok.label"
                :title="blok.title"
                :content="blok.description"
                :transition="false"
                :inverted="false"
                :fontB1="false"
              />
            </div>
            <div class="hero__slider">
              <RVideoSlider :slides="blok.ceoTestimonials" />
            </div>
          </div>
        </WMaxWidth>
      </WSection>
    </div>
    <div class="chapters">
      <w-list-chapters-search
        :chapters="blok.testimonials"
        :searchText="blok.searchText"
        :searchTextError="blok.searchTextError"
      ></w-list-chapters-search>
    </div>
  </div>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WListChaptersSearch from "@/components/wrappers/W-ListChaptersSearch.vue";
import IText from "@/components/items/I-Text.vue";
import RVideoSlider from "@/components/references/R-VideoSlider.vue";

export default {
  name: "LayoutTestimonials",
  components: {
    WSection,
    WMaxWidth,
    WListChaptersSearch,
    IText,
    RVideoSlider,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    videos() {
      return this.blok.ceoTestimonials.map((item) => item.content);
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "se2" "se1";
  @extend %grid-column-gap;

  @media (min-width: $break-tablet-portrait) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ". se1 se1 se1 se1 .   se2 se2 se2 se2 se2 .";
    align-items: center;
  }
  &__wrapper {
    background-color: $color-blue;
    color: white;
    padding: 1rem 0 3rem;
  }
  &__content {
    grid-area: se1;
  }
  &__slider {
    margin: 2rem 0;
    grid-area: se2;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
  }
}
</style>