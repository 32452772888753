<template>
  <WSection
    :margins="false"
    :class="[
      'compCarousel',
      {
        fullHeight: settingsFullHeight,
        halfHeight: settingsHalfHeight,
        locked: isLocked,
        isFirst,
      },
    ]"
    :style="'--full-height: ' + innerHeight"
  >

    <WMaxWidth>
      <div
        :class="[
          'C',
          {
            colored: settingsBlueBackground,
            centered: settingsCentered,
            hasBackground,
          },
        ]"
      >
        <div class="graphic">
          <img
            :alt="blok.slides[count].graphic.alt"
            :src="blok.slides[count].graphic.filename"
            v-if="blok.slides[count].graphic && blok.slides[count].graphic.filename"
          />
        </div>
        <IText
          :label="blok.slides[count].label"
          :content="blok.slides[count].content"
          :buttons="blok.slides[count].buttons"
          :inverted="invertedUiColors"
          :fontB1="true"
        />
        <div class="scroll-indicator-wrapper">
          <IScrollDownIndicator
            v-if="this.$route.path == '/' && settingsFullHeight"
          />
        </div>
      </div>
    </WMaxWidth>
    <WBackground
      :type="'fill'"
      :colored="settingsBlueBackground"
      :hasBackground="hasBackground"
    >
      <!-- <WVimeoLoopBanner
        v-if="
          blok.backgroundVideoLoopUrl && blok.backgroundVideoLoopUrl.vimeo_data
        "
        :vimeoData="blok.backgroundVideoLoopUrl.vimeo_data"
        :poster="blok.backgroundImages"
      /> -->
      <ILazyImage
        v-if="
          //!blok.backgroundVideoLoopUrl.vimeo_data &&
          blok.slides[count].backgroundImage
        "
        :filename="blok.slides[count].backgroundImage.filename"
        class="banner-image"
        :alt="blok.slides[count].backgroundImage.alt"
        sizes="(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)"
        :ratioW="16"
        :ratioH="9"
        classes="h100"
      />
    </WBackground>
    <div class="show_slide">

    </div>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WBackground from "@/components/wrappers/W-Background.vue";
import WVimeoLoopBanner from "@/components/wrappers/W-VimeoLoopBanner.vue";
import IText from "@/components/items/I-Text.vue";
import IScrollDownIndicator from "@/components/items/I-ScrollDownIndicator.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "compCarousel",
  components: {
    WSection,
    WMaxWidth,
    WBackground,
    WVimeoLoopBanner,
    IText,
    IScrollDownIndicator,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLocked: true,
      innerHeight: "100vh",
      count: 0
    };
  },
  computed: {
    changeCarouselImage(index) {
      return index++
    },
    settingsBlueBackground() {
      return this.blok.settings.includes("blueBackground");
    },
    settingsCentered() {
      return this.blok.settings.includes("centered");
    },
    settingsHalfHeight() {
      return this.blok.settings.includes("halfHeight");
    },
    settingsFullHeight() {
      return this.blok.settings.includes("fullHeight");
    },
    // backgroundVideoLoopUrl() {
    //   return this.blok.backgroundVideoLoopUrl;
    // },
    backgroundImages() {
      return this.blok.backgroundImages;
    },
    hasBackground() {
      if (
        //this.blok.backgroundVideoLoopUrl.vimeo_raw ||
        this.blok.backgroundImages
      ) {
        return true;
      }
      return false;
    },
    invertedUiColors() {
      return this.hasBackground || this.settingsBlueBackground ? true : false;
    },
  },
  mounted() {
    this.init();
    if (this.$deviceIsMobile) {
      this.innerHeight = window.innerHeight + "px";
    }
    // populating show_slide div with amount of slides
    const slideDiv = document.querySelector('.show_slide')
    // create circles
    const diameter = 10
    for (let i = 0; i < this.blok.slides.length; i++) {
      let circleDiv = document.createElement('div')
      circleDiv.setAttribute("class", "circle-div")
      circleDiv.style.width = circleDiv.style.height = `${diameter}px`
      circleDiv.style.borderRadius = `${diameter / 2}px`
      circleDiv.style.background = '#fff'
      //circleDiv.style.margin= `${diameter}px ${diameter}px 0 ${diameter}px`
      circleDiv.style.cursor = 'pointer'
      circleDiv.style.opacity = '0.7'
      slideDiv.appendChild(circleDiv)
      // change circle colour based on current image
      const circles = document.getElementsByClassName('circle-div')
      circles[0].style.opacity = '1'
      circleDiv.addEventListener('click', (e) => {
        this.count = i
        for (let circle of circles) {
          circle.style.opacity = '0.7'
        }
        e.target.style.opacity = '1'
      })
    }

  },
  activated() {
    // Only used if keep-alive is used
    this.init();
  },
  deactivated() {
    // Only used if keep-alive is used
    this.lock();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrolled);
    this.lock();
  },
  methods: {
    init() {
      if (this.$route.path == "/") {
        window.addEventListener("scroll", this.onScrolled);
        setTimeout(() => {
          this.isLocked = false;
        }, 4000);
      } else {
        setTimeout(() => {
          this.isLocked = false;
        }, 1200);
      }

      // Auto slide carousel
        setInterval(() => {
          this.nextSlide()
        }, 7000)
    },
    onScrolled() {
      this.unlock();
      window.removeEventListener("scroll", this.onScrolled);
    },
    unlock() {
      this.isLocked = false;
    },
    lock() {
      this.isLocked = true;
    },

    nextSlide() {
      this.count == this.blok.slides.length - 1 ? this.count = 0 : this.count++
      const circles = document.getElementsByClassName('circle-div')
      for (let circle of circles) {
          circle.style.opacity = '0.5'
      }
      circles[this.count].style.opacity = '1'
            bannerImage.classList.toggle('fade-out')

      //return this.count
    }
  },
};
</script>

<style lang="scss" scoped>

.show_slide {
  position: absolute;
  bottom: 0;
  left: calc(2rem + 60 * (100vw - 375px) / 1065);
  // transform: translateX(50%);
  min-width: 160px;
  padding: 10px;
  // height: 40px;
  display: flex;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
  align-items: center;
  // background: white;
  // background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3) 90%);
  // box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

@keyframes fade-out-in {
  0% {opacity: 0;}
  20% {opacity: 1}
  80% {opacity: 1}
  95% {opacity: 0}
  100% {opacity: 0}
}

.banner-image {
  animation: fade-out-in 7s repeat;
  & /deep/ img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    
  }
}
.compCarousel {
  display: flex;
  flex-direction: column;
  // background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6));
  /deep/ .W-MaxWidth {
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      > * {
        height: 100%;
        align-items: flex-end;
      }
    }
  }
}
.C {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  > * {
    align-items: flex-end;
    grid-column-start: 1;
    grid-column-end: span 12;
    @media (min-width: $break-mobile-landscape) {
      grid-column-end: span 8;
    }
  }
  &.centered {
    align-items: center;
    > * {
      grid-column-start: 1;
      grid-column-end: span 12;
      @media (min-width: $break-mobile-landscape) {
        grid-column-start: 2;
        grid-column-end: span 10;
      }
      @media (min-width: $break-tablet-portrait) {
        grid-column-start: 2;
        grid-column-end: span 10;
      }
      @media (min-width: $break-tablet-landscape) {
        grid-column-start: 2;
        grid-column-end: span 10;
      }
      @media (min-width: $break-desktop-small) {
        grid-column-start: 3;
        grid-column-end: span 8;
      }
    }
    /deep/ {
      text-align: center;
      footer {
        justify-content: center;
      }
    }
  }
}
.compCarousel.isFirst {
  &.fullHeight {
    --full-height: 100vh;
    margin-bottom: $nav-height;
    transition: min-height 0.85s cubic-bezier($ease-in-out-cubic);
    min-height: calc(var(--full-height) - #{$nav-height * 2});
    .scroll-indicator-wrapper {
      position: absolute;
      right: 0;
      top: calc(100% + #{$nav-height / 2});
      transform: translateY(-50%);
      @extend %padding-right-x1;
      @extend %margin-top-x1;
    }
    &.locked {
      min-height: calc(var(--full-height) - #{$nav-height * 1});
    }
  }
  &.halfHeight {
    transition: min-height 0.85s cubic-bezier($ease-in-out-cubic);
    min-height: 50vh; //calc(100vh - #{$nav-height * 2});
    &.locked {
      min-height: calc(100vh - #{$nav-height});
    }
  }
}
.graphic {
  & img {
    max-width: 100%;
  }
}
.centered /deep/ .text__button-list {
  justify-content: center;
}
</style>
