<template>
  <div class="filter" :class="{ 'filter--globe-closed': !isGlobeOpen }">
    <div class="filter-sectors">
      <label class="filter-sectors__label">Sectors</label>
      <div class="filter-sectors__chips">
        <div
          v-for="item in getAllSectors"
          :key="item.value"
          class="filter-sectors__chip"
        >
          <input
            :id="item.value"
            type="checkbox"
            :value="item.value"
            v-model="activeSectors"
            @change="setQ"
            class="filter-sectors__chip-input"
          />
          <label :for="item.value">{{ item.name }}</label>
        </div>
      </div>
    </div>
    <div class="filters-selects" v-if="getAllRegions.length">
      <div
        class="filter-stages-of-development"
        v-if="getAllStagesOfDevelopment.length"
      >
        <label class="filter-stages-of-development__label"
          >Stages of development</label
        >
        <select
          v-model="activeStageOfDevelopment"
          class="filter-stages-of-development__select"
          :class="{ selected: activeStageOfDevelopment !== undefined }"
          @change="setQ"
        >
          <option :value="undefined" selected>All stages of development</option>
          <option
            v-for="item in getAllStagesOfDevelopment"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="filter-regions" v-if="getAllRegions.length">
        <label class="filter-regions__label">Regions</label>
        <select
          v-model="activeRegion"
          class="filter-regions__select"
          :class="{ selected: activeRegion !== undefined }"
          @change="setQ"
        >
          <option :value="undefined" selected>All regions</option>
          <option
            v-for="item in getAllRegions"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TerraXNavFilters",
  computed: {
    filteredData() {
      return this.$store.getters["terrax/filteredData"]();
    },
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    activeSectors: {
      get() {
        return this.$store.state.terrax.routeQuery.sectors;
      },
      set(value) {
        this.$store.commit("terrax/setSectors", value);
      },
    },
    activeRegion: {
      get() {
        return this.$store.state.terrax.routeQuery.region;
      },
      set(value) {
        this.$store.commit("terrax/setRegion", value);
      },
    },
    activeStageOfDevelopment: {
      get() {
        return this.$store.state.terrax.routeQuery.stageOfDevelopment;
      },
      set(value) {
        this.$store.commit("terrax/setStageOfDevelopment", value);
      },
    },
    getAllSectors() {
      const valuesArr = this.$store.getters["terrax/getFilters"](
        "sector",
        "main"
      );
      return this.$store.getters["terrax/getFiltersArrOfObjs"](
        valuesArr,
        "sectors"
      );
    },
    getAllRegions() {
      const valuesArr = this.$store.getters["terrax/getFilters"]("region");
      return this.$store.getters["terrax/getFiltersArrOfObjs"](
        valuesArr,
        "region"
      );
    },
    getAllStagesOfDevelopment() {
      const valuesArr =
        this.$store.getters["terrax/getFilters"]("stageOfDevelopment");
      return this.$store.getters["terrax/getFiltersArrOfObjs"](
        valuesArr,
        "stageOfDevelopment"
      );
    },
  },
  methods: {
    setQ() {
      this.$store.dispatch("terrax/setUrlQuery", this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  background-color: $color-white;
  color: $color-blue;
  padding: 2.5rem;
  max-width: 100%;
  @media (min-width: $break-terra-x-nav) {
    max-width: 570px;
  }
  &--globe-closed {
    @media (min-width: $break-terra-x-nav) {
      display: grid;
      grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);
      gap: 2rem;
      &.filter {
        max-width: 100%;
      }
    }
  }
  &__link {
    @extend %body1;
  }
  &-sectors {
    margin-bottom: 2rem;
    &__label {
      @extend %lead;
      display: block;
      margin-bottom: 1rem;
      opacity: 0.5;
    }
    &__chips {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
    &__chip {
      display: inline-block;
    }
    &__chip-input {
      @extend %checkbox-chip;
    }
  }
  &-stages-of-development {
    margin-bottom: 2rem;
    &__label {
      @extend %lead;
      display: block;
      margin-bottom: 1rem;
      opacity: 0.5;
    }
    &__select {
      width: 100%;
    }
  }
  &-regions {
    &__label {
      @extend %lead;
      display: block;
      margin-bottom: 1rem;
      opacity: 0.5;
    }
    &__select {
      width: 100%;
    }
  }
}
</style>
