var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WSection',{class:[_vm.$options.name, { slim: _vm.isSlim }],attrs:{"margin":true,"paddings":false}},[_c('WMaxWidth',[_c('div',{class:[
        'C',
        { isSlim: _vm.isSlim, leftImage: _vm.isLeftImage, dark: _vm.isLayoutAstra }
      ]},[(!_vm.isSlim)?_c('section',[_c('IText',{attrs:{"label":_vm.label,"content":_vm.content}})],1):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"buttns"},_vm._l((_vm.downloads),function(item,index){return _c('li',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.observed),expression:"observed"}],key:("" + index)},[_c('a',{staticClass:"buttn",attrs:{"target":"_blank","href":("/" + (item.filePath)),"title":("Download the " + (item.label))},on:{"mouseenter":function($event){$event.preventDefault();return (function (e) {
                _vm.hoverDownload(index);
              })($event)},"mouseleave":function (e) {
                _vm.hoverDownload();
              }}},[_c('IconCircleArrow'),_vm._v(" "),_c('span',[_vm._v("Download the "+_vm._s(item.label))])],1)])}),0),_vm._v(" "),(!_vm.isSlim)?_c('figure',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.observed),expression:"observed"}],class:['thumbnails', { single: _vm.single, stack: _vm.stack }]},[_c('div',{staticClass:"cover"},_vm._l((_vm.downloads),function(item,index){return _c('div',{key:("" + index)},[_c('ILazyImage',{attrs:{"filename":item.thumbnail.filename,"alt":item.thumbnail.alt,"sizes":"(min-width: 600px) 30vw, 100vw","ratioW":16,"ratioH":0}}),_vm._v(" "),_c('a',{staticClass:"buttn",attrs:{"target":"_blank","href":("/" + (item.filePath)),"title":("Download the " + (item.label))}},[_c('IconCircleArrow'),_vm._v(" "),_c('span',[_vm._v("Download the "+_vm._s(item.label))])],1)],1)}),0)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }