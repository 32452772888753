<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6 4.5L8.5 16.7L3.4 11.6L2 13L8.5 19.5L22 5.9L20.6 4.5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconCheckmark"
};
</script>
