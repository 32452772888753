<template>
  <div class="sticky-intro__wrap">
    <WMaxWidth>
      <div class="sticky-intro">
        <div class="sticky-intro__placeholder">
          <StickyGraphic compType="intro" :vimeoUrl="blok.videoUrl" />
        </div>
        <div class="sticky-intro__content">
          <div
            v-for="item in blok.items"
            :key="item.id"
            class="sticky-intro__content__item"
          >
            <component :is="item.component" :blok="item" />
          </div>
        </div>
      </div>
    </WMaxWidth>
  </div>
</template>

<script>
import StickyGraphic from "@/components/comps/CompStickyGraphic";
import SealStickyText from "@/components/seal/SealStickyText";
import SealStickyBlockquote from "@/components/seal/SealStickyBlockquote";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";

export default {
  name: "stickyIntro",
  components: {
    StickyGraphic,
    SealStickyText,
    SealStickyBlockquote,
    WMaxWidth
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.sticky-intro {
  position: relative;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: #fff;

  @extend %grid-column-gap;
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";
  @media (min-width: $break-tablet-portrait) {
    max-width: none;
    grid-template-rows: initial;
    grid-template-areas: " se1 se1 se1 se1 se1 se1 se1  se2 se2 se2 se2  se2 ";
  }
  @media (min-width: $break-desktop-small) {
    max-width: none;
    grid-template-rows: initial;
    grid-template-areas: " se1 se1 se1 se1 se1 se1 se1  se2 se2 se2 se2  . ";
  }
  &__placeholder {
    grid-area: se1;

    @media (min-width: $break-tablet-portrait) {
      align-self: start;
      display: flex;
      z-index: 100;
      top: $nav-height;
      align-items: center;
      position: sticky;
      min-height: calc(100vh - #{$nav-height});
    }
  }
  &__content {
    grid-area: se2;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      @media (min-width: $break-tablet-portrait) {
        min-height: calc(100vh - #{$nav-height});
        margin-bottom: 0px;
      }
    }
  }
}
</style>
