<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <div
        :class="[
          'C',
          {
            addPadding: settingsAddPadding,
            imageOnLeftSide: settingsImageLeft,
          },
        ]"
      >
        <section>
          <IText
            :icon="blok.icon"
            :label="blok.label"
            :content="blok.content"
            :buttons="blok.buttons"
            :inverted="false"
            :fontB1="true"
          />
        </section>
        <section class="image" v-observe-visibility="observed">
          <WVimeoLoop
            v-if="blok.videoLoopUrl && blok.videoLoopUrl.vimeo_data"
            :vimeoUrl="blok.videoLoopUrl.vimeo_raw"
            :vimeoData="blok.videoLoopUrl.vimeo_data"
            :poster="blok.image"
          />
          <ILazyImage
            v-if="!blok.videoLoopUrl.vimeo_data && blok.image.filename"
            :filename="blok.image.filename"
            :alt="blok.image.alt"
            sizes="(min-width: 600px) 30vw, 100vw"
            :ratioW="16"
            :ratioH="0"
            classes="h100"
          />
        </section>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVimeoLoop from "@/components/wrappers/W-VimeoLoop.vue";
import IText from "@/components/items/I-Text.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "compTextImage",
  components: {
    WSection,
    WMaxWidth,
    WVimeoLoop,
    IText,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    settingsAddPadding() {
      return this.blok.settings.includes("addPadding");
    },
    settingsImageLeft() {
      return this.blok.settings.includes("imageLeft");
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  height: 90vw;
  @media (min-width: $break-mobile-landscape) {
    height: 100%;
  }

  & /deep/ img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 25%;
  }
}
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  // We always want the text first on mobile
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";

  section {
    position: relative;
    &:first-child {
      grid-area: se1;
      @extend %margin-top-bottom-x1;
      display: flex;
      align-items: center;
    }
    &:last-child {
      grid-area: se2;
    }
  }
  @media (min-width: $break-mobile-landscape) {
    section {
      &:last-child {
        min-height: 520px;
        .W-PictureSourceSet {
          position: absolute;
          top: 0;
        }
        /deep/ {
          video,
          img {
            position: absolute;
          }
        }
      }
    }
    // two-columns
    grid-template-areas: "se1 se1 se1 se1 se1 se1  .  se2 se2 se2 se2 se2";

    &.imageOnLeftSide {
      grid-template-areas: "se2 se2 se2 se2 se2  .  se1 se1 se1 se1 se1 se1";
    }
  }
  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      grid-template-areas: " .  se1 se1 se1 se1 se1  .  se2 se2 se2 se2  . ";
      &.imageOnLeftSide {
        grid-template-areas: " .  se2 se2 se2 se2  .  se1 se1 se1 se1 se1  . ";
      }
    }
  }
}
</style>
