<template>
  <transition name="fade">
    <div class="terra-x-intro" v-if="show">
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
      <div class="terra-x-intro__buttons">
        <div class="terra-x-intro__buttons__item">
          <span class="h4">
            Projects
            <sup>{{ projectsCount }}</sup>
          </span>
          <p>{{ projectsDescription }}</p>
          <nuxt-link
            class="terra-x-intro__link"
            :to="{
              path: $urlResolver($config.terraXProjectsPage),
              query: {
                globe: true,
              },
            }"
            @mouseover.native="setType('projects')"
            @mouseleave.native="setType('projects')"
          >
            <IconArrowLeft></IconArrowLeft>
            <span>Find projects</span>
          </nuxt-link>
        </div>
        <!--
        <div class="terra-x-intro__buttons__item">
          <span class="h4">
            Investors
            <sup>{{ investorsCount }}</sup>
          </span>
          <p>{{investorsDescription}}</p>
          <nuxt-link
            class="terra-x-intro__link"
            :to="{
            path: $urlResolver($config.terraXInvestorsPage),
            query: {
              globe: true,
            },
          }"
            @mouseover.native="setType('investors')"
            @mouseleave.native="setType('investors')"
          >
            <IconArrowLeft></IconArrowLeft>
            <span>Find investors</span>
          </nuxt-link>
        </div>
        -->
        <div class="terra-x-intro__buttons__item">
          <span class="h4">
            Insurance
            <sup>{{ insuranceCount }}</sup>
          </span>
          <p>{{ insuranceDescription }}</p>
          <nuxt-link
            class="terra-x-intro__link"
            :to="{
              path: $urlResolver($config.terraXInsurancePage),
              query: {
                globe: false,
              },
            }"
          >
            <IconArrowLeft></IconArrowLeft>
            <span>Find insurance</span>
          </nuxt-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
export default {
  name: "TerraXIntro",
  components: {
    IconArrowLeft,
  },
  props: {
    title: {
      type: String,
      default: "About Terra X",
    },
    description: {
      type: String,
      default:
        "Connecting the world’s most ambitious sustainability projects with visionary investors.",
    },
    projectsDescription: {
      type: String,
      default: "",
    },
    investorsDescription: {
      type: String,
      default: "",
    },
    insuranceDescription: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
  computed: {
    projectsCount() {
      return this.$store.getters["terrax/getProjectsCount"]();
    },
    investorsCount() {
      return this.$store.getters["terrax/getInvestorsCount"]();
    },
    insuranceCount() {
      return this.$store.getters["terrax/getInsuranceCount"]();
    },
  },
  methods: {
    setType(type) {
      this.$store.commit("terrax/setQueryType", type);
    },
  },
};
</script>

<style lang="scss" scoped>
.terra-x-intro {
  pointer-events: auto;
  @extend %body1;
  padding-top: $nav-height / 2;
  @media (min-width: $break-nav) {
    padding-top: 0;
  }
  & h1 {
    @extend %headline2;
    margin-bottom: 15px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    &__item {
      margin-top: 30px;
      & .h4 {
        padding-bottom: 1rem;
        display: block;
        & sup {
          opacity: 0.5;
          font-size: 12px;
          font-family: "Avenir LT";
        }
      }

      & p {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    transition: 0.3s ease;
    color: $color-blue;
    & span {
      margin-left: 25px;
    }
    &:hover {
      transform: translateX(30px);
    }
  }
}
</style>
