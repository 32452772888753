<template>
  <WSection
    :margins="false"
    :class="['banner', { 'banner--locked': isLocked }]"
    :style="'--full-height: ' + innerHeight"
  >
    <WMaxWidth>
      <div class="banner-content">
        <div class="banner-content__header">
          <button class="banner-content__header__back-button" @click="goBack">
            <div class="banner-content__header__back-button-icon inverted">
              <IconArrowLeft />
            </div>
            <label class="banner-content__header__back-button-label"
              >Go back</label
            >
          </button>
        </div>
        <h1 class="banner-content__title h2">{{ blok.title }}</h1>
      </div>
    </WMaxWidth>
    <WBackground :type="'fill'" :colored="true" :hasBackground="hasBackground">
      <WVimeoLoopBanner
        v-if="hasBgVideoLoop"
        :vimeoData="blok.backgroundVideoLoopUrl.vimeo_data"
        :poster="blok.backgroundImage"
      />
      <ILazyImage
        v-if="!hasBgVideoLoop && hasBgImage"
        :filename="blok.backgroundImage.filename"
        class="banner-background"
        :alt="blok.backgroundImage.alt"
        sizes="(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)"
        :ratioW="16"
        :ratioH="9"
        classes="h100"
      />
    </WBackground>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVimeoLoopBanner from "@/components/wrappers/W-VimeoLoopBanner.vue";
import WBackground from "@/components/wrappers/W-Background.vue";

import ILazyImage from "@/components/items/I-LazyImage.vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";

export default {
  name: "compTerraXHero",
  components: {
    WSection,
    WMaxWidth,
    WVimeoLoopBanner,
    WBackground,
    ILazyImage,
    IconArrowLeft,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLocked: true,
      hasScrolled: false,
      innerHeight: "100vh",
    };
  },
  computed: {
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    hasBgImage() {
      if (this.blok.backgroundImage && this.blok.backgroundImage.filename) {
        return true;
      }
      return false;
    },
    hasBgVideoLoop() {
      if (
        this.blok.backgroundVideoLoopUrl &&
        this.blok.backgroundVideoLoopUrl.vimeo_raw &&
        this.blok.backgroundVideoLoopUrl.vimeo_data
      ) {
        return true;
      }
      return false;
    },
    hasBackground() {
      if (this.hasBgImage || this.hasBgVideoLoop) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.init();
    if (this.$deviceIsMobile) {
      this.innerHeight = window.innerHeight + "px";
    }
  },
  activated() {
    // Only used if keep-alive is used
    this.init();
  },
  deactivated() {
    // Only used if keep-alive is used
    this.lock();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrolled);
    this.lock();
  },
  methods: {
    init() {
      if (this.$route.path == "/") {
        window.addEventListener("scroll", this.onScrolled);
        setTimeout(() => {
          this.isLocked = false;
        }, 4000);
      } else {
        setTimeout(() => {
          this.isLocked = false;
        }, 1200);
      }
    },
    onScrolled() {
      this.hasScrolled = true;
      this.unlock();
      window.removeEventListener("scroll", this.onScrolled);
    },
    goBack() {
      const layout = this.blok.component;
      switch (layout) {
        case "LayoutProject":
          this.$router.push({
            path: this.$urlResolver(this.$config.terraXProjectsPage),
            query: { globe: this.isGlobeOpen },
          });
          break;
        case "LayoutInvestor":
          this.$router.push({
            path: this.$urlResolver(this.$config.terraXInvestorsPage),
            query: { globe: this.isGlobeOpen },
          });
          break;
        default:
          this.$router.back();
      }
    },
    unlock() {
      this.isLocked = false;
    },
    lock() {
      this.isLocked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  flex-direction: column;
  color: $color-white;
  /deep/ .W-MaxWidth {
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      > * {
        height: 100%;
        align-items: flex-end;
      }
    }
  }
  transition: min-height 0.85s cubic-bezier($ease-in-out-cubic);
  min-height: 50vh; //calc(100vh - #{$nav-height * 2});
  &--locked {
    min-height: calc(100vh - #{$nav-height});
  }
  &-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @extend %grid-column-gap;
    > * {
      grid-column-start: 1;
      grid-column-end: span 12;
      @media (min-width: $break-mobile-landscape) {
        grid-column-end: span 8;
      }
    }
    &__header {
      align-self: flex-start;
      padding-bottom: 5rem;
      &__back-button {
        display: flex;
        align-items: center;
        color: currentColor;
      }
      &__back-button-icon {
        @extend %button-icon;
        svg {
          transform: translate(-50%, -50%) rotate(180deg);
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      &__back-button-label {
        @extend %lead;
        margin-left: 2.3rem;
      }
    }
    &__title {
      align-self: flex-end;
    }
  }
  &-background {
    & /deep/ img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
