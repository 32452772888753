<template>
  <WListSimple
    :label="blok.label"
    :title="blok.title"
    :titleH3="!blok.titleLarge"
    :content="blok.content"
    :buttons="blok.buttons"
    :listItems="blok.listItems"
    :is-grid="true"
  >
    <template v-slot:default="{ listItem }">
      <!-- <IListItem :blok="listItem"/> -->
      <RPerson
        :blok="listItem"
        :highlighted="highlighted"
        :listHasImages="listHasImages"
        :placeholderImage="placeholderImageUrl"
      />
    </template>
  </WListSimple>
</template>

<script>
import WListSimple from "@/components/wrappers/W-ListSimple.vue";
import RPerson from "@/components/references/R-Person.vue";
export default {
  name: "compListPeople",
  components: {
    WListSimple,
    RPerson
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  computed: {
    stickyScroll() {
      return this.blok.settings.includes("scrollingText");
    },
    highlighted() {
      return this.blok.settings.includes("highlightItems");
    },
    buttons() {
      return this.blok.buttons;
    },
    listHasImages() {
      // Check if any of the list items has an image
      return this.blok.listItems.some(item => item.image);
    },
    placeholderImageUrl() {
      // Placeholder image for persons without an image
      return this.blok.placeholderImg?.filename || "";
    }
  },
  watch: {},
  mounted() {},
  activated() {
    // Only used if keep-alive is used
  },
  deactivated() {
    // Only used if keep-alive is used
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  header {
    grid-column-start: 1;
    grid-column-end: span 12;
    //hyphens: auto;
    @extend %padding-bottom-x1;
    @media (min-width: $break-mobile-landscape) {
      grid-column-end: span 8;
    }
    @media (min-width: $break-tablet-portrait) {
      grid-column-start: 1;
      grid-column-end: span 4;
    }
    @media (min-width: $break-desktop-small) {
      grid-column-start: 2;
      grid-column-end: span 3;
    }
    .label {
      @extend %lead;
    }
  }
  &.stickyScroll {
    header {
      display: block;
      align-items: center;
      > * {
        position: sticky;
        top: $nav-height;
      }
    }
  }

  .I-Text {
    &.desktop {
      display: none;
    }
    &.mobile {
      grid-column-start: 1;
      grid-column-end: span 12;
      /deep/ footer .button-list {
        width: 100%;
        justify-content: center;
      }
    }

    @media (min-width: $break-tablet-landscape) {
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }
  }
}
.I {
  grid-column-start: 1;
  grid-column-end: span 12;
  display: grid;
  @extend %grid-column-gap;
  @media (min-width: $break-mobile-portrait + ($break-mobile-landscape - $break-mobile-portrait) / 2) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $break-mobile-landscape) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: $break-tablet-portrait) {
    grid-column-start: 5;
    grid-column-end: span 8;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $break-tablet-landscape) {
    grid-template-columns: repeat(3, 1fr);
  }

  li {
    h3 {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    p {
      @extend %line-clamp-p;
    }
  }
}
</style>
