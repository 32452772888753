<template>
  <WMaxWidth>
    <div class="terra-x-nav">
      <div class="terra-x-nav-topbar-header">
        <TerraXNavHeader />
      </div>
      <div
        v-if="!isGlobeOpen"
        class="terra-x-nav-topbar-search"
        :class="{ 'terra-x-nav-topbar-search--globe-open': isGlobeOpen }"
      >
        <TransitionExpandHeight
          :name="!deviceIsMobile ? 'no-transition' : 'fade-down'"
          :expanded="!isGlobeOpen"
        >
          <TerraXNavSearch class="terra-x-nav-topbar__item" />
        </TransitionExpandHeight>
      </div>
      <div
        class="terra-x-nav-topbar-filters"
        :class="{ 'terra-x-nav-topbar-filters--globe-open': isGlobeOpen }"
      >
        <TransitionExpandHeight name="fade-down" :expanded="isFilterOpen">
          <TerraXNavFilters class="terra-x-nav-topbar__item" />
        </TransitionExpandHeight>
      </div>
      <div
        class="terra-x-nav-sidebar"
        :class="{ 'terra-x-nav-sidebar--globe-open': isGlobeOpen }"
      >
        <div class="terra-x-nav-sidebar-content">
          <TerraXNavViews />
          <TerraXNavTypes />
        </div>
      </div>
      <TerraXGlobeModal
        v-if="selectedPin && !isLandscapeOrientation"
        :details="selectedPinDetails"
      ></TerraXGlobeModal>
      <!-- TerraXGlobePlaceholder name="filters-open" replicates this position -->
      <!-- TerraXGlobePlaceholder name="filters-closed" replicates this position -->
      <div class="terra-x-nav-list-wrapper">
        <slot></slot>
      </div>
    </div>
  </WMaxWidth>
</template>
<script>
import TerraXNavHeader from "@/components/terra-x/nav/TerraXNavHeader";
import TerraXNavFilters from "@/components/terra-x/nav/TerraXNavFilters";
import TerraXNavViews from "@/components/terra-x/nav/TerraXNavViews";
import TerraXNavTypes from "@/components/terra-x/nav/TerraXNavTypes";
import TerraXNavSearch from "@/components/terra-x/nav/TerraXNavSearch";
import TerraXGlobeModal from "@/components/terra-x/globe/TerraXGlobeModal";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import TransitionExpandHeight from "@/components/utils/TransitionExpandHeight.vue";

export default {
  name: "TerraXNav",
  components: {
    TerraXNavHeader,
    TerraXNavFilters,
    TerraXNavViews,
    TerraXNavTypes,
    TerraXNavSearch,
    TerraXGlobeModal,
    WMaxWidth,
    TransitionExpandHeight,
  },
  computed: {
    isFilterOpen() {
      return this.$store.getters["terrax/isFilterOpen"]();
    },
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    deviceIsMobile() {
      return this.$deviceIsMobile;
    },
    selectedPin() {
      return this.$store.getters["terrax/getSelectedPin"]();
    },
    selectedPinDetails() {
      return this.$store.getters["terrax/getSelectedPinDetails"]();
    },
    isLandscapeOrientation() {
      return this.$store.getters["terrax/isLandscapeOrientation"]();
    },
  },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {
    // close filters
    if (this.isFilterOpen) {
      this.$store.commit("terrax/filterToggle");
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.terra-x-nav {
  position: relative;
  padding: 2.8rem 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: 5rem 30% 1fr auto;
  grid-template-rows: auto auto auto auto auto;
  column-gap: 2rem;
  //min-height: calc(100vh - #{$nav-height * 4 - 5rem});
  &-topbar {
    &-header {
      z-index: $z-globe-wrap + 1; // put in front of globe
      grid-column: 1 / span 4;
      grid-row: 1;
      pointer-events: none;
      @media (min-width: $break-terra-x-nav) {
        grid-column: 1 / span 3;
      }
    }
    &-filters {
      z-index: $z-globe-wrap + 1; // put in front of globe
      grid-column: 1 / span 4;
      grid-row: 2;
      &.terra-x-nav-topbar-filters--globe-open > * {
        @media (min-width: $break-terra-x-nav) {
          max-width: 570px;
        }
      }
      &--globe-open {
        @media (min-width: $break-terra-x-nav) {
          grid-column-end: span 2;
        }
      }
    }
    &-search {
      z-index: $z-globe-wrap + 1; // put in front of globe
      grid-column: 1 / span 4;
      grid-row: 3;
      &--globe-open {
        grid-row: 2;
      }
    }
    &__item {
      margin-top: 2rem;
    }
  }
  &-sidebar {
    z-index: $z-globe-wrap + 1; // put in front of globe
    position: sticky;
    top: calc(var(--nav-height) + 2rem);
    margin-top: 2rem;
    margin-bottom: 2rem;
    grid-column: 1 / span 1;
    grid-row: 4;
    &--globe-open {
      min-height: 50vh; // make sure modal doesn't cover globe on tablet portrait
    }
    @media (min-width: $break-terra-x-nav) {
      margin-top: initial;
      position: relative;
      top: auto;
      grid-column: 4 / span 1;
      grid-row: 1;
      &--globe-open {
        min-height: initial;
      }
    }
    &-content {
      width: 5rem;
      height: 29rem;
      @media (min-width: $break-terra-x-nav) {
        width: initial;
        height: initial;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 2rem;
        align-items: center;
        .terra-x-nav-views {
          grid-column: 2;
          grid-row: 1;
        }
      }
    }
  }
  &-list-wrapper {
    width: 100%;
    margin-top: -29rem + -2rem;
    grid-column: 2 / span 3;
    grid-row: 5;
    z-index: $z-globe-wrap + 1; // put in front of globe
    // border: 2px solid green;
    @media (min-width: $break-terra-x-nav) {
      margin-top: 2rem;
      padding-left: initial;
      grid-column: 1 / span 4;
    }
    &--globe-open {
      grid-row: 5;
      @media (min-width: $break-terra-x-nav) {
        grid-column: 3 / span 2;
        grid-row: 2 / span 2;
      }
    }
  }
}

.globe-placeholder {
  grid-row: 3;
  @media (min-width: $break-terra-x-nav) {
    grid-row: 2 / span 1;
    grid-row: 2;
  }
  &--filters-closed {
    grid-column: 2 / span 3;
    @media (min-width: $break-terra-x-nav) {
      grid-column: 1 / span 4;
    }
  }
  &--filters-open {
    grid-column: 2 / span 3;
    @media (min-width: $break-terra-x-nav) {
      grid-column: 3 / span 2;
    }
  }
}
</style>
