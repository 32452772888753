<template>
  <div>
    <IQuote
      :label="blok.label"
      :quote-size="blok.quoteTextSize"
      :title="blok.title"
      :quote="blok.quote"
      :citation="blok.citation"
      :inverted="false"
      :fontB1="false"
    />
  </div>
</template>

<script>
import IQuote from "@/components/items/I-Quote.vue";
export default {
  components: {
    IQuote,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>