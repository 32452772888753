<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M16 13L14.6 11.6L13 13.2V6H11V13.2L9.4 11.6L8 13L12 17L16 13Z" />
  </svg>
</template>

<script>
export default {
  name: "IconArrowDownShort"
};
</script>
