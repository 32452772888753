<template>
  <div class="news-archive" v-if="blok.newsList">
    <WListSimple
      :label="blok.label"
      :title="blok.title"
      :content="blok.content"
      :buttons="blok.buttons"
      :titleH3="!blok.titleLarge"
      :listItems="blok.newsList"
    >
      <template v-slot:default="{ listItem }">
        <RNews :blok="listItem" />
      </template>
    </WListSimple>
  </div>
</template>

<script>
import WListSimple from "@/components/wrappers/W-ListSimple.vue";
import RNews from "@/components/references/R-News.vue";
export default {
  name: "compNewsArchive",
  components: {
    WListSimple,
    RNews,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      news: [],
      listReady: false,
    };
  },
  computed: {
    filteredData() {
      let output = this.blok.newsList;
      let blok = this.blok;

      if (blok.listItems.length > 0) {
        let selected = output.filter((news) =>
          blok.listItems.includes(news.uuid)
        );
        let theRest = output.filter(
          (news) => !blok.listItems.includes(news.uuid)
        );

        output = [...selected, ...theRest];
      }
      if (blok.limit) {
        output = output.slice(0, Number(blok.limit));
      }
      return output;
    },
  },
};
</script>

<style lang="scss" scoped>
// .news-archive {
//   & /deep/ .C-list-item {
//     padding-bottom: 4rem;
//     padding-top: 0;
//     border-bottom: 0px;
//   }
// }
</style>
