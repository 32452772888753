var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WSection',{class:_vm.$options.name,attrs:{"margin":true}},[_c('WMaxWidth',[_c('div',{class:[
        'C',
        {
          addPadding: _vm.addPadding,
          placeLogosOnTheLeftSide: _vm.placeLogosOnTheLeftSide,
          placeLogosBelowText: _vm.placeLogosBelowText,
          stickyScroll: _vm.stickyScroll,
        } ]},[_c('header',[_c('IText',{attrs:{"content":_vm.content,"buttons":_vm.buttons,"inverted":false,"fontB1":false}})],1),_vm._v(" "),_c('ul',_vm._l((_vm.logos),function(referenceUrl,index){return _c('li',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.observed),expression:"observed"}],key:("logo-" + index),staticClass:"logo"},[_c('a',{staticClass:"logo-link",attrs:{"href":referenceUrl.url,"target":"_blank","rel":"noopener"}},[_c('ILazyImage',{staticClass:"logo-image",attrs:{"filename":referenceUrl.logo.filename,"alt":referenceUrl.logo.alt,"sizes":"(min-width: 600px) 30vw, 100vw","ratioW":16,"ratioH":0,"loading":"eager"}}),_vm._v(" "),_c('div',{staticClass:"cta"},[_c('span',[_vm._v("Visit website")])])],1)])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }