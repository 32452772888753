<template>
  <CompTerraXAside
    :accordions="accordions"
    :website="blok.website"
  />
</template>

<script>
import asideDataFormatMixin from "~/components/terra-x/projectsAndInvestors/asideDataFormatMixin"
import CompTerraXAside from "@/components/terra-x/projectsAndInvestors/CompTerraXAside"
export default {
  name: 'CompInvestorAside',
  components: {
    CompTerraXAside,
  },
  mixins: [asideDataFormatMixin],
  props: {
    blok: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    }
  },
  computed: {
    accordions() {
      return [
        {
          title: 'Overview',
          data: this.accordionInvestorOverview,
        },
      ]
    },
    accordionInvestorOverview() {
      return [
        {
          title: 'Investment type',
          body: this.formatArray(this.blok.availableFinancing, 'investors-available-financing'),
          fullWidth: true,
        },
        {
          title: 'Investment available',
          body: this.formatCurrency(this.blok.investmentAvailable),
          fullWidth: true,
        },
        {
          title: 'Sector focus',
          body: this.formatArray(this.blok.sectorFocus, 'sectors'),
          fullWidth: true,
        },
        {
          title: 'Geographical focus',
          body: this.formatArray(this.blok.geographicalFocus, 'regions'),
          fullWidth: true,
        },
        {
          title: 'Target return',
          body: this.formatCurrency(this.blok.targetReturn),
          fullWidth: true,
        },
        {
          title: 'HQ location',
          body: this.formatString(this.blok.headquarterLocationText),
          fullWidth: true,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
</style>