  <template>
  <WSection :margin="false">
    <WMaxWidth>
      <div class="seal-footer" v-observe-visibility="observed">
        <div class="seal-footer__content">
          <div class="title">{{ title }}</div>
          <div class="email">
            <a :href="'mailto:' + email">{{ email }}</a>
          </div>
        </div>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
export default {
  name: "SealFooter",
  components: {
    WSection,
    WMaxWidth,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.seal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__placeholder {
    content: "";
    width: 100%;
    @media (min-width: $break-tablet-landscape) {
      width: calc(100% * (10 / 12));
    }
  }
  &__content {
    z-index: 2;
    margin-top: auto;
    padding: 3rem;
    text-align: center;
    @extend %body2;
    opacity: 0.8;
    & a {
      color: $color-blue;
      font-weight: 600;
      opacity: 0.8;
      transition: 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
