<template>
  <article class="accordion">
    <header class="accordion__header" @click="toggle">
      <span class="accordion__title">{{ title }}</span>
      <div class="accordion__header-icon">
        <IconArrowDownShort :class="{ open }" />
      </div>
    </header>
    <TransitionExpandHeight :expanded="open" :classes="['accordion__body']">
      <slot></slot>
    </TransitionExpandHeight>
  </article>
</template>

<script>
import TransitionExpandHeight from "@/components/utils/TransitionExpandHeight.vue";
import IconArrowDownShort from "@/components/icons/IconArrowDownShort.vue";
export default {
  components: {
    TransitionExpandHeight,
    IconArrowDownShort,
  },
  name: "W-Accordion",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggle() {
      if (this.open) {
        this.$emit("close");
      } else {
        this.$emit("open");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  background-color: $color-grey-light;
  @extend %body3;
  &__header {
    position: relative;
    padding: 1.4rem 2.7rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    cursor: pointer;
  }
  &__header-icon {
    height: 2.4rem;
    svg {
      transition: all 250ms;
      &.open {
        transform: rotate(-180deg);
      }
    }
  }
  /deep/ &__body {
    padding: 0 2.7rem 2rem;
  }
}
</style>