<template>
  <div class="terra-x-search">
    <input
      id="search"
      type="text"
      v-model="search"
      @keyup="setQ"
      :placeholder="placeholder"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  name: "TerraXNavSearch",
  computed: {
    search: {
      get() {
        return this.$store.state.terrax.routeQuery.search;
      },
      set(value) {
        this.$store.commit("terrax/setSearch", value);
      },
    },
    placeholder() {
      let type = this.$store.getters["terrax/getType"]();
      let string = "Type to search";
      switch (type) {
        case "projects":
          string += " for projects";
          break;
        case "investors":
          string += " for investors";
          break;
        case "insurance":
          string += " for insurance";
          break;
      }
      string += "...";
      return string;
    },
  },
  methods: {
    setQ() {
      this.$store.dispatch("terrax/setUrlQuery", this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
.terra-x-search {
  animation: fadeInUp 300ms ease-in-out forwards; // keyframe in baseApp.scss
  & input {
    @extend %input-search;
    width: 100%;
    color: #fff;
  }
}
</style>
