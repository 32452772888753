<template>
  <WSection class="documentation">
    <h2 class="documentation-header__title h3" v-observe-visibility="observed">
      Documentation
    </h2>
    <ul class="documentation-list">
      <li
        class="documentation-list__item"
        v-for="listItem in blok.listItems"
        :key="listItem._uid"
        v-observe-visibility="observed"
        v-editable="listItem"
      >
        <RFile :blok="listItem" :slim="true" />
      </li>
    </ul>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import RFile from "@/components/references/R-File.vue";
export default {
  components: {
    WSection,
    RFile,
  },
  name: "CompProjectDocumentation",
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.documentation {
  flex-direction: column;
}
.documentation-header {
  &__title {
    margin-bottom: 2rem;
  }
}
.documentation-list {
  &__item {
    border-bottom: 1px solid $color-blue-lighter;
    padding-bottom: 2rem;
    &:not(:first-child) {
      padding-top: 3rem;
    }
    @media (min-width: $break-tablet-portrait) {
      padding-bottom: 3rem;
      &:not(:first-child) {
        padding-top: 5rem;
      }
    }
  }
}
</style>