<template>
  <div
    :class="[
      'terra-x-background-bar-outer',
      { 'terra-x-background-bar-move': front },
    ]"
  >
    <div
      :class="[
        'terra-x-background-bar',
        {
          full: $store.state.layout !== 'LayoutTerraX',
        },
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TerraXBackgroundBar",
  props: {
    front: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.terra-x-background-bar-outer {
  position: fixed;
  top: -$nav-height;
  left: 0;
  bottom: 0;
  width: 0;
  z-index: -1;
  width: 100vw;
  transition: translate 250ms linear;
}
.terra-x-background-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $color-blue;
  transition: transform $globe-transition-duration-transform / 2
      $nav-sidebar-transition-speed cubic-bezier($ease-out-cubic),
    opacity 500ms;
  transform: translateX(-100%);
  @media (min-width: $break-nav) {
    transform: translateX(-70%);
  }
  &.full {
    transition: transform $globe-transition-duration-transform / 2
        cubic-bezier($ease-in-out-cubic),
      opacity 500ms;
    transform: translateX(0%);
  }
  &.hide {
    transition: transform 0.35s 0.1s cubic-bezier($ease-in-cubic);
    transform: translateX(-100%);
  }
}
</style>
