<template>
  <div class="seal-grid__wrap">
    <WMaxWidth>
      <div :class="['seal-grid', blok.sliderPosition]">
        <div class="seal-grid__placeholder">
          <div :class="['wrap-slides', blok.sliderRatio]">
            <div class="wrap-slides__corner-left" v-if="showFrame"></div>
            <div class="wrap-slides__corner-right" v-if="showFrame"></div>
            <RSlider
              :slides="blok.images"
              :ratioW="ratio[0]"
              :ratioH="ratio[1]"
              :dotColor="blok.colorDots"
              :center="centerDots"
              :showDots="showDots"
              :autoPlay="autoPlay"
              animation="fade"
            />
          </div>
        </div>
        <div class="seal-grid__content">
          <div
            v-for="item in blok.items"
            :key="item.id"
            class="seal-grid__content__item"
          >
            <component :is="item.component" :blok="item" />
          </div>
        </div>
      </div>
    </WMaxWidth>
  </div>
</template>

<script>
import SealGraphic from "@/components/seal/SealGraphic";
import SealStickyText from "@/components/seal/SealStickyText";
import SealStickyBlockquote from "@/components/seal/SealStickyBlockquote";

import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import RSlider from "@/components/references/R-Slider.vue";
export default {
  name: "SealIntro",
  components: {
    SealGraphic,
    SealStickyText,
    SealStickyBlockquote,
    WSection,
    WMaxWidth,
    RSlider,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ratio() {
      let ratio = [4, 4];
      if (this.blok.sliderRatio === "landscape") {
        ratio = [16, 9];
      } else if (this.blok.sliderRatio === "portrait") {
        ratio = [5.21, 6.2];
      }
      return ratio;
    },
    showFrame() {
      let show = true;
      if (this.blok.showFrame === "false") {
        show = false;
      }
      return show;
    },
    showDots() {
      let show = true;
      if (this.blok.showDots === "false") {
        show = false;
      }
      return show;
    },
    centerDots() {
      let center = true;
      if (this.blok.centerDots === "false") {
        center = false;
      }
      return center;
    },
    autoPlay() {
      let auto = false;
      if (this.blok.autoPlay === "true") {
        auto = true;
      }
      return auto;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-slides {
  position: relative;
  height: 0;
  padding-bottom: 60%;
  width: 100%;
  &.square {
    padding-bottom: 100%;
  }
  &.portrait {
    padding-bottom: 120%;
  }
  &__corner-left {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      border-left: 1px solid rgba(0, 0, 0, 0.5);
    }
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      border-left: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
  &__corner-right {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      border-right: 1px solid rgba(0, 0, 0, 0.5);
    }
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      width: 15px;
      height: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      border-right: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
}
.seal-grid {
  position: relative;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: #fff;

  @extend %grid-column-gap;
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";
  @media (min-width: $break-tablet-portrait) {
    max-width: none;
    grid-template-rows: initial;
    grid-template-areas: " se1 se1 se1 se1 se1 se1 .  se2 se2 se2 se2 se2 ";
    &.right {
      grid-template-areas: "se2 se2 se2 se2 se2  . se1 se1 se1 se1 se1 se1 ";
    }
  }

  @media (min-width: $break-desktop-small) {
    max-width: none;
    grid-template-rows: initial;
    grid-template-areas: " . se1 se1 se1 se1 se1 . se2 se2 se2 se2  . ";
    &.right {
      grid-template-areas: ". se2 se2 se2 se2 . se1 se1 se1 se1 se1 . ";
    }
  }
  &__placeholder {
    grid-area: se1;
    margin-bottom: 30px;
    margin-top: 30px;
    @media (min-width: $break-tablet-portrait) {
      margin-bottom: 60px;
      margin-top: 60px;
      align-self: start;
      display: flex;
      z-index: 100;
      top: 0;
      align-items: center;
      position: sticky;
      min-height: 100vh;
      margin: 0;
    }
  }
  &__content {
    grid-area: se2;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      @media (min-width: $break-tablet-portrait) {
        min-height: 100vh;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
