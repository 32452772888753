var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"terra-x-intro"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.description))]),_vm._v(" "),_c('div',{staticClass:"terra-x-intro__buttons"},[_c('div',{staticClass:"terra-x-intro__buttons__item"},[_c('span',{staticClass:"h4"},[_vm._v("\n          Projects\n          "),_c('sup',[_vm._v(_vm._s(_vm.projectsCount))])]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.projectsDescription))]),_vm._v(" "),_c('nuxt-link',{staticClass:"terra-x-intro__link",attrs:{"to":{
            path: _vm.$urlResolver(_vm.$config.terraXProjectsPage),
            query: {
              globe: true,
            },
          }},nativeOn:{"mouseover":function($event){return _vm.setType('projects')},"mouseleave":function($event){return _vm.setType('projects')}}},[_c('IconArrowLeft'),_vm._v(" "),_c('span',[_vm._v("Find projects")])],1)],1),_vm._v(" "),_c('div',{staticClass:"terra-x-intro__buttons__item"},[_c('span',{staticClass:"h4"},[_vm._v("\n          Insurance\n          "),_c('sup',[_vm._v(_vm._s(_vm.insuranceCount))])]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.insuranceDescription))]),_vm._v(" "),_c('nuxt-link',{staticClass:"terra-x-intro__link",attrs:{"to":{
            path: _vm.$urlResolver(_vm.$config.terraXInsurancePage),
            query: {
              globe: false,
            },
          }}},[_c('IconArrowLeft'),_vm._v(" "),_c('span',[_vm._v("Find insurance")])],1)],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }