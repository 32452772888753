<template>
  <WSection :margin="true" :class="($options.name, { dark: darkTheme })">
    <WMaxWidth>
      <div :class="['C']">
        <div>
          <IText
            :label="blok.label"
            :content="blok.content"
            :inverted="false"
            :fontB1="true"
          ></IText>
        </div>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
export default {
  name: "compArticle",
  props: {
    blok: {
      type: Object,
      required: true
    },
    hasLayoutAstra: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WSection,
    WMaxWidth,
    IText
  },
  computed: {
    darkTheme() {
      if (this.$store.state.layout === "LayoutAstra") {
        return this.blok.settings.includes("dark");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dark {
  background-color: $color-astra-black;
  color: $color-white;
}
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas: "cont cont cont cont cont cont cont cont cont cont cont cont";

  @media (min-width: $break-mobile-landscape) {
    grid-template-areas: ". cont cont cont cont cont cont cont cont cont cont .";
  }

  @media (min-width: $break-tablet-landscape) {
    grid-template-areas: ". . cont cont cont cont cont cont cont cont . .";
  }

  @media (min-width: $break-desktop-small) {
    grid-template-areas: ". . . cont cont cont cont cont cont . . .";
  }

  > div {
    grid-area: cont;
  }
}
</style>
