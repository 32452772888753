<template>
  <div class="icon-sdg">
    <img :src="`/icons/sdg-icons/${sdgIconFilename}`" :alt="sdgTitle || sdgName">
  </div>
</template>

<script>
export default {
  name: "IconSDG",
  props: {
    sdgName: {
      type: String,
      required: true,
    },
    sdgTitle: {
      type: String,
    },
  },
  computed: {
    sdgIconFilename() {
      switch (this.sdgName) {
        case '01-no-poverty':
          return 'sdg-01.svg'
        case '02-zero-hunger':
          return 'sdg-02.svg'
        case '03-good-health-and-well-being':
          return 'sdg-03.svg'
        case '04-quality-education':
          return 'sdg-04.svg'
        case '05-gender-equality':
          return 'sdg-05.svg'
        case '06-clean-water-and-sanitation':
          return 'sdg-06.svg'
        case '07-affordable-and-clean-energy':
          return 'sdg-07.svg'
        case '08-decent-work-and-economic-growth':
          return 'sdg-08.svg'
        case '09-industry-innovation-and-infrastructure':
          return 'sdg-09.svg'
        case '10-reduce-inequalities':
          return 'sdg-10.svg'
        case '11-sustainable-cities-and-communities':
          return 'sdg-11.svg'
        case '12-responsible-consumption-and-production':
          return 'sdg-12.svg'
        case '13-climate-action':
          return 'sdg-13.svg'
        case '14-life-below-water':
          return 'sdg-14.svg'
        case '15-life-on-land':
          return 'sdg-15.svg'
        case '16-peace-justice-and-strong-institutions':
          return 'sdg-16.svg'
        case '17-partnerships':
          return 'sdg-17.svg'
        default:
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-sdg {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>