<template>
  <WSection
    :margin="true"
    :paddings="false"
    :class="[$options.name, { slim: isSlim }]"
  >
    <WMaxWidth>
      <div
        :class="[
          'C',
          { isSlim: isSlim, leftImage: isLeftImage, dark: isLayoutAstra }
        ]"
      >
        <section v-if="!isSlim">
          <IText :label="label" :content="content"></IText>
        </section>
        <ul class="buttns">
          <li
            v-for="(item, index) in downloads"
            :key="`${index}`"
            v-observe-visibility="observed"
          >
            <a
              class="buttn"
              target="_blank"
              :href="`/${item.filePath}`"
              :title="`Download the ${item.label}`"
              @mouseenter.prevent="
                e => {
                  hoverDownload(index);
                }
              "
              @mouseleave="
                e => {
                  hoverDownload();
                }
              "
            >
              <IconCircleArrow />
              <span>Download the {{ item.label }}</span>
            </a>
          </li>
        </ul>
        <figure
          v-if="!isSlim"
          :class="['thumbnails', { single: single, stack: stack }]"
          v-observe-visibility="observed"
        >
          <!-- <template v-for="index in 7">
            <div class="page" :key="'page-' + index"></div>
          </template> -->
          <div class="cover">
            <div v-for="(item, index) in downloads" :key="`${index}`">
              <!-- <WPictureSourceSet
                :image="item.fields.thumbnail"
                :sizes="thumbnailSizes"
              /> -->
              <ILazyImage
                :filename="item.thumbnail.filename"
                :alt="item.thumbnail.alt"
                sizes="(min-width: 600px) 30vw, 100vw"
                :ratioW="16"
                :ratioH="0"
              />
              <a
                class="buttn"
                target="_blank"
                :href="`/${item.filePath}`"
                :title="`Download the ${item.label}`"
              >
                <IconCircleArrow />
                <span>Download the {{ item.label }}</span>
              </a>
            </div>
          </div>
        </figure>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IconCircleArrow from "@/components/icons/IconCircleArrow.vue";
import IText from "@/components/items/I-Text.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "C-Downloads",
  components: {
    WSection,
    WMaxWidth,
    IconCircleArrow,
    IText,
    ILazyImage
  },
  props: {
    blok: {
      type: Object,
      required: true
    },
    isAstra: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stack: false,
      single: false
    };
  },
  computed: {
    isLayoutAstra() {
      if (this.$store.state.layout === "LayoutAstra") {
        return !this.isAstra;
      }
    },
    isLeftImage() {
      return this.blok.settings.includes("leftImage");
    },
    showLabel() {
      return this.blok.settings.includes("showLabel");
    },
    isSlim() {
      return this.blok.settings.includes("slimDesign");
    },
    label() {
      return this.blok.label;
    },
    content() {
      return this.blok.description;
    },

    downloads() {
      return this.blok.downloads;
    },
    thumbnailSizes() {
      return `
          95vw
        `;
    }
  },
  methods: {
    hoverDownload(index) {
      if (index == undefined) {
        this.single = false;
        this.stack = false;
      } else if (index == 0) {
        this.stack = true;
        this.single = false;
      } else {
        this.stack = true;
        this.single = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.C-Downloads {
  background-color: $color-white-off;
  .buttn {
    display: flex;
    align-items: center;
    @extend %lead;
    font-size: 1.4rem;
    font-weight: 700;
    white-space: nowrap;
    padding: 2rem 0;
    svg {
      margin-right: 2rem;
      color: $color-terra-green;
    }
    span {
      transform: translateY(0.1rem);
      color: $color-terra-green;
      white-space: nowrap;
      &:after {
        content: "";
        display: block;
        top: 100%;
        width: 100%;
        height: 0.2rem;
        background-color: $color-terra-green;
        transform-origin: 0% 50%;
        transform: translateY(0.1rem) scale(0, 1);
        // transition: transform 0.5s cubic-bezier($ease-out-cubic);
      }
    }
    @media (hover: hover) {
      &:hover {
        span {
          &:after {
            transform: translateY(0.1rem) scale(1, 1);
          }
        }
      }
    }
  }
  .C {
    &.isSlim {
      padding: 2.5rem 0;
      .buttns {
        @media (min-width: $break-mobile-portrait + ($break-mobile-landscape - $break-mobile-portrait) / 2) {
          display: flex;
          flex-direction: column;
          .buttn {
            justify-content: center;
          }
        }

        @media (min-width: $break-mobile-landscape + 100px) {
          flex-direction: row;
          justify-content: space-evenly;
          li {
            margin-top: 0 !important;
          }
        }
      }
    }
    &:not(.isSlim) {
      @extend %padding-top-bottom-x1;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      @extend %grid-column-gap;

      .buttns {
        margin-top: 2.5rem;
      }
      &.dark {
        section {
          .I-Text {
            color: $color-astra-blue;
          }
        }
        .buttns {
          span,
          svg {
            color: $color-astra-blue;
          }
        }
      }
      section {
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 1;
        .I-Text {
          color: $color-terra-green;
        }
      }
      .buttns {
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 2;
        display: none;
      }
      .thumbnails {
        margin-top: 4rem;
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 3;
        .cover {
          > div {
            .buttn {
              justify-content: center;
              padding: 2rem;
            }
            &:not(:first-child) {
              margin-top: 4rem;
            }
          }
        }
      }
      @media (min-width: $break-mobile-landscape) {
        section {
          grid-column-start: 2;
          grid-column-end: 12;
        }
        .thumbnails {
          grid-column-start: 3;
          grid-column-end: 11;
        }
      }
      @media (min-width: $break-tablet-portrait) {
        &.leftImage {
          section {
            grid-column-start: 7;
          }
          .buttns {
            grid-column-start: 7;
          }
          .thumbnails {
            grid-column-start: 1;
          }
        }
        section {
          grid-column-start: 2;
          grid-column-end: span 5;
          grid-row: 2;
        }
        .buttns {
          grid-column-start: 2;
          grid-column-end: span 6;
          grid-row: 3;
          display: block;
        }
        .thumbnails {
          overflow-x: initial;
          position: relative;
          grid-column-start: 8;
          grid-column-end: span 5;
          grid-row-start: 1;
          grid-row-end: 5;
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
          align-self: center;
          > div {
            transition: transform 0.5s cubic-bezier($ease-out-cubic);
          }
          .buttn {
            display: none;
          }
          .cover {
            z-index: 10;
            position: relative;
            > div {
              &:first-child {
                width: auto;
                position: relative;
                z-index: 1;
                transition: opacity 0.5s cubic-bezier($ease-out-cubic);
              }
              &:not(:first-child) {
                margin-top: 0;
                position: absolute;
                top: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
          .page {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            // background-color: white;
          }
          $offset: 1.25%;
          &.stack {
            > div {
              // @for $i from 1 through 8 {
              //   &:nth-child(#{$i}) {
              transform: translate(-(1 * $offset), -(1 * $offset));

              //   }
              // }
            }
          }
          &.single {
            > div {
              &:nth-child(1) {
                div {
                  .image {
                    // border: 0.15rem solid #eeeeee;
                  }
                }
                transform: translate(-$offset * 2, -$offset * 2);
              }
            }
            .cover {
              > div {
                &:first-child {
                  opacity: 0;
                }
                &:not(:first-child) {
                }
                opacity: 1;
              }
            }
          }
        }
      }
      @media (min-width: $break-desktop-small) {
        section {
          grid-column-end: span 4;
        }
        .buttns {
          display: flex;
          //justify-content: space-evenly;
          li {
            margin-top: 0 !important;
            &:not(:first-child) {
              margin-left: 4rem;
            }
          }
        }
      }
    }
  }
}
</style>
