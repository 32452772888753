import { render, staticRenderFns } from "./CompListChapters.vue?vue&type=template&id=024ecbc2&scoped=true&"
import script from "./CompListChapters.vue?vue&type=script&lang=js&"
export * from "./CompListChapters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024ecbc2",
  null
  
)

export default component.exports