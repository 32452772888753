<template>
  <div
    :class="[$options.name]"
    v-observe-visibility="visibility"
    ref="container"
    :style="{
      '--aspect-difference': aspectDifference,
    }"
  >
    <div class="vimeo-embed">
      <div class="vimeo-embed-iframe" v-if="isVisible">
        <IIframe
          :vimeo-id="vimeoData.id"
          :vimeo-title="vimeoData.title"
          :vimeo-width="vimeoData.width"
          :vimeo-height="vimeoData.height"
        />
      </div>
      <ILazyImage
        v-if="poster && poster.filename"
        :filename="poster.filename"
        :alt="poster.alt"
        :sizes="posterSizes"
        class="vimeo-embed-image"
        :ratioW="vimeoData.width"
        :ratioH="vimeoData.height"
        loading="eager"
      />
    </div>
  </div>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
import IIframe from "@/components/items/I-Iframe.vue";
export default {
  name: "W-VimeoLoop",
  components: {
    ILazyImage,
    IIframe,
  },
  props: {
    vimeoData: {
      type: Object,
      required: true,
    },
    poster: {
      type: Object,
      required: false,
    },
    posterSizes: {
      type: String,
      required: false,
      default:
        "(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)",
    },
    alt: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      containerAspect: 1,
      isVisible: false,
    };
  },
  computed: {
    aspectDifference() {
      const aspectVideo = this.vimeoData.width / this.vimeoData.height;
      const aspectContainer = this.containerAspect;
      var aspect = null;
      if (aspectVideo > aspectContainer) {
        aspect = aspectVideo / aspectContainer;
      } else {
        aspect = aspectContainer / aspectVideo;
      }
      return aspect;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    visibility(isVisible, entry) {
      if (isVisible) {
        this.isVisible = true;
      }
    },
    getContainerAspect() {
      const box = this.$refs.container;
      const height = box.offsetHeight;
      const width = box.offsetWidth;
      const aspect = width / height;
      this.containerAspect = aspect;
    },
    resize() {
      this.getContainerAspect();
    },
  },
};
</script>

<style lang="scss" scoped>
.W-VimeoLoop {
  position: relative;
  width: 100%;
  height: 100%;
  //background-color: $color-blue;
}
.vimeo-embed {
  position: relative;

  height: 100%;
  width: 100%;
  overflow: hidden;
  &-iframe {
    z-index: 5;
    position: relative;
    height: 100%;
    width: 100%;
    & /deep/ iframe {
      position: absolute;
      height: 100%;
      width: calc(var(--aspect-difference) * 102%);
      top: 50%;
      left: 51%;
      transform: translateY(-50%) translateX(-51%);
    }
  }
  &-image {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    & /deep/ img {
      position: absolute;
      max-width: none;
      width: calc(var(--aspect-difference) * 100%);
      top: 50%;
      left: 50%;

      transform: translateY(-50%) translateX(-50%);
    }
  }
}
</style>
