<template>
  <ILink
    class="RTextImage"
    :class="{
      'RTextImage--slim': slim,
      'RTextImage--no-thumbnail': !blok.thumbnail || !blok.thumbnail.filename,
      'RTextImage--has-thumbnail': blok.thumbnail || blok.thumbnail.filename
    }"
  >
    <ILazyImage
      class="RTextImage-thumbnail"
      :filename="blok.thumbnail.filename"
      :alt="blok.thumbnail.alt"
      sizes="(min-width: 600px) 400px, 100vw"
      :ratioW="16"
      :ratioH="0"
    />
    <div class="RTextImage-content">
      <h3 class="RTextImage-title">{{ blok.title }}</h3>
      <p class="RTextImage-description" v-if="blok.description">
        {{ blok.description }}
      </p>
    </div></ILink
  >
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "RTextImage",
  components: {
    ILazyImage
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.RTextImage {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  grid-template-areas:
    "img img img img img img img img img img img img"
    "txt txt txt txt txt txt txt txt txt txt txt txt";

  @media (min-width: $break-mobile-landscape) {
    grid-template-areas: "img img img img img txt txt txt txt txt txt txt";
  }
  @media (min-width: $break-tablet-portrait) {
    grid-template-areas: "img img img img txt txt txt txt txt txt txt . ";
  }
  &-thumbnail {
    grid-area: img;
    max-width: 100%;
    height: 40vw;
    overflow: hidden;
    @media (min-width: $break-mobile-landscape) {
      height: 100%;
      margin-right: 2rem;
    }
    & /deep/ img {
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }
  &-content {
    grid-area: txt;
    padding: 1rem 0;
  }
  &-header {
    margin-top: 0.625em;
    margin-bottom: 0.625em;
  }
  &-title {
    @extend %headline3;
  }
  @media (hover: hover) {
    &:hover {
      /deep/ img {
        transform: scale(1);
      }
    }
  }
  &-description {
    margin-top: 0.625em;
  }
  &--slim {
    @media (min-width: $break-mobile-landscape) {
      grid-template-areas: "img img img txt txt txt txt txt txt txt txt txt";
    }
    @media (min-width: $break-tablet-portrait) {
      grid-template-areas: "img img img txt txt txt txt txt txt txt txt . ";
    }
    .RTextImage-title {
      @extend %headline4;
    }
  }
  &--has-thumbnail {
    min-height: 20vh;
  }
  &--no-thumbnail {
    grid-template-areas: "txt txt txt txt txt txt txt txt txt txt txt . ";
  }
}
</style>
