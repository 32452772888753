<template>
  <main v-editable="blok" class="news">
    <div class="news-article" ref="scrollParent">
      <div class="news-article__content" ref="scrollChild">
        <div class="news-intro">
          <div class="news-intro__image">
            <ILazyImage
              :filename="blok.image.filename"
              :alt="blok.image.alt"
              sizes="(min-width: 600px) 60vw, 100vw"
              :ratioW="16"
              :ratioH="7"
            />
          </div>
          <div class="news-article--space">
            <WMaxWidth>
              <div class="news-description" v-observe-visibility="observed">
                <header class="news-header">
                  <span v-if="blok.label" class="news-header-label">
                    {{ blok.label }}
                  </span>
                  <span v-if="date" class="news-header-date">{{ date }}</span>
                </header>
                <h3 class="news-title">{{ blok.title }}</h3>
                <p class="news-description">{{ blok.description }}</p>
              </div>
            </WMaxWidth>
          </div>
        </div>
        <div class="news-article--space">
          <component
            v-for="(item, i) in blok.body"
            :key="item.id"
            :blok="item"
            :class="{ 'no-space': i === 0 }"
            :is="item.component"
            v-editable="item"
          />
        </div>
      </div>
      <div class="news-article__close">
        <button @click="goTo" class="news-article__btn">
          <svg fill="none" viewBox="0 0 89 89">
            <circle
              class="fill-circle"
              cx="44.5"
              cy="44.5"
              r="35.5"
              fill="#fff"
            />
            <circle
              class="fill-stroke-circle"
              transform="rotate(-90,44.5,44.5)"
              :stroke-dasharray="circumference + ' ' + circumference"
              :style="{ strokeDashoffset: strokeDashoffset }"
              :stroke-width="stroke"
              fill="transparent"
              :r="normalizedRadius"
              :cx="radius"
              :cy="radius"
            />
            <path
              class="fill-stroke-path"
              d="M37.354 35.646l16 16M36.646 51.646l16-16"
            />
          </svg>
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import CompArticle from "@/components/comps/CompArticle";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "LayoutNewsArticle",
  props: {
    blok: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
      radius: 44.5,
      stroke: 3,
      scheduledAnimationFrame: false,
    };
  },
  components: {
    CompArticle,
    WMaxWidth,
    ILazyImage,
  },
  computed: {
    normalizedRadius() {
      return this.radius - this.stroke * 2;
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    date() {
      const dateString = this.blok.date.replace(/-/g, "/").replace(/T.+/, "");
      const d = new Date(dateString);
      const day = ("0" + d.getDate()).slice(-2);
      const month = d.toLocaleString("default", { month: "long" });
      const year = d.getFullYear();
      return this.onlyDisplayMonthAndYear
        ? `${month} ${year}`
        : `${day} ${month} ${year}`;
    },
  },
  methods: {
    goTo() {
      if (this.$store.state.pages.hasBackUrl) {
        this.$router.back();
      } else {
        this.$router.push("/news-archive");
      }
    },
    observed(isVisible, entry) {
      this.animateIn(isVisible, entry);
    },
    getScrollPosition() {
      const scrollParent = this.$refs.scrollParent.getBoundingClientRect();
      const scrollChild = this.$refs.scrollChild.getBoundingClientRect();

      let scrollParentHeight = Number(scrollParent.height);
      let scrollChildTop = Number(scrollChild.top);

      let scrollChildHeight = Number(scrollChild.height);

      // TODO: DO better calculations

      let scrollProgress =
        ((scrollChildHeight - scrollParentHeight + scrollChildTop) /
          (scrollChildHeight - scrollParentHeight)) *
        100;
      scrollProgress = (scrollProgress - 100) * -1;

      if (scrollProgress > 99) {
        scrollProgress = 100;
      }
      this.progress = scrollProgress;
      this.scheduledAnimationFrame = false;
    },
    updateScrollPosition(e) {
      if (this.scheduledAnimationFrame) {
        return;
      }
      this.scheduledAnimationFrame = true;
      requestAnimationFrame(this.getScrollPosition);
    },
  },
  mounted() {
    this.$refs.scrollParent.addEventListener(
      "scroll",
      this.updateScrollPosition
    );
  },
  beforeDestroy() {
    this.$refs.scrollParent.removeEventListener(
      "scroll",
      this.updateScrollPosition
    );
  },
};
</script>

<style lang="scss" scoped>
.news {
  &-description {
    padding: 2rem 0 0;
    @extend %body1;
    font-weight: bold;
  }
  &-header {
    margin-top: 0.625em;
    margin-bottom: 0.625em;
    &-label {
      @extend %lead;
      font-weight: 900;
      margin-right: 1em;
    }
    &-date {
      @extend %lead;
    }
  }
  &-title {
    @extend %headline3;
  }
  & /deep/ .C {
    @media (min-width: $break-mobile-landscape) {
      grid-template-areas: "cont cont cont cont cont cont cont cont cont cont cont cont";
    }
  }
  & .no-space {
    padding-top: 4rem;
  }
}
.news-article {
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2000;
  overflow: scroll;
  position: fixed;
  &__content {
    background-color: #fff;
    width: 100%;
    padding-bottom: 60px;
    @media (min-width: $break-mobile-landscape) {
      width: 60vw;
    }
  }
  &--space {
    max-width: 1024px;
    margin: 0 auto;
  }
  &__close {
    opacity: 0;
    background-color: $color-blue;
    position: fixed;
    right: -200vw;
    top: 0;
    display: flex;
    align-items: center;
    width: 200vw;
    height: 100%;
    animation: colorFadeIn 1200ms ease-in-out forwards;
    @media (min-width: $break-mobile-landscape) {
      right: -160vw;
    }
  }
  &__btn {
    width: 89px;
    height: 89px;
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transform: scale(0.75);
    transform-origin: top right;
    @media (min-width: $break-mobile-landscape) {
      margin-left: 50px;
      position: relative;
      top: 0;
      right: 0;
      transform: scale(1);
    }
  }
}
.fill-circle {
  fill: $color-blue;
  @media (min-width: $break-mobile-landscape) {
    fill: #fff;
  }
}
.fill-stroke-path {
  stroke: #fff;
  @media (min-width: $break-mobile-landscape) {
    stroke: $color-blue;
  }
}
.fill-stroke-circle {
  stroke: $color-blue;
  @media (min-width: $break-mobile-landscape) {
    stroke: #fff;
  }
}
@keyframes colorFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
