<template>
  <WSection :margin="true" :class="[$options.name, 'bg-blue', 'wrap-globe']">
    <WMaxWidth>
      <div
        :class="[
          'C',
          {
            imageOnLeftSide: true,
          },
        ]"
      >
        <section class="globe-image">
          <img
            class="blob"
            src="/placeholders/earth.png"
            width="812"
            height="812"
            alt="placeholder earth"
          />
          <transition name="fade">
            <img
              class="image"
              v-if="earthSource"
              :src="earthSource"
              alt="Earth"
              width="812"
              height="812"
              @load="onEarthLoaded"
            />
          </transition>
        </section>
        <section class="globe-text">
          <div class="content">
            <IText
              :title="blok.title"
              :content="blok.content"
              :buttons="blok.buttons"
              :inverted="true"
              :fontB1="false"
            />
          </div>
        </section>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
export default {
  name: "compGlobe",
  components: {
    WSection,
    WMaxWidth,
    IText,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lat: null,
      lon: null,
      city: null,
      date: null,
      time: null,
      imgSize: 812,
      earthLoaded: false,
      earthTimeoutDone: false,
    };
  },
  computed: {
    dateString() {
      const coeff = 1000 * 60 * 10;
      const date = new Date();
      const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
      return rounded.toISOString().replace(/T/, "+").replace(/\..+/, "");
    },
    earthSource() {
      if (this.earthTimeoutDone && this.lat && this.lon && this.dateString) {
        return `https://www.fourmilab.ch/cgi-bin/Earth?img=NASAmMM-l.evif&imgsize=${this.imgSize}&dynimg=y&gamma=1.32&opt=-l&lat=${this.lat}&ns=North&lon=${this.lon}&ew=East&alt=&tle=&date=1&utc=${this.dateString}`;
      }
      return false;
    },
  },
  methods: {
    onEarthLoaded() {
      this.earthLoaded = true;
    },
    getDate() {
      const date = new Date();

      this.date = date.getFullYear();

      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.time = hours + ":" + minutes + " " + ampm;
    },
    async getGeo() {
      if (!this.lat) {
        const geo = await fetch("https://reallyfreegeoip.org/json/")
          .then((promise) => promise.json())
          .catch(function (error) {
            //console.log('Geo IP error: \n', error);
          });

        this.lat = geo.latitude;

        let lon = parseFloat(geo.longitude);
        if (lon < 0) {
          lon = 360 + lon;
        }
        this.lon = lon;
        this.city = geo.city;
      }
    },
  },
  mounted() {
    this.earthTimeoutDone = true;
    //console.log(this.footer)

    this.getDate();

    this.getGeo();
  },
};
</script>

<style lang="scss" scoped>
.wrap-globe {
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bg-blue {
  background-color: $color-blue;
}
.content {
  max-width: 412px;
  min-width: 240px;
}
.globe-image {
  position: relative;
  height: clamp(512px, 40vw, 812px);
}
.blob,
.image {
  width: clamp(512px, 40vw, 812px);
  height: clamp(512px, 40vw, 812px);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}
.globe-text {
  grid-column: 3;
}
.C {
  display: grid;
  grid-template-columns: 6fr 40px 5fr;
  align-items: center;
  @media (min-width: $break-mobile-landscape) {
    grid-template-columns: 6fr 100px 5fr;
  }
}
</style>
