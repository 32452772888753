<template>
  <div class="terra-x-list">
    <component :is="xType" :x-data="xData"></component>
  </div>
</template>

<script>
import investors from "@/components/terra-x/TerraXListInvestors.vue";
import projects from "@/components/terra-x/TerraXListProjects.vue";
import insurance from "@/components/terra-x/TerraXListInsurance.vue";

export default {
  name: "ITerraXList",
  props: {
    xType: {
      type: String,
      default: {},
    },
    xData: {
      type: Array,
    },
  },
  components: {
    investors,
    projects,
    insurance,
  },
  methods: {
    callback() {
      this.$emit("callback", "some value");
    },
  },
  mounted() {
    console.log(this.xType);
  },
};
</script>
