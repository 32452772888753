<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <div
        :class="[
          'C',
          {
            addPadding: addPadding,
            placeLogosOnTheLeftSide: placeLogosOnTheLeftSide,
            placeLogosBelowText: placeLogosBelowText,
            stickyScroll: stickyScroll,
          },
        ]"
      >
        <header>
          <IText
            :content="content"
            :buttons="buttons"
            :inverted="false"
            :fontB1="false"
          ></IText>
        </header>
        <ul>
          <li
            v-for="(referenceUrl, index) in logos"
            :key="`logo-${index}`"
            class="logo"
            v-observe-visibility="observed"
          >
            <a
              :href="referenceUrl.url"
              target="_blank"
              class="logo-link"
              rel="noopener"
            >
              <ILazyImage
                class="logo-image"
                :filename="referenceUrl.logo.filename"
                :alt="referenceUrl.logo.alt"
                sizes="(min-width: 600px) 30vw, 100vw"
                :ratioW="16"
                :ratioH="0"
                loading="eager"
              />
              <div class="cta">
                <span>Visit website</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "compLogoWall",
  components: {
    WSection,
    WMaxWidth,
    IText,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stickyScroll() {
      return this.blok.settings.includes("scrollingText");
    },
    addPadding() {
      return true;
    },
    placeLogosOnTheLeftSide() {
      return this.blok.settings.includes("logoWallLeftSide");
    },
    placeLogosBelowText() {
      return this.blok.settings.includes("placeLogosBelowText");
    },
    label() {
      return this.blok.label;
    },
    content() {
      return this.blok.description;
    },
    buttons() {
      return this.blok.buttons;
    },
    logos() {
      return this.blok.listItems;
    },
    logoSizes() {
      return `
        (min-width: ${this.$breakpoints.widescreen}px) ${
        this.$breakpoints.widescreen * 0.13
      }rem,
        (min-width: ${this.$breakpoints.mobileLandscape}px) 20vw,
        50vw
      `;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  // We always want the text first on mobile
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";

  /*grid-template-areas:
        "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
        "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";

  &.imageOnLeftSide{
    grid-template-areas:
        "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2"
        "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1";
  }*/

  header {
    grid-area: se1;
    @extend %margin-top-bottom-x1;
    display: flex;
    align-items: center;
  }
  ul {
    grid-area: se2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @extend %grid-column-gap;
    @extend %grid-row-gap;

    li {
      position: relative;
      //border: 1px solid $color-blue;
      &:before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 81.1224489796%;
      }
      &:nth-child(odd) {
        // grid-column-start: 1;
        grid-column-end: span 2;
        //grid-area: r1;
      }
      &:nth-child(even) {
        // grid-column-start: 3;
        grid-column-end: span 2;
        //grid-area: r2;
      }
    }
  }
  &.stickyScroll {
    header {
      display: block;
      align-items: center;
      > * {
        position: sticky;
        top: $nav-height;
      }
    }
  }

  .logo {
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .cta {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-blue;
        transform: translate3d(0, 100%, 0);
        transition: transform 0.4s cubic-bezier($ease-in-out-cubic);
        span {
          @extend %lead;
          color: white;
        }
      }
      & .logo-image {
        transition: transform 0.4s cubic-bezier($ease-in-out-cubic);
      }
      @media (hover: hover) {
        &:hover {
          .logo-image {
            transform: translate3d(0, -2.5rem, 0);
          }
          .cta {
            transform: translateY(0);
          }
        }
      }
    }
  }

  @media (min-width: $break-mobile-landscape) {
    // two-columns
    grid-template-areas: "se1 se1 se1 se1 se1 se1  .  se2 se2 se2 se2 se2";

    &.placeLogosOnTheLeftSide {
      grid-template-areas: "se2 se2 se2 se2 se2  .  se1 se1 se1 se1 se1 se1";
    }
    &.placeLogosBelowText {
      grid-template-areas:
        "se1 se1 se1 se1 se1 . . . . . . . "
        "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";
      ul {
        li {
          grid-column-end: span 2;
        }
      }
    }
  }

  @media (min-width: $break-tablet-portrait) {
    &.placeLogosBelowText {
      ul {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }

  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      grid-template-areas: " .  se1 se1 se1 se1 se1  .  se2 se2 se2 se2  . ";
      &.placeLogosOnTheLeftSide {
        grid-template-areas: " .  se2 se2 se2 se2  .  se1 se1 se1 se1 se1  . ";
      }
      &.placeLogosBelowText {
        grid-template-areas:
          ". se1 se1 se1 se1 se1 . . . . . . "
          ". se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 .";
        ul {
          grid-template-columns: repeat(10, 1fr);
        }
      }
    }
  }
}
</style>
