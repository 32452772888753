<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <g :clip-path="`url(#arrowleft_${_uid})`">
      <path
        d="M0 13L20.2 13L14.6 18.6L16 20L24 12L16 4L14.6 5.4L20.2 11L0 11V13Z"
      />
    </g>
    <defs>
      <clipPath :id="`arrowleft_${_uid}`">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconArrowLeft"
};
</script>
