<template>
  <WSection
    :margin="true"
    :paddings="false"
    :class="[$options.name, { slim: isSlim }]"
  >
    <WMaxWidth>
      <div :class="['C', { isSlim: isSlim }]">
        <section v-if="!isSlim">
          <IText :label="label" :content="content"></IText>
        </section>
        <ul class="buttns">
          <li
            v-for="(item, index) in links"
            :key="`${index}`"
            v-observe-visibility="observed"
          >


      <!-- <RUrlVue :blok="item" /> -->


            <a
              class="buttn"
              :href="item.url.url ? `${item.url.cached_url}` : `/${item.url.cached_url}`"
              :title="`Go to ${item.label}`"
              @mouseenter.prevent="
                (e) => {
                  hoverDownload(index);
                }
              "
              @mouseleave="
                (e) => {
                  hoverDownload();
                }
              "
            >
              
              <span>Go to {{ item.label }}</span>
              <!-- <span class="link__arrow"></span> TESTING THE ARROW WITH NO LINE (INTERNAL)-->

              <!-- <IconExternal /> -->
            </a>
          </li>
        </ul>
        <figure
          v-if="!isSlim"
          :class="['thumbnails', { single: single, stack: stack }]"
          v-observe-visibility="observed"
        >
          <template v-for="index in 7">
            <div class="page" :key="'page-' + index"></div>
          </template>
          <div class="cover">
            <div v-for="(item, index) in links" :key="`${index}`">
              <!-- <WPictureSourceSet
                :image="item.fields.thumbnail"
                :sizes="thumbnailSizes"
              /> -->
              <!-- <ILazyImage
                :filename="item.thumbnail.filename"
                :alt="item.thumbnail.alt"
                sizes="(min-width: 600px) 30vw, 100vw"
                :ratioW="16"
                :ratioH="0"
              /> -->
0
          

              
              <a
                class="buttn"
                :href="item.url.url ? `${item.url.cached_url}` : `/${item.url.cached_url}`"
                :title="`Go to ${item.label}`"
              >
              
                
                <span>Go to {{ item.label }}</span>
                <!-- <span class="link__arrow"></span> -->
                <!-- <IconExternal /> -->
              </a>
            </div>
          </div>
        </figure>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IconExternal from "@/components/icons/IconExternal.vue";
import IText from "@/components/items/I-Text.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";


export default {
  name: "C-FloatLink",
  components: {
    WSection,
    WMaxWidth,
    IconExternal,
    IText,
    ILazyImage
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stack: false,
      single: false,
    };
  },
  computed: {
    showLabel() {
      return this.blok.settings.includes("showLabel");
    },
    isSlim() {
      return this.blok.settings.includes("slimDesign");
    },
    label() {
      return this.blok.label;
    },
    content() {
      return this.blok.description;
    },

    links() {
      return this.blok.links;
    },
    thumbnailSizes() {
      return `
          95vw
        `;
    },
  },
  methods: {
    hoverDownload(index) {
      if (index == undefined) {
        this.single = false;
        this.stack = false;
      } else if (index == 0) {
        this.stack = true;
        this.single = false;
      } else {
        this.stack = false;
        this.single = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .link__arrow {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg clip-path='url(%23arrowleft_2099)'%3E%3Cpath d='M0 13h20.2l-5.6 5.6L16 20l8-8-8-8-1.4 1.4 5.6 5.6H0v2z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='arrowleft_2099'%3E%3Cpath fill='currentColor' d='M0 0h24v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
//     color: $color-blue;
//     background-position: right 0 bottom 2rem;
//     background-size: 2.4rem;
//     background-repeat: no-repeat;
//     transition: 0.3s ease;
//     width: 50px;
// }
.C-FloatLink {
  background-color: $color-white-off;
  position: sticky;
  top: 0;
  z-index: 10;
  @media screen and (max-width:  879px) {
    top: var(--nav-height)
  }
  .buttn {
    display: flex;
    align-items: center;
    @extend %lead;
    font-size: 1.4rem;
    font-weight: 700;
    white-space: nowrap;
    padding: 2rem 0;
    svg {
      margin-left: 2rem;
      color: $color-terra-green;
    }
    span {
      transform: translateY(0.1rem);
      color: $color-terra-green;
      white-space: nowrap;
      &:after {
        content: "";
        display: block;
        top: 100%;
        width: 100%;
        height: 0.2rem;
        background-color: $color-terra-green;
        transform-origin: 0% 50%;
        transform: translateY(0.1rem) scale(0, 1);
        transition: transform 0.5s cubic-bezier($ease-out-cubic);
      }
    }
    @media (hover: hover) {
      &:hover {
        span {
          &:after {
            transform: translateY(0.1rem) scale(1, 1);
          }
        }
      }
    }
  }
  .C {
    &.isSlim {
      padding: 2.5rem 0;
      .buttns {
        // @media (min-width: $break-mobile-portrait + ($break-mobile-landscape - $break-mobile-portrait) / 2) {
          display: flex;
          flex-direction: column;
          .buttn {
            justify-content: center;
          }
        // }

        @media (min-width: $break-mobile-landscape + 100px) {
          flex-direction: row;
          justify-content: space-evenly;
          li {
            margin-top: 0 !important;
          }
        }
      }
    }
    &:not(.isSlim) {
      @extend %padding-top-bottom-x1;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      @extend %grid-column-gap;
      .buttns {
        margin-top: 2.5rem;
      }
      section {
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 1;
        .I-Text {
          color: $color-terra-green;
        }
      }
      .buttns {
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 2;
        display: none;
      }
      .thumbnails {
        margin-top: 4rem;
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 3;
        .cover {
          > div {
            .buttn {
              justify-content: center;
              padding: 2rem;
            }
            &:not(:first-child) {
              margin-top: 4rem;
            }
          }
        }
      }
      @media (min-width: $break-mobile-landscape) {
        section {
          grid-column-start: 2;
          grid-column-end: 12;
        }
        .thumbnails {
          grid-column-start: 3;
          grid-column-end: 11;
        }
      }
      @media (min-width: $break-tablet-portrait) {
        section {
          grid-column-start: 2;
          grid-column-end: span 5;
          grid-row: 2;
        }
        .buttns {
          grid-column-start: 2;
          grid-column-end: span 6;
          grid-row: 3;
          display: block;
        }
        .thumbnails {
          overflow-x: initial;
          position: relative;
          grid-column-start: 8;
          grid-column-end: span 5;
          grid-row-start: 1;
          grid-row-end: 5;
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
          align-self: center;
          > div {
            border: 0.15rem solid #eeeeee;
            transition: transform 0.5s cubic-bezier($ease-out-cubic);
          }
          .buttn {
            display: none;
          }
          .cover {
            z-index: 10;
            position: relative;
            > div {
              &:first-child {
                width: auto;
                position: relative;
                z-index: 1;
                transition: opacity 0.5s cubic-bezier($ease-out-cubic);
              }
              &:not(:first-child) {
                margin-top: 0;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
          .page {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: white;
          }
          $offset: 1.25%;
          &.stack {
            > div {
              @for $i from 1 through 8 {
                &:nth-child(#{$i}) {
                  transform: translate(
                    $i * -$offset + 4 * $offset,
                    $i * -$offset + 4 * $offset
                  );
                }
              }
            }
          }
          &.single {
            > div {
              &:nth-child(8) {
                transform: translate(-$offset * 2, -$offset * 2);
              }
            }
            .cover {
              > div {
                &:first-child {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
      @media (min-width: $break-desktop-small) {
        section {
          grid-column-end: span 4;
        }
        .buttns {
          display: flex;
          //justify-content: space-evenly;
          li {
            margin-top: 0 !important;
            &:not(:first-child) {
              margin-left: 4rem;
            }
          }
        }
      }
    }
  }
}
</style>
