<template>
  <svg
    fill="none"
    viewBox="0 0 46 46"
    width="46"
    height="46"
    :class="{ inverted: inverted }"
  >
    <path
      class="circle"
      d="M45.923 22.961c0 12.681-10.28 22.962-22.962 22.962C10.281 45.923 0 35.643 0 22.96 0 10.281 10.28 0 22.961 0s22.962 10.28 22.962 22.961Z"
    />
    <path
      class="cross"
      stroke-width="1.5"
      d="m18.53 18.47 10 10M18.184 28.47l10-10"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClose",
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  fill: #fff;
}
.cross {
  stroke: $color-blue;
}
.inverted {
  .circle {
    fill: $color-blue;
  }
  .cross {
    stroke: #fff;
  }
}
</style>