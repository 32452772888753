var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-gradient"},[_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"table"},[_c('tr',[_c('th',[_c('button',{class:[
              'toggle-investor',
              _vm.sortData.byName,
              { active: _vm.sortData.byName } ],on:{"click":function($event){return _vm.toggleSorting('byName')}}},[_vm._v("\n            Investor\n          ")])]),_vm._v(" "),_c('th',[_c('button',{class:[
              'toggle-investor',
              _vm.sortData.byType,
              { active: _vm.sortData.byType } ],on:{"click":function($event){return _vm.toggleSorting('byType')}}},[_vm._v("\n            Investment type\n          ")])]),_vm._v(" "),_c('th',[_c('button',{class:[
              'toggle-investor',
              _vm.sortData.byAvailable,
              { active: _vm.sortData.byAvailable } ],on:{"click":function($event){return _vm.toggleSorting('byAvailable')}}},[_vm._v("\n            Investment available\n          ")])]),_vm._v(" "),_c('th',[_c('button',{class:[
              'toggle-investor',
              _vm.sortData.byReturn,
              { active: _vm.sortData.byReturn } ],on:{"click":function($event){return _vm.toggleSorting('byReturn')}}},[_vm._v("\n            Target return\n          ")])])]),_vm._v(" "),_vm._l((_vm.sortedInvestors),function(project){return _c('tr',{key:project.id},[_c('td',[_c('nuxt-link',{attrs:{"to":_vm.$urlResolver(project.url)}},[_c('div',{staticClass:"investor-logo"},[_c('div',{staticClass:"investor-logo__image"},[(project.image && project.image.filename)?_c('ILazyImage',{attrs:{"filename":project.image.filename,"alt":project.image.alt,"sizes":"(min-width: 600px) 30vw, 100vw","ratioW":16,"ratioH":16}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"investor-logo__title"},[_vm._v("\n                "+_vm._s(project.title)+"\n              ")])])])],1),_vm._v(" "),_c('td',{staticClass:"investment-type"},[_vm._v("\n          "+_vm._s(_vm.formatArray(
              project.availableFinancing,
              "investors-available-financing"
            ))+"\n        ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(project.investmentAvailable)))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(project.targetReturn)))])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }