var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WSection',{attrs:{"margin":true}},[_c('WMaxWidth',{ref:"parent"},[_c('div',{staticClass:"chapters-list"},[_c('div',{staticClass:"chapters-list__item"},[(!_vm.search)?_c('div',{ref:"nav",staticClass:"chapters-list__sticky"},[_c('div',{ref:"navProgress",staticClass:"chapters-progress",style:('--progress-bar: calc(' +
              _vm.scrollProgress +
              '* var(--progress-height))')},[_c('ul',{staticClass:"chapters-nav"},_vm._l((_vm.progressNav),function(navItem,i){return _c('li',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.chapters[i]),expression:"chapters[i]"}],key:navItem.id,class:[
                  'chapters-nav__item',
                  { 'is-active': navItem.isActive } ],style:({ height: navItem.size })},[_c('button',{staticClass:"chapters-nav__action",on:{"click":function($event){return _vm.scrollToSection(i)}}},[_vm._v("\n                  "+_vm._s(navItem.title)+"\n                  "),_c('small',{staticClass:"chapters-nav__count"},[_vm._v(_vm._s(navItem.len))])])])}),0)])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"chapters-list__item"},[_c('div',{staticClass:"chapters-list__search-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"chapters-list__search",attrs:{"placeholder":_vm.searchText,"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_vm._v(" "),(!_vm.countChapters)?_c('div',{staticClass:"chapters-list__search__error"},[_vm._v("\n          "+_vm._s(_vm.searchTextError)+"\n          "),_c('strong',[_vm._v(_vm._s(_vm.search))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.filtered),function(chapter){return _c('div',{key:chapter.id,ref:"chapters",refInFor:true},[(chapter.output.length > 0)?_c('ul',{class:[
              'chapter-list',
              'chapter-list--grid',
              { 'is-active': _vm.navActive === chapter._uid || _vm.search } ]},_vm._l((chapter.output),function(item){return _c('li',{key:item.id,staticClass:"chapter-list__item"},[_c('RTestimonial',{attrs:{"id":item.id,"label":item.label,"name":item.name,"title":item.title,"image":item.videoPosterImage,"videoUrl":item.videoUrl}})],1)}),0):_vm._e()])})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }