<template>
  <WSection :margin="true">
    <WMaxWidth ref="parent">
      <div class="chapters-list">
        <div class="chapters-list__item">
          <div class="chapters-list__sticky" ref="nav" v-if="!search">
            <div
              class="chapters-progress"
              ref="navProgress"
              :style="
                '--progress-bar: calc(' +
                scrollProgress +
                '* var(--progress-height))'
              "
            >
              <ul class="chapters-nav">
                <li
                  v-for="(navItem, i) in progressNav"
                  :key="navItem.id"
                  :class="[
                    'chapters-nav__item',
                    { 'is-active': navItem.isActive },
                  ]"
                  :style="{ height: navItem.size }"
                  v-editable="chapters[i]"
                >
                  <button
                    class="chapters-nav__action"
                    @click="scrollToSection(i)"
                  >
                    {{ navItem.title }}
                    <small class="chapters-nav__count">{{ navItem.len }}</small>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="chapters-list__item">
          <div class="chapters-list__search-wrap">
            <input
              :placeholder="searchText"
              class="chapters-list__search"
              type="text"
              v-model="search"
            />
          </div>
          <div v-if="!countChapters" class="chapters-list__search__error">
            {{ searchTextError }}
            <strong>{{ search }}</strong>
          </div>
          <div v-for="chapter in filtered" :key="chapter.id" ref="chapters">
            <ul
              :class="[
                'chapter-list',
                'chapter-list--grid',
                { 'is-active': navActive === chapter._uid || search },
              ]"
              v-if="chapter.output.length > 0"
            >
              <li
                class="chapter-list__item"
                v-for="item in chapter.output"
                :key="item.id"
              >
                <RTestimonial
                  :id="item.id"
                  :label="item.label"
                  :name="item.name"
                  :title="item.title"
                  :image="item.videoPosterImage"
                  :videoUrl="item.videoUrl"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </WMaxWidth>
  </WSection>
</template>
<script>
var VueScrollTo = require("vue-scrollto");
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WSection from "@/components/wrappers/W-Section.vue";
import RTestimonial from "@/components/references/R-Testimonial.vue";
export default {
  name: "ListChaptersSearch",
  components: {
    WMaxWidth,
    WSection,
    RTestimonial,
  },
  props: {
    searchText: {
      type: String,
      default: "",
    },
    searchTextError: {
      type: String,
      default: "",
    },
    chapters: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      search: "",
      scrollTop: 0,
      scrollHeight: 0,
      scrollNav: 165,
      scheduledAnimationFrame: false,
      intersectingScroll: false,
      chaptersHeight: [],
      options: {
        easing: [0.45, 0.44, 0.0, 1.0],
        lazy: false,
        offset: 5,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      },
    };
  },
  computed: {
    filtered() {
      return this.chapters.map((item) => {
        return {
          id: item.id,
          _uid: item._uid,
          category: item.category,
          output: item.output.filter((el) =>
            el.search.toLowerCase().includes(this.search.toLowerCase())
          ),
        };
      });
    },
    navActive() {
      const active = this.progressNav.filter((item) => item.isActive)[0];
      return active ? active.id : "";
    },
    progressNav() {
      const nav = this.filtered;
      const navFirst = 0;
      const navLast = Number(nav.length) - 1;

      let count = 0;
      return nav.map((item, i) => {
        let listLength = Number(item.output.length);

        let listHeight = 0;
        if (this.chaptersHeight.length > 0) {
          listHeight = this.chaptersHeight[i].height;
          count += this.chaptersHeight[i].height;
        }

        const progress = this.scrollProgress;

        let isActive = false;

        if (i == navFirst) {
          isActive = count > progress;
        } else if (i === navLast) {
          isActive = count < progress + listHeight;
        } else {
          isActive = count < progress + listHeight && count > progress;
        }
        return {
          id: item._uid,
          title: item.category,
          len: listLength,
          size: listHeight * 100 + "%",
          pos: count,
          isActive: isActive,
        };
      });
    },
    scrollProgress() {
      return this.scrollValue / this.scrollHeight;
    },
    scrollValue() {
      let scrollValue = (this.scrollTop - this.scrollNav / 2) * -1;
      scrollValue = Math.max(0, scrollValue);
      scrollValue = Math.min(scrollValue, this.scrollHeight);
      return scrollValue;
    },
    countChapters() {
      let count = 0;
      this.filtered.map((chapter) => {
        count += Number(chapter.output.length);
      });
      return count;
    },
  },
  methods: {
    scrollToSection(index) {
      this.options.offset = -(this.scrollNav / 2) + 40;
      if (index < 1) {
        this.options.offset = -65;
      }
      VueScrollTo.scrollTo(this.$refs.chapters[index], 1000, this.options);
    },
    getScrollPosition() {
      const nav = this.$refs.nav.getBoundingClientRect();
      this.scrollNav = nav.height;

      const parentBoundingRect = this.$refs.parent.$el.getBoundingClientRect();
      this.scrollTop = parentBoundingRect.top;
      this.scrollHeight = parentBoundingRect.height;

      this.scheduledAnimationFrame = false;
    },
    updateScrollPosition(e) {
      if (this.intersectingScroll) {
        if (this.scheduledAnimationFrame) {
          return;
        }
        this.scheduledAnimationFrame = true;

        requestAnimationFrame(this.getScrollPosition);
      }
    },
    visibilityChanged(isVisible, entry) {
      this.intersectingScroll = isVisible;
    },
    onScroll(e) {
      if (!this.countChapters) return;
      this.getScrollPosition();
      this.updateScrollPosition(e);
    },
    onResize(e) {
      this.getScrollPosition();
      this.calcChapters();
    },
    calcChapters() {
      if (!this.$refs.chapters) return;

      const chapters = this.$refs.chapters;
      let count = 0;
      const listHeights = this.chapters.map((chapter, i) => {
        const listheight = chapters[i].getBoundingClientRect().height;
        count += listheight;
        return {
          height: listheight / this.scrollHeight,
          count: count,
        };
      });
      this.chaptersHeight = listHeights;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
    setTimeout(() => {
      this.onScroll();
      this.getScrollPosition();
      this.calcChapters();
    }, 10);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.chapters-list {
  --progress-height: 65vh;
  min-height: 70vh;
  display: grid;

  @extend %grid-column-gap;

  @media (min-width: $break-tablet-portrait) {
    grid-template-columns: 3fr 9fr;
  }
  @media (min-width: $break-tablet-landscape) {
    grid-template-columns: 4fr 8fr;
  }
  @media (min-width: $break-desktop-small) {
    grid-template-columns: 3fr 9fr;
  }

  &__title {
    $i: 0.5;
    @include poly-fluid-sizing(
      (padding-bottom),
      (
        $break-mobile-portrait: $padding-mobile * $i,
        $break-desktop-large: $padding-desktop * $i,
        $break-widescreen: $padding-widescreen * $i,
      )
    );
  }
  &__count {
    @extend %headline5;
    display: inline-block;
  }
  &__item {
    &:first-child {
      @media (min-width: $break-desktop-small) {
        grid-column-start: 1;
      }
    }
    &:last-child {
      @media (min-width: $break-tablet-portrait) {
        //padding-bottom: 35vh;
      }
    }
  }
  &__sticky {
    position: sticky;
    top: $nav-height;
  }
  &__search-wrap {
    margin-top: 15px;
    margin-bottom: 15px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 13 14'%3E%3Cpath fill='%23031F35' fill-rule='evenodd' d='M8.998 10.553c-.921.62-2.03.983-3.225.983A5.77 5.77 0 0 1 0 5.768 5.77 5.77 0 0 1 5.773 0a5.77 5.77 0 0 1 5.772 5.768c0 1.71-.744 3.245-1.926 4.301L13 13.448l-.553.552-3.45-3.447Zm1.766-4.785a4.989 4.989 0 0 1-4.991 4.987A4.989 4.989 0 0 1 .782 5.768 4.989 4.989 0 0 1 5.772.781a4.989 4.989 0 0 1 4.992 4.987Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    @media (min-width: $break-tablet-portrait) {
      margin-top: -15px;
      margin-bottom: 15px;
    }
    & input[type="text"] {
      border: 1px solid rgba($color-blue, 0.2);
      width: 100%;
      padding-bottom: 0px;
      padding-left: 15px;
      font-size: 16px;
      &::placeholder {
        color: $color-blue;
        opacity: 1;
        font-size: 14px;
      }
    }
  }
  &__search__error {
    @extend %body1;
  }
}
.chapters-progress {
  padding-left: 10px;
  border-left: 0.7rem solid $color-blue-lighter;
  position: relative;
  display: none;
  @media (min-width: $break-tablet-portrait) {
    display: block;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -0.7rem;
    background-color: $color-blue;
    height: var(--progress-bar);
    max-height: var(--progress-height);
    width: 0.7rem;
  }
}
.chapters-nav {
  height: 65vh;
  display: flex;
  flex-direction: column;
  &__item {
    opacity: 0.5;
    min-height: 24px;
    padding-top: 7px;
    &:first-child {
      min-height: none;
      padding-top: 0;
    }
    &.is-active {
      opacity: 1;
      & .icon {
        opacity: 1;
      }
    }
  }
  &__action {
    @extend %headline5;
    cursor: pointer;
    padding-left: 0px;
    text-align: left;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: -36px top;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'%3E%3Cpath d='M0 13h20.2l-5.6 5.6L16 20l8-8-8-8-1.4 1.4 5.6 5.6H0v2z'/%3E%3C/svg%3E");
    transition: all 0.25s cubic-bezier($ease-out-cubic);
    .is-active & {
      padding-left: 36px;
      background-position: 0 top;
    }
  }
  &__count {
    margin-left: 0.75rem;
    opacity: 0.5;
    font-size: 1.2rem;
  }
}
.chapter-list {
  @extend %padding-bottom-x2;

  @media (min-width: $break-tablet-portrait) {
    opacity: 0.5;
    .popup-active & {
      opacity: 1;
    }
  }
  &.is-active {
    opacity: 1;
  }
  &__item {
    padding: 3em 0;
  }
  &--grid {
    display: grid;
    @extend %grid-column-gap;
    // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    @media (min-width: $break-mobile-landscape) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $break-tablet-landscape) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $break-desktop-small) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
