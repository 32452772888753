<template>
  <WSection :margin="true" :fillBlue="true" :class="$options.name">
    <WMaxWidth>
      <div
        :class="[
          'C',
          { addPadding: addPadding, imageOnLeftSide: imageOnLeftSide },
        ]"
      >
        <section>
          <div class="text">
            <IText
              :icon="blok.icon"
              :showLabel="false"
              :content="blok.content"
              :inverted="false"
              :fontB1="false"
            />
          </div>
          <ul>
            <li
              v-for="(link, index) in blok.listItems"
              :key="`link-${index}`"
              v-observe-visibility="observed"
            >
              <a :href="link.url" target="_blank" rel="noopener">
                <figure>
                  <ILazyImage
                    :filename="link.thumbnail.filename"
                    :alt="link.thumbnail.alt"
                    sizes="(min-width: 600px) 30vw, 100vw"
                    :ratioW="16"
                    :ratioH="9"
                  />
                  <figcaption>
                    <h3><IconPlay class="icon" />{{ link.title }}</h3>
                    <p>{{ link.description }}</p>
                  </figcaption>
                </figure>
              </a>
            </li>
          </ul>
        </section>
        <section v-observe-visibility="observed" class="vimeo-wrap">
          <WVimeoLoop
            v-if="blok.videoLoopUrl && blok.videoLoopUrl.vimeo_data"
            :vimeoData="blok.videoLoopUrl.vimeo_data"
            :poster="blok.image"
          />
          <ILazyImage
            v-if="!blok.videoLoopUrl.vimeo_data && blok.image.filename"
            :filename="blok.image.filename"
            :alt="blok.image.alt"
            class="retv-image"
            sizes="(min-width: 600px) 30vw, 100vw"
            :ratioW="16"
            :ratioH="0"
            classes="h100"
          />
          <a
            :href="blok.buttonLink"
            target="_blank"
            rel="noopener"
            class="inverted"
            >{{ blok.buttonText }}</a
          >
        </section>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVimeoLoop from "@/components/wrappers/W-VimeoLoop.vue";
import IText from "@/components/items/I-Text.vue";
import IconPlay from "@/components/icons/IconPlay.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "compReTv",
  components: {
    WSection,
    WMaxWidth,
    WVimeoLoop,
    IText,
    IconPlay,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    addPadding() {
      return this.blok.settings.includes("addPadding");
    },
    imageOnLeftSide() {
      return this.blok.settings.includes("imageLeft");
    },
    thumbnailSizes() {
      return `
          (min-width: ${this.$breakpoints.widescreen}px) ${
        this.$breakpoints.widescreen * 0.025
      }rem,
          (min-width: ${this.$breakpoints.tabletPortrait}px) 25vw,
          (min-width: ${this.$breakpoints.mobileLandscape}px) 50vw,
          100vw
        `;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.vimeo-wrap {
  height: 90vw;
  @media (min-width: $break-tablet-portrait) {
    height: auto;
  }
}
.retv-image {
  & /deep/ img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.C {
  //@extend %padding-top-bottom-x1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  .W-VideoSourceSet {
    /deep/ .W-PictureSourceSet {
      transform: scale(0.98);
    }
  }

  // We always want the text first on mobile
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";

  @media (min-width: $break-tablet-portrait) {
    grid-template-areas: "se1 se1 se1 se1 se1 se1  .  se2 se2 se2 se2 se2";

    &.imageOnLeftSide {
      grid-template-areas: "se2 se2 se2 se2 se2  .  se1 se1 se1 se1 se1 se1";
    }
  }
  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      grid-template-areas: " .  se1 se1 se1 se1 se1  .  se2 se2 se2 se2  . ";
      &.imageOnLeftSide {
        grid-template-areas: " .  se2 se2 se2 se2  .  se1 se1 se1 se1 se1 . ";
      }
    }
  }
  section {
    &:first-child {
      grid-area: se1;
      @extend %margin-top-bottom-x1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &:last-child {
      position: relative;
      grid-area: se2;
      a {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 6;
        transform: translate(-50%, -100%);
        @extend %button;
      }
    }
  }
  .text {
    //display: grid;
    //grid-template-columns: repeat(6, 1fr);
    //@extend %grid-column-gap;
    margin-bottom: 4em;
    > * {
      //grid-column-start: 1;
      //grid-column-end: span 4;
    }
    /deep/ {
      p {
        color: white;
      }
    }
  }
  figcaption {
    color: white;
    //@extend %headline5;
    //display: flex;
    //align-items: flex-start;
    margin-top: 1em;
    h3 {
      @extend %headline5;
      display: flex;
      //hyphens: auto;
    }
    p {
      @extend %caption;
      //hyphens: auto;
    }
    .icon {
      margin-right: 0.625em;
      margin-top: -0.05em;
      flex-basis: 1.25em;
      flex-shrink: 0;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @extend %grid-column-gap;
    figure > div {
      overflow: hidden;
    }
    .W-PictureSourceSet {
      position: absolute;
      top: 0;
    }
    /deep/ img {
      transition: transform 0.3s cubic-bezier($ease-out-cubic);
      transform: scale(1.2);
    }
    @media (hover: hover) {
      /deep/ a:hover {
        img {
          transform: scale(1);
        }
      }
    }
    li {
      grid-column-start: 1;
      grid-column-end: span 6;
      margin-bottom: 3rem;
      &:nth-of-type(1) {
        grid-row: 1;
      }
      &:nth-of-type(2) {
        grid-row: 2;
      }
      &:nth-of-type(3) {
        grid-row: 3;
      }
      &:nth-of-type(4) {
        grid-row: 4;
      }
      @media (min-width: $break-mobile-landscape) {
        &:nth-of-type(1) {
          grid-column-start: 1;
          grid-column-end: span 3;
          grid-row: 1;
          padding-right: 1.5em;
        }
        &:nth-of-type(2) {
          grid-column-start: 4;
          grid-column-end: span 3;
          grid-row: 1;
          padding-left: 1.5em;
        }
        &:nth-of-type(3) {
          grid-column-start: 1;
          grid-column-end: span 3;
          grid-row: 2;
          padding-right: 1.5em;
          margin-bottom: 0;
        }
        &:nth-of-type(4) {
          grid-column-start: 4;
          grid-column-end: span 3;
          grid-row: 2;
          padding-left: 1.5em;
          margin-bottom: 0;
        }
      }
      @media (min-width: $break-tablet-portrait) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          padding-left: 0em;
          padding-right: 3em;
        }
      }
    }
  }
}
</style>
