<template>
  <WSection class="team">
    <div class="team-description">
      <h2 class="team-description__title h3" v-observe-visibility="observed">
        The team
      </h2>
      <IText
        :content="blok.description"
        :inverted="false"
        :fontB1="true"
      ></IText>
    </div>

    <div
      class="team-partners"
      v-if="blok.partnersAndDevelopers && blok.partnersAndDevelopers.length"
    >
      <h3 class="team-partners__title h4" v-observe-visibility="observed">
        Partners and developers
      </h3>
      <CompLogosGrid :logos="blok.partnersAndDevelopers" />
    </div>

    <div
      class="team-contacts"
      v-if="blok.keyContacts && blok.keyContacts.length"
    >
      <h3 class="team-contacts__title h4" v-observe-visibility="observed">
        Key contacts
      </h3>
      <ul>
        <RTeamContact
          v-for="contact in blok.keyContacts"
          :key="contact._uid"
          :blok="contact"
        />
      </ul>
    </div>
  </WSection>
</template>

<script>
import CompLogosGrid from "@/components/terra-x/projectsAndInvestors/CompLogosGrid";
import WSection from "@/components/wrappers/W-Section.vue";
import IText from "@/components/items/I-Text.vue";
import RTeamContact from "@/components/references/R-TeamContact.vue";

export default {
  name: "CompProjectTeam",
  components: {
    CompLogosGrid,
    WSection,
    IText,
    RTeamContact,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.team-description {
  &__title {
    margin-bottom: 2rem;
  }
}
.team-partners {
  &__title {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
}
.team-contacts {
  &__title {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
}
</style>