<template>
  <WSection
    :margins="false"
    :class="[
      'compBanner',
      {
        fullHeight: settingsFullHeight,
        halfHeight: settingsHalfHeight,
        locked: isLocked,
        isFirst,
      },
    ]"
    :style="'--full-height: ' + innerHeight"
  >
    <WMaxWidth>
      <div
        :class="[
          'C',
          {
            colored: settingsBlueBackground,
            centered: settingsCentered,
            hasBackground,
          },
        ]"
      >
        <div class="graphic">
          <img
            :alt="blok.graphic.alt"
            :src="blok.graphic.filename"
            v-if="blok.graphic && blok.graphic.filename"
          />
        </div>
        <IText
          :label="blok.label"
          :content="blok.content"
          :buttons="blok.buttons"
          :inverted="invertedUiColors"
          :fontB1="true"
        />
        <div class="scroll-indicator-wrapper">
          <IScrollDownIndicator
            v-if="this.$route.path == '/' && settingsFullHeight"
          />
        </div>
      </div>
    </WMaxWidth>
    <WBackground
      :type="'fill'"
      :colored="settingsBlueBackground"
      :hasBackground="hasBackground"
    >
      <WVimeoLoopBanner
        v-if="
          blok.backgroundVideoLoopUrl && blok.backgroundVideoLoopUrl.vimeo_data
        "
        :vimeoData="blok.backgroundVideoLoopUrl.vimeo_data"
        :poster="blok.backgroundImage"
      />
      <ILazyImage
        v-if="
          !blok.backgroundVideoLoopUrl.vimeo_data &&
          blok.backgroundImage &&
          blok.backgroundImage.filename
        "
        :filename="blok.backgroundImage.filename"
        class="banner-image"
        :alt="blok.backgroundImage.alt"
        sizes="(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)"
        :ratioW="16"
        :ratioH="9"
        classes="h100"
      />
    </WBackground>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WBackground from "@/components/wrappers/W-Background.vue";
import WVimeoLoopBanner from "@/components/wrappers/W-VimeoLoopBanner.vue";
import IText from "@/components/items/I-Text.vue";
import IScrollDownIndicator from "@/components/items/I-ScrollDownIndicator.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "compBanner",
  components: {
    WSection,
    WMaxWidth,
    WBackground,
    WVimeoLoopBanner,
    IText,
    IScrollDownIndicator,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLocked: true,
      innerHeight: "100vh",
    };
  },
  computed: {
    settingsBlueBackground() {
      return this.blok.settings.includes("blueBackground");
    },
    settingsCentered() {
      return this.blok.settings.includes("centered");
    },
    settingsHalfHeight() {
      return this.blok.settings.includes("halfHeight");
    },
    settingsFullHeight() {
      return this.blok.settings.includes("fullHeight");
    },
    backgroundVideoLoopUrl() {
      return this.blok.backgroundVideoLoopUrl;
    },
    backgroundImage() {
      return this.blok.backgroundImage;
    },
    hasBackground() {
      if (
        this.blok.backgroundVideoLoopUrl.vimeo_raw ||
        this.blok.backgroundImage
      ) {
        return true;
      }
      return false;
    },
    invertedUiColors() {
      return this.hasBackground || this.settingsBlueBackground ? true : false;
    },
  },
  mounted() {
    this.init();
    if (this.$deviceIsMobile) {
      this.innerHeight = window.innerHeight + "px";
    }
  },
  activated() {
    // Only used if keep-alive is used
    this.init();
  },
  deactivated() {
    // Only used if keep-alive is used
    this.lock();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrolled);
    this.lock();
  },
  methods: {
    init() {
      if (this.$route.path == "/") {
        window.addEventListener("scroll", this.onScrolled);
        setTimeout(() => {
          this.isLocked = false;
        }, 4000);
      } else {
        setTimeout(() => {
          this.isLocked = false;
        }, 1200);
      }
    },
    onScrolled() {
      this.unlock();
      window.removeEventListener("scroll", this.onScrolled);
    },
    unlock() {
      this.isLocked = false;
    },
    lock() {
      this.isLocked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-image {
  & /deep/ img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.compBanner {
  display: flex;
  flex-direction: column;
  /deep/ .W-MaxWidth {
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      > * {
        height: 100%;
        align-items: flex-end;
      }
    }
  }
}
.C {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  > * {
    align-items: flex-end;
    grid-column-start: 1;
    grid-column-end: span 12;
    @media (min-width: $break-mobile-landscape) {
      grid-column-end: span 8;
    }
  }
  &.centered {
    align-items: center;
    > * {
      grid-column-start: 1;
      grid-column-end: span 12;
      @media (min-width: $break-mobile-landscape) {
        grid-column-start: 2;
        grid-column-end: span 10;
      }
      @media (min-width: $break-tablet-portrait) {
        grid-column-start: 2;
        grid-column-end: span 10;
      }
      @media (min-width: $break-tablet-landscape) {
        grid-column-start: 2;
        grid-column-end: span 10;
      }
      @media (min-width: $break-desktop-small) {
        grid-column-start: 3;
        grid-column-end: span 8;
      }
    }
    /deep/ {
      text-align: center;
      footer {
        justify-content: center;
      }
    }
  }
}
.compBanner.isFirst {
  &.fullHeight {
    --full-height: 100vh;
    margin-bottom: $nav-height;
    transition: min-height 0.85s cubic-bezier($ease-in-out-cubic);
    min-height: calc(var(--full-height) - #{$nav-height * 2});
    .scroll-indicator-wrapper {
      position: absolute;
      right: 0;
      top: calc(100% + #{$nav-height / 2});
      transform: translateY(-50%);
      @extend %padding-right-x1;
      @extend %margin-top-x1;
    }
    &.locked {
      min-height: calc(var(--full-height) - #{$nav-height * 1});
    }
  }
  &.halfHeight {
    transition: min-height 0.85s cubic-bezier($ease-in-out-cubic);
    min-height: 50vh; //calc(100vh - #{$nav-height * 2});
    &.locked {
      min-height: calc(100vh - #{$nav-height});
    }
  }
}
.graphic {
  & img {
    max-width: 100%;
  }
}
.centered /deep/ .text__button-list {
  justify-content: center;
}
</style>
