<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7766 6.33984H10.25H7.75H7V7.08984V9.58984V10.3398H7.75H9.03072C9.0545 10.0857 9.09187 9.83541 9.14209 9.58984H7.75V7.08984H10.1931C10.3707 6.82656 10.5658 6.57601 10.7766 6.33984ZM9.00069 11.0996H7.75H7V11.8496V14.3496V15.0996H7.75H10.25H10.3255C10.1524 14.8605 9.99415 14.6101 9.85194 14.3496H7.75V11.8496H9.05104C9.02124 11.6033 9.00423 11.3531 9.00069 11.0996ZM5.25 7.08984H2.75V9.58984H5.25V7.08984ZM2.75 6.33984H2V7.08984V9.58984V10.3398H2.75H5.25H6V9.58984V7.08984V6.33984H5.25H2.75ZM2.75 11.8496H5.25V14.3496H2.75V11.8496ZM2 11.0996H2.75H5.25H6V11.8496V14.3496V15.0996H5.25H2.75H2V14.3496V11.8496V11.0996Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9976 15.553C18.0768 16.1739 16.9671 16.5364 15.7726 16.5364C12.5845 16.5364 10 13.9539 10 10.7682C10 7.58252 12.5845 5 15.7726 5C18.9607 5 21.5452 7.58252 21.5452 10.7682C21.5452 12.4775 20.8012 14.0131 19.6191 15.0694L23 18.4477L22.4472 19L18.9976 15.553ZM20.7635 10.7682C20.7635 13.5225 18.529 15.7553 15.7726 15.7553C13.0162 15.7553 10.7817 13.5225 10.7817 10.7682C10.7817 8.01392 13.0162 5.78112 15.7726 5.78112C18.529 5.78112 20.7635 8.01392 20.7635 10.7682Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSearchProjects",
};
</script>

<style lang="scss" scoped></style>
