<template>
  <div class="scroll-indicator">
    <div class="scroll-indicator__content">
      <div class="scroll-indicator__content-text">
        <IScrollDownIndicator />
      </div>
      <svg fill="none" viewBox="0 0 89 89">
        <circle
          class="fill-stroke-circle-behind"
          transform="rotate(-90,44.5,44.5)"
          :stroke-width="3"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
        <circle
          class="fill-stroke-circle"
          transform="rotate(-90,44.5,44.5)"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset: strokeDashoffset }"
          :stroke-width="stroke"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import IScrollDownIndicator from "@/components/items/I-ScrollDownIndicator.vue";

export default {
  name: "SealScrollCircle",
  components: {
    IScrollDownIndicator,
  },
  data() {
    return {
      windowHeight: 0,
      docHeight: undefined,
      scrollY: undefined,
      scheduledAnimationFrame: false,
      radius: 44.5,
      stroke: 5,
    };
  },
  computed: {
    normalizedRadius() {
      return this.radius - this.stroke * 2;
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeDashoffset() {
      let progress = this.scrollY / (this.docHeight - this.windowHeight);
      if (progress > 1) {
        progress = 1;
      } else if (progress < 0) {
        progress = 0;
      }
      return this.circumference - progress * this.circumference;
    },
  },
  methods: {
    handleResize() {
      setTimeout(() => {
        this.setDocHeight();
      }, 100);
    },
    handleScroll() {
      if (this.scheduledAnimationFrame) {
        return;
      }
      this.scheduledAnimationFrame = true;
      requestAnimationFrame(this.handleScrollAnimationFrame);
    },
    handleScrollAnimationFrame() {
      if (window.scrollY < 0) {
        this.scrollY = 0;
      } else {
        this.scrollY = window.scrollY;
      }
      this.scheduledAnimationFrame = false;
    },
    setDocHeight() {
      const scrollParent = this.$parent.$el.getBoundingClientRect();
      this.windowHeight = window.innerHeight - 200;
      this.docHeight = Number(scrollParent.height);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleScroll();
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.scroll-indicator {
  pointer-events: none;
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 1880px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  @extend %padding-left-right-x1;
  &__content {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 30px;
    // width: 38px;
    // height: 38px;
    display: flex;
    align-items: center;
    @media (min-width: $break-tablet-landscape) {
      margin-bottom: 50px;
    }
    &-text {
      padding-right: 2rem;
    }
    svg {
      width: 38px;
    }
  }
}
.fill-stroke-circle {
  stroke: $color-blue;
  @media (min-width: $break-mobile-landscape) {
    stroke: $color-blue;
  }
  &-behind {
    opacity: 0.5;
    stroke: #dadada;
  }
}
</style>
