<template>
  <div
    ref="scrollContainer"
    class="seal-tm__wrap"
    :style="{
      '--scroll-height': scrollHeight + 'px',
      '--scroll-horizontal': scrollTop + 'px',
    }"
  >
    <div :class="'seal-tm__inner ' + scrollClass">
      <WSection :margin="true">
        <WMaxWidth>
          <div class="seal-tm">
            <div class="seal-tm__content">
              <h3>{{ blok.title }}</h3>
            </div>
            <div class="seal-tm__videos" ref="scrollElements">
              <div class="seal-tm__videos__inside">
                <div
                  v-for="item in blok.ceoTestimonials"
                  :key="item.id"
                  class="seal-tm__videos__item"
                  ref="scrollItem"
                >
                  <RTestimonial
                    :label="item.content.label"
                    :name="item.content.name"
                    :title="item.content.title"
                    :image="item.content.videoPosterImage"
                    :videoUrl="item.content.videoUrl"
                    :iconPosRight="true"
                  />
                </div>
                <div class="seal-tm__videos__item">
                  <div class="see-all" v-observe-visibility="observed">
                    <div class="see-all__box">
                      <p>{{ blok.seeAllText }}</p>
                      <w-resolve-link
                        :slug="blok.seeAllButtonLink"
                        class="see-all__link"
                      >
                        <span>{{ blok.seeAllButtonText }}</span>
                      </w-resolve-link>
                    </div>
                    <div class="see-all__image">
                      <ILazyImage
                        v-if="blok.seeAllImage.filename"
                        :filename="blok.seeAllImage.filename"
                        :alt="blok.seeAllImage.alt"
                        sizes="(min-width: 600px) 30vw, 100vw"
                        :ratioW="16"
                        :ratioH="9"
                        classes="h100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WMaxWidth>
      </WSection>
    </div>
  </div>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WResolveLink from "@/components/wrappers/W-ResolveLink.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";
import RTestimonial from "@/components/references/R-Testimonial.vue";

export default {
  name: "CompSealTestimonials",
  components: {
    WSection,
    WMaxWidth,
    WResolveLink,
    ILazyImage,
    RTestimonial,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scheduledAnimationFrame: false,
      scrollClass: "",
      scrollTop: 0,
      scrollHeight: 0,
      loaded: false,
    };
  },
  methods: {
    setScrollPosition() {
      const scrollContainer =
        this.$refs.scrollContainer.getBoundingClientRect();

      if (scrollContainer.top > 0) {
        this.scrollTop = 0;
        this.scrollClass = "seal-tm__inner--before";
      } else if (scrollContainer.top < -this.scrollHeight) {
        this.scrollTop = -this.scrollHeight;
        this.scrollClass = "seal-tm__inner--after";
      } else {
        this.scrollTop = scrollContainer.top;
        this.scrollClass = "seal-tm__inner--active";
      }

      this.scheduledAnimationFrame = false;
    },
    handleScroll() {
      if (this.scheduledAnimationFrame) {
        return;
      }
      this.scheduledAnimationFrame = true;
      this.handleResize();
      requestAnimationFrame(this.setScrollPosition);
    },
    handleResize() {
      const scrollElements = this.$refs.scrollElements.getBoundingClientRect();
      const scrollItem = this.$refs["scrollItem"][0].getBoundingClientRect();
      const cW = scrollElements.width;
      this.scrollHeight =
        scrollItem.width * (this.blok.ceoTestimonials.length + 1) - cW;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.setScrollPosition();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.seal-tm {
  & h3 {
    margin-bottom: 3rem;
  }
  &__wrap {
    --scroll-height: 0;
    --scroll-horizontal: 0;
    overflow: hidden;
    width: 100%;
    @media (min-width: $break-tablet-landscape) {
      height: calc(100vh + var(--scroll-height));
      position: relative;
      content: "";
    }
  }
  &__inner {
    max-width: 640px;
    margin: 0 auto;
    @media (min-width: $break-tablet-landscape) {
      max-width: none;
      margin: 0;
      top: 0;
      height: 100vh;
      display: flex;
      align-items: center;
      position: absolute;
      &--active {
        top: 0;
        bottom: auto;
        position: fixed;
      }
      &--after {
        top: auto;
        bottom: 0;
      }
    }
  }
  &__videos {
    &__inside {
      position: relative;
      @media (min-width: $break-tablet-landscape) {
        transform: translateX(var(--scroll-horizontal));
        display: grid;
        grid-template-columns: repeat(20, 60%);
        .popup-active & {
          left: var(--scroll-horizontal);
          transform: none;
        }
      }
    }
    &__item {
      padding-bottom: 30px;
      @media (min-width: $break-tablet-landscape) {
        padding-bottom: 0;
        padding-right: clamp(40px, 5vw, 128px);
      }
    }
  }
}
.see-all {
  position: relative;
  &__box {
    position: absolute;
    z-index: 2;

    border: 1px solid $color-blue;
    background-color: #fff;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: $break-mobile-landscape) {
      height: 78px;
      flex-wrap: nowrap;
    }

    & p {
      padding: 20px;
      min-width: 280px;
      @media (min-width: $break-mobile-landscape) {
        white-space: nowrap;
      }
    }
  }
  &__image {
    position: relative;
    z-index: 1;
  }
  &__link {
    @extend %button;
    background-color: $color-blue;
    color: white;
    margin: 0 20px 20px;
    @media (min-width: $break-mobile-landscape) {
      margin: 0 15px 0 0;
    }
  }
}
</style>
