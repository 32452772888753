<template>
  <div
    :class="[
      'sticky-graphic',
      `sticky-graphic-${compType}`,
      { active: active }
    ]"
  >
    <WVimeoSource
      ref="vimeoSource"
      title=""
      :vimeoId="vimeoUrl.vimeo_data.id"
      :autoplay="1"
      :controls="0"
      :loop="true"
      :popup="false"
      :muted="1"
      :class="['sticky-graphic__video', `sticky-graphic-${compType}__video`]"
    />
  </div>
</template>

<script>
import WVimeoSource from "@/components/wrappers/W-VimeoSource.vue";

export default {
  name: "StickyGraphic",
  components: {
    WVimeoSource
  },
  data() {
    return {
      active: false
    };
  },
  props: {
    compType: {
      type: String,
      default: ""
    },
    vimeoUrl: {
      type: Object,
      default: ""
    }
  },
  mounted() {
    setTimeout(() => {
      this.active = true;
    }, 300);
  }
};
</script>

<style lang="scss" scoped>
.sticky-graphic {
  background-color: #fff;
  position: relative;
  z-index: 20;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  &__video {
    position: absolute;
    z-index: 1;
    // max-height: 75vh;
    background-blend-mode: multiply;
    filter: brightness(1.02);

    object-fit: cover;
  }

  &-intro {
    @media (min-width: $break-tablet-landscape) {
      margin-top: -($nav-height / 2);
    }

    &:after {
      content: "";
      background-color: #fff;
      width: 100%;
      height: 100%;
      top: 100%;
      left: 0;
      position: absolute;
      opacity: 1;
      transition: 0.6s 1.5s ease;
      z-index: 0;
      pointer-events: none;
      @media (min-width: $break-tablet-landscape) {
        top: 0;
        left: 80%;
      }
    }

    &.active {
      &:after {
        opacity: 0;
      }
    }
    &__video {
      opacity: 0;
      transition: opacity 1s ease;
      @media (min-width: $break-tablet-landscape) {
        z-index: 100;
        transform: translateX(36%) scale(1.3);
        transition: transform 1s 0.5s ease, opacity 0.5s ease;
      }
      .active & {
        opacity: 1;
        transform: translateX(0) scale(1);
      }
    }
  }
}
</style>
