<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12.9H20.9556C20.504 17.448 16.6668 21 12 21C7.33319 21 3.49599 17.448 3.04444 12.9H3V12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12V12.9ZM15.8332 12.9C15.6227 14.8899 14.662 17.1111 12.45 19.4597V12.9H15.8332ZM20.0506 12.9C19.6452 16.5672 16.7917 19.4965 13.1606 20.0175C15.4898 17.5231 16.5278 15.1083 16.7381 12.9H20.0506ZM20.1 12H16.7791C16.7766 11.3803 16.7106 10.7793 16.5922 10.1999C16.0281 7.44014 14.291 5.2493 12.7581 3.93501C16.8761 4.31724 20.1 7.78213 20.1 12ZM15.7104 10.3801C15.8161 10.897 15.8764 11.4379 15.8788 12H12.45V4.86456C13.7989 6.10289 15.23 8.02961 15.7104 10.3801ZM11.55 19.6388V12.9H7.99589C8.21165 14.9404 9.21632 17.2241 11.55 19.6388ZM10.6393 19.9862C7.10391 19.3882 4.34734 16.4995 3.94944 12.9H7.09094C7.3004 15.0991 8.33056 17.5029 10.6393 19.9862ZM7.04991 12H3.9C3.9 7.84885 7.02268 4.42704 11.0472 3.95545C9.52014 5.27195 7.79799 7.4544 7.23687 10.1999C7.11846 10.7793 7.05244 11.3803 7.04991 12ZM7.95023 12C7.95264 11.4379 8.013 10.897 8.11865 10.3801C8.61939 7.93001 10.1532 5.94037 11.55 4.71087V12H7.95023Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconGlobe"
};
</script>

<style lang="scss" scoped></style>
