<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.87459 12.3218V20L14.1254 18.4368V12.3218L19 4H5L9.87459 12.3218ZM13.2013 12.069V17.7931L10.7987 18.6667V12.069L6.61716 4.91954H17.3828L13.2013 12.069Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "Iconfilter"
};
</script>

<style lang="scss" scoped></style>
