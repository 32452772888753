<template>
  <CompTerraXAside :accordions="accordions" :website="blok.website" />
</template>

<script>
import asideDataFormatMixin from "~/components/terra-x/projectsAndInvestors/asideDataFormatMixin";
import CompTerraXAside from "@/components/terra-x/projectsAndInvestors/CompTerraXAside";
export default {
  name: "CompProjectAside",
  components: {
    CompTerraXAside
  },
  mixins: [asideDataFormatMixin],
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    accordions() {
      return [
        {
          title: "Project overview",
          data: this.accordionProjectOverview
        },
        {
          title: "Project impact",
          data: this.accordionProjectImpact
        },
        {
          title: "Financial overview",
          data: this.accordionFinancialOverview
        },
        {
          title: "Key contacts",
          data: this.accordionKeyContacts
        }
      ];
    },
    accordionProjectOverview() {
      return [
        {
          title: "Available from",
          body: this.formatYear(this.blok.projectTimelineStart)
        },
        {
          title: "Funding sought",
          body: this.formatCurrency(this.blok.fundingSaugth)
        },
        {
          title: "Location",
          body: this.formatString(this.blok.locationText)
        },
        {
          title: "Sector",
          body: this.formatArray(this.blok.sectorFocus, "sectors", "main")
        },
        {
          title: "Growth stage",
          body: this.formatArray(
            [this.blok.stageOfDevelopment],
            "project-stages-of-development"
          )
        },
        {
          title: "Sub sector",
          body: this.formatArray(this.blok.sectorFocus, "sectors", "sub")
        }
      ];
    },
    accordionProjectImpact() {
      return [
        {
          title: "Environmental impact",
          body: this.formatString(this.blok.environmentalImpact),
          fullWidth: true
        },
        {
          title: "Societal impact",
          body: this.formatString(this.blok.societalImpact),
          fullWidth: true
        },
        {
          title: "SDG Alignment",
          sdgItems: this.formatSdg(this.blok.sdgAlignment),
          body: null,
          fullWidth: true
        }
      ];
    },
    accordionFinancialOverview() {
      return [
        {
          title: "Funding sought",
          body: this.formatCurrency(this.blok.fundingSaugth)
        },
        {
          title: "Type of funding",
          body: this.blok.fundingType
        },
        {
          title: "Min. investment",
          body: this.formatCurrency(this.blok.minInvestment)
        }
      ];
    },
    accordionKeyContacts() {
      const body = this.blok.body;

      for (let i = 0; i < body.length; i++) {
        if (body[i].component == "CompTeam") {
          let contacts = body[i].keyContacts;

          return [
            {
              keyContacts: true,
              contacts: contacts,
              fullWidth: true
            }
          ];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
