<template>
  <main v-editable="blok" :key="blok._uid">
    <CompTerraXBanner :blok="blok" />

    <WMaxWidth class="wrapper">
      <article class="article">
        <WSection>
          <p class="subheading h4" v-observe-visibility="observed">
            {{ blok.shortDescription }}
          </p>
          <component
            v-for="item in blok.body"
            :key="item._uid"
            :blok="item"
            :is="item.component"
            v-editable="item"
          />
          <div class="investor-logo">
            <ILazyImage
              v-if="blok.investorLogo.filename"
              :filename="blok.investorLogo.filename"
              :alt="blok.investorLogo.alt"
              sizes="(min-width: 600px) 30vw, 100vw"
              :ratioW="16"
              :ratioH="0"
              class="investor-logo-img"
            />
          </div>
        </WSection>
      </article>
      <aside class="aside">
        <WSection class="aside__content">
          <CompInvestorAside :blok="blok" />
        </WSection>
      </aside>
    </WMaxWidth>
  </main>
</template>

<script>
import CompTerraXBanner from "@/components/terra-x/projectsAndInvestors/CompTerraXBanner";
import CompTerraXArticle from "@/components/terra-x/projectsAndInvestors/CompTerraXArticle";
import CompTeam from "@/components/terra-x/projectsAndInvestors/CompTeam";
import CompInvestorAside from "@/components/terra-x/projectsAndInvestors/CompInvestorAside";

import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "LayoutInvestor",
  components: {
    CompTerraXBanner,
    CompArticle: CompTerraXArticle,
    CompTeam,
    CompInvestorAside,
    WSection,
    WMaxWidth,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .wrapper > div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas:
    "side side side side side side side side side side side side"
    "main main main main main main main main main main main main";
  @media (min-width: $break-tablet-portrait) {
    grid-template-areas: "main main main main main main main side side side side side";
  }
  @media (min-width: $break-tablet-landscape) {
    grid-template-areas: ". main main main main main main . side side side side";
  }
}
.article {
  grid-area: main;
}
.aside {
  grid-area: side;
  &__content {
    @media (min-width: $break-tablet-portrait) {
      margin-left: 2rem;
      position: sticky;
      @include poly-fluid-sizing(
        top,
        (
          $break-mobile-portrait: $nav-height - $padding-mobile,
          $break-desktop-large: $nav-height - $padding-desktop,
          $break-widescreen: $nav-height - $padding-widescreen,
        )
      );
    }
    @media (min-width: $break-tablet-landscape) {
      margin-left: 0;
    }
    @media (min-width: $break-nav) {
      top: 0;
    }
  }
}

.investor-logo {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  &-img {
    grid-column-start: 1;
    grid-column-end: span 12;
    @media (min-width: $break-mobile-landscape) {
      grid-column-end: span 7;
    }
  }
}
</style>
