<template>
  <div
    :class="[
      'W-Background',
      { colored: colored, hasBackground: hasBackground }
    ]"
  >
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "W-Background",
  components: {},
  props: {
    type: {
      type: String,
      required: false,
      default: "fill"
    },
    colored: {
      type: Boolean,
      required: false,
      default: false
    },
    hasBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.W-Background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  div {
    position: relative;
    height: 100%;
  }
  &.colored {
    &:after {
      content: "";
      display: block;
      background-color: $color-blue;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 3;
    }
  }
  &.hasBackground {
    &:after {
      opacity: 0.6;
    }
  }
}
.fill {
  div {
    width: 100%;
  }
}
.fill-left {
  background-color: var(--color);
  div {
    width: 50%;
  }
}
.fill-right {
  background-color: var(--color);
  div {
    width: 50%;
    transform: translateX(100%);
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: 40%;
      background: linear-gradient(
        90deg,
        var(--color) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 3;
    }
  }
}
</style>
