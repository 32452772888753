<template>
    <div class="statlist_container">
        <h2 class="statlist_title">{{blok.title}}</h2>
        <div
            v-for="(stat, index) in blok.stats"
            :key="stat.id"
            class="stat_container"
            :style="'animation-delay:' + (index+1)/2 + 's;'"
        >
            <h1>{{stat.figure}}</h1>
            <p>{{stat.label}}</p>
        </div>

    </div>
</template>

<script>

export default {
    name: 'CompStatlist',
    props: {
        blok: {
            type: Object,
            required: true
        }
    }
}

</script>


<style lang="scss" scoped>
.statlist_container {
    width: 100vw;
    margin: 50px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;

    h2 {
        width: 100vw;
        text-align: center;
        margin-bottom: 20px;
    }

    .stat_container {
        width: 100vw;
        max-width: 350px;
        text-align: center;
        margin-bottom: 10px;
        opacity: 0;
        animation-name: fade-in-stat;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        p {
            margin: 0 50px;
        }
    }

}

@keyframes fade-in-stat {
    0% {
        opacity: 0;
        transform: translateY(50px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

</style>