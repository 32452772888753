<template>
  <div class="tx">
    <SealScrollCircle v-if="windowWidth >= 768" />
    <TerraXBackgroundBar :front="true" />
    <main v-editable="blok" :key="blok._uid" class="main">
      <WMaxWidth>
        <div class="terra-x">
          <div class="terra-x-grid">
            <div class="terra-x-grid__item">
              <!-- TerraXGlobePlaceholder  name="about" replicates this position -->
            </div>
            <div class="terra-x-grid__item">
              <TerraXIntro
                :title="blok.title"
                :description="blok.description"
                :projectsDescription="blok.projectsDescription"
                :investorsDescription="blok.investorsDescription"
                :insuranceDescription="blok.insuranceDescription"
                v-observe-visibility="observed"
              />
            </div>
          </div>
        </div>
      </WMaxWidth>
      <component
        v-for="(item, index) in blok.body"
        :key="item._uid"
        :blok="item"
        :isFirst="index === 0 ? true : false"
        :is="item.component"
        v-editable="item"
      />
    </main>
  </div>
</template>

<script>
import SealScrollCircle from "@/components/seal/SealScrollCircle";
import TerraXBackgroundBar from "@/components/terra-x/TerraXBackgroundBar";
import CompBlockquoteImage from "@/components/comps/CompBlockquoteImage";
import CompBlockquoteVideo from "@/components/comps/CompBlockquoteVideo";
import CompTextImage from "@/components/comps/CompTextImage";
import CompTextVideo from "@/components/comps/CompTextVideo";
import CompLogoWall from "@/components/comps/CompLogoWall";
import TerraXIntro from "@/components/terra-x/TerraXIntro.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import CompDisclaimer from "@/components/comps/CompDisclaimer";

export default {
  name: "LayoutTerraX",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  components: {
    TerraXBackgroundBar,
    CompBlockquoteImage,
    CompBlockquoteVideo,
    CompTextImage,
    CompTextVideo,
    CompLogoWall,
    TerraXIntro,
    SealScrollCircle,
    WMaxWidth,
    CompDisclaimer
  },
  computed: {},
  data() {
    return {
      scheduledAnimationFrame: false,
      windowHeight: 0,
      windowWidth: 0,
      globeBackgoundEl: undefined,
      globeEl: undefined
    };
  },
  mounted() {
    console.log("IS TERRA X SITE - terrax/setQueryType", "projects");
    this.$store.commit("terrax/setQueryType", "projects");

    this.$store.commit("terrax/globeOpen");
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);

    this.handleResize();
    this.handleScroll();

    this.globeBackgroundEl = document.querySelector(
      ".terra-x-background-bar-move"
    );
    this.globeEl = document.querySelector(".terra-x-globe-position-outer");
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    handleScroll() {
      if (this.scheduledAnimationFrame) {
        // Prevent multiple rAF callbacks.
        return;
      }
      this.scheduledAnimationFrame = true;
      requestAnimationFrame(this.handleScrollAnimationFrame);
    },
    handleScrollAnimationFrame() {
      const scrollY = window.scrollY < 0 ? 0 : window.scrollY;

      // calculate offset
      let globeOffset = 1 - scrollY / (this.windowHeight * 1.5);
      let backgroundOffset = 1 - scrollY / (this.windowHeight * 1.25);

      // get value from 0 - 1
      const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
      globeOffset = 1 - clamp(globeOffset, 0, 1);
      backgroundOffset = 1 - clamp(backgroundOffset, 0, 1);

      // apply
      this.globeEl.style.transform = "translateX(-" + globeOffset * 100 + "%)";
      this.globeBackgroundEl.style.transform =
        "translateX(-" + backgroundOffset * 100 + "%)";

      this.scheduledAnimationFrame = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);

    // reset offsets when leaving this view
    this.globeEl.style.transform = "translateX(0%)";
    this.globeBackgroundEl.style.transform = "translateX(0%)";
  }
};
</script>

<style lang="scss" scoped>
main {
  > * {
    position: relative;
    z-index: 10;
  }
}
.terra-x-grid {
  position: relative;
  min-height: calc(100vh - 8rem);
  // margin-top: $nav-height;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  grid-template-areas: "globe globe . txt txt txt txt txt txt txt txt txt";
  @media (min-width: $break-nav) {
    grid-template-areas: " globe globe globe globe globe  .  txt txt txt txt txt  . ";
  }

  &__item {
    display: flex;
    align-items: center;
    &:first-child {
      grid-area: globe;
    }
    &:last-child {
      grid-area: txt;
      // @extend %margin-top-bottom-x1;
    }
  }
}
.terra-x-intro__link {
  @extend %body1;
}
</style>
