<template>
  <div
    :class="['seal-graphic', `seal-graphic-${compType}`, { active: active }]"
  >
    <WVideoSourceSet
      ref="video"
      :class="['seal-graphic__video', `seal-graphic-${compType}__video`]"
      :video="video"
      :poster="poster"
      :enablePlayBtn="true"
    />
  </div>
</template>

<script>
import WVideoSourceSet from "@/components/wrappers/W-VideoSourceSet.vue";
export default {
  name: "SealGraphic",
  components: {
    WVideoSourceSet
  },
  props: {
    compType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    video() {
      if (this.$store.state.layout === "LayoutAstra") {
        return {
          filename: "" //"/assets/seal/astra/AstraCartaSeal.mp4"
        };
      } else {
        return {
          filename: "/assets/seal/TerraCartaSeal_colorDark.mp4"
        };
      }
    },
    poster() {
      if (this.$store.state.layout === "LayoutAstra") {
        return "/assets/seal/astra/AstraSeal_transparent.png";
      } else {
        return "/assets/seal/TerraCartaSeal_colorDark.png";
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.active = true;
    }, 300);
  }
};
</script>

<style lang="scss" scoped>
.seal-graphic {
  background-color: #fff;
  position: relative;
  z-index: 20;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  &__video {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    // max-height: 75vh;
    background-blend-mode: multiply;
    filter: brightness(1.02);
  }
  &-intro {
    @media (min-width: $break-tablet-landscape) {
      margin-top: -($nav-height / 2);
    }

    &:after {
      content: "";
      background-color: #fff;
      width: 100%;
      height: 100%;
      top: 100%;
      left: 0;
      position: absolute;
      opacity: 1;
      transition: 0.6s 1.5s ease;
      z-index: 0;
      pointer-events: none;
      @media (min-width: $break-tablet-landscape) {
        top: 0;
        left: 80%;
      }
    }

    &.active {
      &:after {
        opacity: 0;
      }
    }
    &__video {
      opacity: 0;
      transition: opacity 1s ease;
      @media (min-width: $break-tablet-landscape) {
        z-index: 100;
        transform: translateX(36%) scale(1.3);
        transition: transform 1s 0.5s ease, opacity 0.5s ease;
      }
      .active & {
        opacity: 1;
        transform: translateX(0) scale(1);
      }
    }
  }
}
</style>
