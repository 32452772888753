<template>
  <WListSimple
    :label="blok.label"
    :title="blok.title"
    :content="blok.content || {}"
    :titleH3="!blok.titleLarge"
    :buttons="blok.buttons"
    :listItems="blok.listItems"
  >
    <template v-slot:default="{ listItem }">
      <RUrl :blok="listItem" />
    </template>
  </WListSimple>
</template>

<script>
import WListSimple from "@/components/wrappers/W-ListSimple.vue";
import RUrl from "@/components/references/R-Url.vue";
export default {
  name: "compListUrl",
  components: {
    WListSimple,
    RUrl,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
