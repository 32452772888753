<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <div class="comp-winners">
        <div class="comp-winners__intro">
          <h2>
            {{ headline }}
            <sup>{{ winnersAmount }}</sup>
          </h2>
          <IText
            :title="blok.title"
            :titleH3="true"
            :content="content"
            :inverted="false"
            :fontB1="true"
          />
        </div>
        <ul class="comp-winners__list">
          <IWinnersLogo
            v-for="(logo, index) in logos"
            :key="`logo-${index}`"
            :reference="logo"
          />
        </ul>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
import IWinnersLogo from "@/components/items/I-WinnersLogo.vue";
export default {
  name: "compWinners",
  components: {
    WSection,
    WMaxWidth,
    IText,
    IWinnersLogo,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headline() {
      return this.blok.headline;
    },
    content() {
      return this.blok.text;
    },
    buttons() {
      return this.blok.buttons;
    },
    logos() {
      return this.blok.items;
    },
    logoSizes() {
      return `
        (min-width: ${this.$breakpoints.widescreen}px) ${
        this.$breakpoints.widescreen * 0.13
      }rem,
        (min-width: ${this.$breakpoints.mobileLandscape}px) 20vw,
        50vw
      `;
    },
    winnersAmount() {
      return this.blok.items.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-winners {
  &__intro {
    width: 100%;
    max-width: 720px;
    margin: auto;
    & h2 {
      margin-bottom: 1.6rem;
      & sup {
        font-size: 12px;
        position: relative;
        top: -5px;
      }
    }
  }

  &__list {
    @extend %padding-top-bottom-x1;
    @extend %grid-column-gap;
    @extend %grid-row-gap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $break-tablet-portrait) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: $break-desktop-small) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
</style>
