<template>
  <div class="RTeamContact" v-editable="blok" v-observe-visibility="observed">
    <ILazyImage
      v-if="blok.portrait && blok.portrait.filename"
      class="RTeamContact-portrait"
      :filename="blok.portrait.filename"
      :alt="blok.portrait.alt"
      sizes="(min-width: 600px) 400px, 100vw"
      :ratioW="16"
      :ratioH="0"
    />
    <div class="RTeamContact-content">
      <header class="RTeamContact-header">
        <span v-if="blok.name" class="RTeamContact-header-name">{{
          blok.name
        }}</span>
        <span v-if="blok.jobTitle" class="RTeamContact-header-job-title">{{
          blok.jobTitle
        }}</span>
      </header>
      <h3 class="RTeamContact-title">{{ blok.title }}</h3>
      <p class="RTeamContact-description" v-if="blok.description">
        {{ blok.description }}
      </p>
      <a
        :href="`mailto:${blok.email}`"
        class="RTeamContact-email"
        rel="noopener"
        >{{ blok.email }}</a
      >
    </div>
    <!-- <pre>{{blok}}</pre> -->
  </div>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "RTeamContact",
  components: {
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.RTeamContact {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  grid-template-areas: "img img img txt txt txt txt txt txt txt txt txt";
  border-bottom: 1px solid $color-blue-lighter;
  padding-bottom: 2rem;
  &:not(:first-child) {
    padding-top: 3rem;
  }
  @media (min-width: $break-tablet-portrait) {
    padding-bottom: 3rem;
    &:not(:first-child) {
      padding-top: 5rem;
    }
  }
  &-portrait {
    grid-area: img;
    margin-right: 2rem;
  }
  &-content {
    grid-area: txt;
    padding: 1rem 0;
  }
  &-header {
    &-name {
      display: block;
      @extend %body2;
      font-weight: 900;
      margin-bottom: 0.2rem;
    }
    &-job-title {
      @extend %lead;
      opacity: 0.5;
    }
  }
  &-description {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  &-email {
    @extend %body2;
    font-weight: 900;
    overflow-wrap: break-word;
  }
}
</style>
