<template>
  <div class="terra-x-nav-header">
    <button
      :class="[
        'terra-x-nav-header__button terra-x-nav-header__filters-button',
        'inverted',
        { active: isFilterOpen },
      ]"
      @click="filterToggle"
    >
      <IconFilter />
    </button>
    <header class="terra-x-nav-header__content">
      <transition name="fade" mode="out-in">
        <h4 :key="`${resultHeadline}`">
          {{ resultCount }} {{ resultHeadline }}
        </h4>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="terra-x-nav-header__content-sub" :key="`${activeFilters}`">
          <span class="terra-x-nav-header__content-sub-filters">{{
            activeFilters || "No filters applied"
          }}</span>
          <button
            v-if="activeFilters"
            @click="clearFilters"
            class="terra-x-nav-header__content-sub-clear"
          >
            Clear filters
          </button>
        </div>
      </transition>
    </header>
  </div>
</template>

<script>
import IconFilter from "@/components/icons/IconFilter.vue";
export default {
  name: "TerraXNavTypes",
  components: {
    IconFilter,
  },
  computed: {
    activeFilters() {
      let data = this.$store.getters["terrax/getRouteQuery"]();
      let string = "";

      // get pretty sector and region names
      let sectors = [];
      if (data.sectors.length) {
        sectors = this.$store.getters["terrax/getFiltersArrOfObjs"](
          data.sectors || [],
          "sectors"
        );
        sectors = sectors.map((sector) => sector.name);
      }
      let regions = [];
      if (data.region) {
        regions = this.$store.getters["terrax/getFiltersArrOfObjs"](
          [data.region],
          "region"
        );
        regions = regions.map((region) => region.name);
      }
      let stagesOfDevelopment = [];
      if (data.stageOfDevelopment) {
        stagesOfDevelopment = this.$store.getters["terrax/getFiltersArrOfObjs"](
          [data.stageOfDevelopment],
          "stageOfDevelopment"
        );
        stagesOfDevelopment = stagesOfDevelopment.map((region) => region.name);
      }

      if (sectors.length) {
        string += sectors.join(" + ");
      }
      if (sectors.length && stagesOfDevelopment.length) {
        string += " at the stage ";
      }
      if (stagesOfDevelopment.length) {
        string += stagesOfDevelopment.join(" + ");
      }
      if (
        (sectors.length && regions.length) ||
        (stagesOfDevelopment.length && regions.length)
      ) {
        string += " in ";
      }
      if (regions.length) {
        string += regions.join(" + ");
      }

      return string;
    },
    resultCount() {
      let data = this.$store.getters["terrax/filteredData"]();
      return data.outputLength;
    },
    resultHeadline() {
      let type = this.$store.getters["terrax/getType"]();
      switch (type) {
        case "projects":
          type = "sustainable project";
          break;
        case "investors":
          type = "investor";
          break;
        case "insurance":
          type = "insurance";
          break;
      }
      if (this.resultCount !== 1) {
        type += "s";
      }
      return type;
    },
    isFilterOpen() {
      return this.$store.getters["terrax/isFilterOpen"]();
    },
  },
  methods: {
    filterToggle() {
      this.$store.commit("terrax/filterToggle");
    },
    clearFilters() {
      this.$store.dispatch("terrax/clearFilters");
    },
  },
};
</script>

<style lang="scss" scoped>
.terra-x-nav-header {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  > * {
    pointer-events: auto;
  }
  &__filters-button {
    flex-shrink: 0;
    margin-right: 2rem;
  }
  &__content-sub {
    // display: flex;
    &-filters {
      @extend %body3;
      opacity: 0.5;
      margin-right: 1.3rem;
      // white-space: nowrap;
      // overflow:hidden;
      // text-overflow: ellipsis;
    }
    &-clear {
      @extend %lead;
      color: $color-white;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  &__button {
    @extend %button-icon;
  }
}
</style>
