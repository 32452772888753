<template>
  <component
    :is="blok.file && blok.file.filename ? 'ILink' : 'div'"
    class="RMeeting"
    :class="{
      'RMeeting--no-thumbnail': !blok.icon || !blok.icon.filename,
      'RMeeting--link': blok.file && blok.file.filename,
    }"
    :extUrl="blok.file && blok.file.filename"
    iconType="arrow"
  >
    <div class="RMeeting-thumbnail" v-if="blok.icon && blok.icon.filename">
      <img :src="blok.icon.filename" :alt="blok.icon.alt" />
    </div>
    <div class="RMeeting-content">
      <header class="RMeeting-header">
        <div class="RMeeting-header-date">{{ date }}</div>
      </header>
      <h3 class="RMeeting-title">{{ blok.title }}</h3>
    </div>
  </component>
</template>

<script>
import ILink from "@/components/items/I-Link.vue";
export default {
  name: "R-Meeting",
  components: {
    ILink,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    onlyDisplayMonthAndYear: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    date() {
      if (!this.blok.date) return null;
      const dateString = this.blok.date.replace(/-/g, "/").replace(/T.+/, "");
      const d = new Date(dateString);
      const day = ("0" + d.getDate()).slice(-2);
      const month = d.toLocaleString("default", { month: "long" });
      const year = d.getFullYear();
      return this.onlyDisplayMonthAndYear
        ? `${month} ${year}`
        : `${day} ${month} ${year}`;
    },
  },
  mounted() {
    //console.log("R-Meeting, blok", this.blok);
  },
};
</script>

<style lang="scss" scoped>
.RMeeting {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  grid-template-areas: "img img img txt txt txt txt txt txt txt txt txt";

  @media (min-width: $break-tablet-portrait) {
    grid-template-areas: "img img txt txt txt txt txt txt txt txt txt . ";
  }
  &--no-thumbnail {
    grid-template-areas: "txt txt txt txt txt txt txt txt txt txt txt . ";
  }
  &-thumbnail {
    grid-area: img;
    position: relative;
    margin-right: 1rem;
    overflow: hidden;
    @media (min-width: $break-mobile-landscape) {
      margin-right: 2rem;
    }
    & /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: transform 0.5s cubic-bezier($ease-out-cubic);
    }
  }
  &-content {
    grid-area: txt;
    padding: 1rem 0;
    align-self: center;
  }
  &-header {
    margin-top: 0.625em;
    margin-bottom: 0.625em;
    &-date {
      @extend %lead;
    }
  }
  &-title {
    @extend %headline4;
  }
  &-file {
    @extend %lead;
    margin-top: 0.625em;
    margin-bottom: 0.625em;
  }
  &--link {
    @media (hover: hover) {
      &:hover {
        /deep/ img {
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>
