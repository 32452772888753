<template>
  <div class="clear-filter">
    <p class="lead">No {{ resultType }} found</p>
    <div class="content">
      <div class="content-filters">
        You may want to
        <button @click="clearFilters">Clear filters</button>
      </div>
      <div class="content-search">
        Or perhaps try to
        <button @click="clearSearch">Clear text search</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TerraXNoResults",
  computed: {
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    resultType() {
      let type = this.$store.getters["terrax/getType"]();
      switch (type) {
        case "projects":
          return "projects";
        case "investors":
          return "investors";
        default:
          return "results";
      }
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch("terrax/clearFilters");
    },
    clearSearch() {
      this.$store.dispatch("terrax/clearSearch");
    },
  },
};
</script>

<style lang="scss" scoped>
.lead {
  @extend %headline4;
  margin-bottom: 2.5rem;
}
.content {
  @extend %body3;
  &-filters,
  &-search {
    margin-bottom: 1.5rem;
    @media (min-width: $break-terra-x-nav) {
      display: inline-block;
    }
  }
}
.clear-filter {
  text-align: center;
  background-color: rgba(#fff, 0.1);
  padding-top: 20vh;
  padding-bottom: 20vh;
  @extend %padding-left-right-x1;
  animation: fadeInUp 500ms ease-in-out; // keyframe in baseApp.scss
  & button {
    @extend %body3;
    margin: 0 2rem 0 0.8rem;
    color: #fff;
    opacity: 0.5;
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
    transition: opacity 250ms;
    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
