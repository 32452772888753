<template>
  <div>
    <transition-group tag="div" :name="animation" class="slider" mode="in-out">
      <div
        v-for="(item, i) in slides"
        :key="item.id"
        v-show="i == current"
        class="slider__group"
      >
        <div class="slider__item">
          <!--  <ILazyImage
            v-if="
              item.content.videoPosterImage &&
              item.content.videoPosterImage.filename
            "
            class="slider__image"
            :filename="item.content.videoPosterImage.filename"
            :alt="item.content.videoPosterImage.alt"
            sizes="(min-width: 575px) 50vw, 100vw"
            :ratioW="ratioW"
            :ratioH="ratioH"
            loading="eager"
            @click="slide(1)"
          />
          <div class="slider__content">
            <RPerson :blok="item.content" />
          </div>-->
          <div class="change-slide-fake-wrapper-big" @click="slide(1)"></div>
          <div class="change-slide-fake-wrapper-small" @click="slide(1)"></div>
          <RTestimonial
            :label="item.content.label"
            :name="item.content.name"
            :title="item.content.title"
            :image="item.content.videoPosterImage"
            :videoUrl="item.content.videoUrl"
            :iconPosRight="true"
            :videoSlide="true"
          />
        </div>
      </div>
    </transition-group>
    <div
      :class="['slider__dots', dotColor, { center: center }]"
      v-if="showDots"
    >
      <span
        class="slider__dots__item"
        v-for="(item, i) in slides"
        :key="item.id"
        :class="{ active: i == current }"
        @click="current = i"
      ></span>
    </div>
  </div>
</template>

<script>
import RTestimonial from "@/components/references/R-Testimonial.vue";
export default {
  name: "R-VideoSlider",
  components: {
    RTestimonial,
  },
  props: {
    slides: {
      type: Array,
      default: [""],
    },
    animation: {
      type: String,
      default: "slider",
    },
    center: {
      type: Boolean,
      default: false,
    },
    ratioW: {
      type: Number,
      default: 16,
    },
    ratioH: {
      type: Number,
      default: 9,
    },
    showDots: {
      type: Boolean,
      default: true,
    },
    dotColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      current: 0,
      direction: 1,
    };
  },
  methods: {
    slide(dir) {
      this.direction = dir;
      var len = this.slides.length;
      this.current = (this.current + (dir % len) + len) % len;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__group {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100%;
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
  }
  &__dots {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 20;
    &__item {
      content: "";
      cursor: pointer;
      width: 9px;
      height: 9px;
      border-radius: 5px;
      background-color: #fff;
      margin-left: 7px;
      opacity: 0.5;
      transition: 0.3s ease;
      &.active {
        opacity: 1;
      }
      .black & {
        opacity: 0.3;
        background-color: #000;
        &.active {
          opacity: 0.7;
        }
      }
    }
  }
}

/* GO TO NEXT SLIDE */
.slider-enter-active {
  animation-name: sliderIn;
  animation-duration: 1s;
  position: absolute;
  z-index: 0;
}
.slider-leave-active {
  position: absolute;
  animation-name: sliderOut;
  animation-duration: 1s;
  z-index: 10;
}
@keyframes sliderIn {
  0% {
    height: 100%;
  }
  100% {
    height: 100%;
  }
}
@keyframes sliderOut {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

.change-slide-fake-wrapper-big {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 80px);
  padding-bottom: 100%;
  background: transparent;
  z-index: 20;
}

.change-slide-fake-wrapper-small {
  position: absolute;
  top: 80px;
  right: 0;
  width: calc(100% - 80px);
  padding-bottom: 100%;
  background: transparent;
  z-index: 20;
}
</style>
