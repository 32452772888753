<template>
  <ul class="logos__list" :style="`--logos-per-row: ${logosPerRow}`">
    <li
      v-for="(referenceLogo, index) in logos"
      :key="`logo-${index}`"
      class="logos__list-item logo"
      v-observe-visibility="observed"
      v-editable="referenceLogo"
    >
      <a
        :href="referenceLogo.url"
        target="_blank"
        rel="noopener"
        :aria-label="referenceLogo.title"
        class="logos__list-item-link"
        :aria-disabled="!referenceLogo.url"
      >
        <ILazyImage
          class="logo-image"
          :filename="referenceLogo.logo.filename"
          :alt="referenceLogo.logo.alt"
          sizes="(min-width: 600px) 30vw, 100vw"
          :ratioW="16"
          :ratioH="0"
          loading="eager"
        />
        <div class="cta">
          <span>Visit website</span>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "CompLogosGrid",
  components: {
    ILazyImage,
  },
  props: {
    logos: {
      type: Array,
      required: true,
    },
    logosPerRow: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
.logos {
  &__list {
    --logos-per-row: 4;
    display: grid;
    grid-template-columns: repeat(var(--logos-per-row), 1fr);
    gap: 1.5rem;
    position: relative;
  }
  &__list-item {
    display: flex;
    align-items: center;
  }
  &__list-item-link {
    display: block;
    transition: all 300ms;
    &[aria-disabled="true"] {
      pointer-events: none;
    }
  }
}
.logo {
  a {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    .cta {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-blue;
      transform: translate3d(0, 100%, 0);
      transition: transform 0.4s cubic-bezier($ease-in-out-cubic);
      span {
        @extend %lead;
        color: white;
      }
    }
    & .logo-image {
      transition: transform 0.4s cubic-bezier($ease-in-out-cubic);
    }
    @media (hover: hover) {
      &:hover {
        .logo-image {
          transform: translate3d(0, -2.5rem, 0);
        }
        .cta {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>