<template>
  <ILink
    :class="['RNews', { extended: blok.extUrl }]"
    v-if="blok.url"
    :ext-url="blok.extUrl"
    :url="blok.url"
    :iconType="blok.extUrl ? 'external' : 'arrow'"
    iconPosition="top"
  >
    <ILazyImage
      class="RNews-thumbnail"
      :filename="blok.image.filename"
      :alt="blok.image.alt"
      sizes="(min-width: 600px) 400px, 100vw"
      :ratioW="16"
      :ratioH="0"
      v-if="!blok.extUrl"
    />
    <ILazyImage
      class="RNews-thumbnail-ext"
      :filename="blok.image.filename"
      :alt="blok.image.alt"
      sizes="(min-width: 600px) 400px, 100vw"
      :ratioW="16"
      :ratioH="16"
      v-else
    />
    <div class="RNews-content">
      <header class="RNews-header">
        <span v-if="blok.label" class="RNews-header-label">{{
          blok.label
        }}</span>
        <span v-if="date" class="RNews-header-date">{{ date }}</span>
      </header>
      <h5 class="RNews-title">{{ blok.title }}</h5>
      <p class="RNews-description" v-if="blok.content && !blok.extUrl">
        {{ blok.content }}
      </p>
    </div>
  </ILink>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
import ILink from "@/components/items/I-Link.vue";
export default {
  name: "RNews",
  components: {
    ILazyImage,
    ILink,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      const dateString = this.blok.date.replace(/-/g, "/").replace(/T.+/, "");
      const d = new Date(dateString);
      const day = ("0" + d.getDate()).slice(-2);
      const month = d.toLocaleString("default", { month: "long" });
      const year = d.getFullYear();
      return this.onlyDisplayMonthAndYear
        ? `${month} ${year}`
        : `${day} ${month} ${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.RNews {
  position: relative;
  display: grid;

  grid-template-columns: 70px 1fr;
  &.extended {
    grid-template-columns: 50px 1fr;
  }
  @media (min-width: $break-tablet-portrait) {
    grid-template-columns: 200px 1fr;
    &.extended {
      grid-template-columns: 100px 1fr;
    }
  }
  &-thumbnail {
    max-width: 100%;
    height: 30vw;
    margin-bottom: 20px;
    overflow: hidden;
    @media (min-width: $break-tablet-portrait) {
      margin-bottom: 0px;
      height: 100%;
      max-width: 200px;
    }
    & /deep/ img {
      height: 100%;
      position: absolute;
      object-fit: cover;
      transform: scale(1);
      transition: 0.3s ease;
      @media (min-width: $break-tablet-portrait) {
        transform: scale(1.2);
      }
    }
    &-ext {
      background-color: #f8f8f8;
      & /deep/ img {
        mix-blend-mode: multiply;
      }
      & /deep/ .placeholder-wrapper {
        display: none;
      }
      height: 50px;
      @media (min-width: $break-tablet-portrait) {
        height: 100px;
      }
    }
  }
  &-content {
    position: relative;
    z-index: 0;
    padding: 0rem 0rem 2rem 2rem;
    @media (min-width: $break-tablet-portrait) {
      padding-top: 2rem;
      padding-right: 100px;
    }
  }
  &-header {
    margin-bottom: 0.625em;
    &-label {
      @extend %lead;
      font-weight: 900;
      margin-right: 1em;
    }
    &-date {
      @extend %lead;
    }
  }
  &-title {
    @extend %headline4;
    margin-bottom: 0.5em;
  }
  @media (hover: hover) {
    &:hover {
      /deep/ img {
        transform: scale(1);
      }
    }
  }
}
</style>
