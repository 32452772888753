<template>
  <div class="seal-login__wrap" ref="loginForm">
    <WSection :margin="true" class="seal-login__wrap">
      <WMaxWidth>
        <!-- <div
          id="seal-login"
          :class="['seal-login', { hide: blok.hideSlideshow }]"
          :style="{
            '--pos-seal-tx': 'translateX(' + getPos * 100 + '%)',
            '--pos-seal-scale': 'scale(' + getScale + ')',
          }"
        > -->
        <div
          id="seal-login"
          :class="[
            'seal-login',
            { hide: blok.hideSlideshow },
            { 'only-slide': !blok.hideSlideshow },
          ]"
        >
          <div class="seal-login__placeholder" v-if="!blok.hideSlideshow">
            <div class="seal-login__placeholder__inside">
              <RSlider
                :ratioW="4"
                :ratioH="4"
                dotColor="black"
                :center="true"
                :slides="slides"
                animation="fade"
                :autoPlay="autoPlay"
              />
            </div>
          </div>
          <div class="seal-login__content" v-if="blok.hideSlideshow">
            <IText
              :title="blok.title"
              :titleH3="true"
              :content="blok.description"
              :inverted="false"
              :fontB1="true"
            />
            <div class="seal-login__grid" v-observe-visibility="observed">
              <div class="seal-login__input">
                <input
                  type="email"
                  placeholder="Email"
                  v-model="user.email"
                  required
                />
              </div>
              <div class="seal-login__btn">
                <button
                  class="seal-login__submit"
                  @click="login()"
                  :disabled="!permission || !user.email"
                >
                  Login
                </button>
              </div>
            </div>

            <div
              :class="['seal-login__checkbox', { active: permission }]"
              v-observe-visibility="observed"
              v-if="!blok.hideSlideshow"
            >
              <input type="checkbox" id="boom" v-model="permission" />
              <label for="boom">
                Accept
                <nuxt-link :to="'/' + blok.termsLink.cached_url" class="link"
                  >terms</nuxt-link
                >&nbsp;to continue
              </label>
              <a :href="'mailo:' + blok.requestAccessEmail" class="link"
                >Request new access</a
              >
            </div>
            <div class="error-message" v-if="error">
              <p>{{ error }}</p>
            </div>
          </div>
        </div>
      </WMaxWidth>
    </WSection>
  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");

import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
import RSlider from "@/components/references/R-Slider.vue";

export default {
  name: "CompSealLogin",
  components: {
    WSection,
    WMaxWidth,
    IText,
    RSlider,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scrollY: 0,
      windowHeight: 0,
      elTop: 0,
      user: {
        email: "",
      },
      permission: false,
      error: "",
      checked: false,
      slides: [
        {
          _uid: "seal-multi-dark",
          image: {
            filename:
              "https://a.storyblok.com/f/109506/1111x1111/5e7fe9b58c/seal-multi-dark.png",
          },
        },
        {
          _uid: "seal-multi-white",
          image: {
            filename:
              "https://a.storyblok.com/f/109506/1111x1111/6af6071e11/seal-multi-light.png",
          },
        },
        {
          _uid: "seal-sepia",
          image: {
            filename:
              "https://a.storyblok.com/f/109506/1111x1111/21d88e51cf/seal-sepia.png",
          },
        },
        {
          _uid: "seal-black",
          image: {
            filename:
              "https://a.storyblok.com/f/109506/1111x1111/a3b698edd7/seal-black.png",
          },
        },
      ],
    };
  },
  computed: {
    getPos() {
      let num = ((this.windowHeight / 2 + this.elTop) * -1) / this.windowHeight;
      num = num * 0.5 + 0.1;
      if (num < 0) {
        num = 0;
      } else if (num > 0.36) {
        num = 0.36;
      }
      return num;
    },
    getScale() {
      let num = ((this.windowHeight / 2 + this.elTop) * -1) / this.windowHeight;
      num = num * 0.15 + 0.92;
      if (num < 0.9) {
        num = 0.9;
      } else if (num > 1) {
        num = 1;
      }
      return num;
    },
    autoPlay() {
      let auto = false;
      if (this.blok.autoPlay === "true") {
        auto = true;
      }
      return auto;
    },
  },
  methods: {
    login() {
      // Post data to $auth
      this.$auth
        .login(this.user.email, this.$config.gatedPassword, false)
        .then((response) => {
          const user = response;
          const roles = user.app_metadata.roles;

          if (roles.includes("seal")) {
            this.user = user;
            sessionStorage.setItem("gotrue.user", JSON.stringify(user));
            this.$router.push("/terra-carta-seal/gated/seal-download");
          } else {
            this.error = "You need to be invited";
          }
        })
        .catch((boo) => {
          this.error = "You need to be invited";
        });
    },
    invited(hash) {
      const inviteQuery = "#invite_token=";
      const idQuery = "user_id=";
      const splitData = hash
        .replace(inviteQuery, "")
        .replace(idQuery, "")
        .split("#");

      if (splitData[0] && splitData[1]) {
        this.hasInviteHash = true;
        const token = splitData[0];
        const email = splitData[1];
        this.$auth.acceptInvite(token, this.$config.gatedPassword, false).then(
          (member) => {
            this.user = member;
          },
          (error) => {
            this.user.email = email;
          }
        );
      }
    },
    handleResize() {
      setTimeout(() => {
        this.windowHeight = window.innerHeight;
      }, 100);
    },
    handleScroll() {
      if (this.scheduledAnimationFrame) {
        return;
      }
      this.scheduledAnimationFrame = true;
      requestAnimationFrame(this.handleScrollAnimationFrame);
    },
    handleScrollAnimationFrame() {
      if (window.scrollY < 0) {
        this.scrollY = 0;
      } else {
        this.scrollY = window.scrollY;
      }
      const scrollParent = this.$el.getBoundingClientRect();
      this.elTop = Number(scrollParent.top - 80);

      this.scheduledAnimationFrame = false;
    },
  },
  mounted() {
    if (sessionStorage.getItem("gotrue.user")) {
      this.user = JSON.parse(sessionStorage.getItem("gotrue.user"));
      this.permission = true;
    }
    if (this.$route.hash) {
      this.invited(decodeURIComponent(this.$route.hash));
      this.$nextTick(() => {
        VueScrollTo.scrollTo(this.$refs.loginForm);
      });
    }

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleScroll();
    this.handleResize();
    if (this.blok.hideSlideshow) {
      this.permission = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.seal-login {
  --pos-seal-tx: translateX(0);
  --pos-seal-scale: scale(1);
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @extend %grid-column-gap;
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";
  max-width: 560px;
  margin: 0 auto 0;

  @media (min-width: $break-tablet-landscape) {
    grid-template-rows: repeat(2, 1fr);
    max-width: none;
    min-height: 200vh;
    margin: 0 0 50px;
    grid-template-areas: "se1 se1 se1 se1 se1 se1 se1 se2 se2 se2 se2 se2 ";
  }
  @media (min-width: $break-desktop-small) {
    grid-template-areas: " . se1 se1 se1 se1 se1 se1 se2 se2 se2 se2  . ";
  }

  &__wrap {
    padding-bottom: 10rem;
    @media (min-width: $break-tablet-landscape) {
      padding-bottom: 0;
    }
  }
  &__placeholder {
    grid-area: se2;

    @media (min-width: $break-tablet-landscape) {
      min-height: 100vh;
      grid-area: se1;
      display: flex;
      align-items: center;
      top: 0;
      position: sticky;

      transform: var(--pos-seal-tx) var(--pos-seal-scale);
    }
    &__inside {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: calc(100% + 30px);
      overflow: hidden;
      background-color: #fff;
    }
  }
  &__content {
    grid-area: se1;
    margin-top: auto;
    position: relative;
    z-index: $z-content;
    margin-bottom: 50px;
    @media (min-width: $break-tablet-landscape) {
      grid-area: se2;
      align-self: center;
      margin-bottom: auto;
    }
    & /deep/ h3 {
      margin-bottom: 0.8rem;
    }
  }
  &__grid {
    margin-top: 3rem;
    display: flex;
    flex: 1 0;
  }
  &.only-slide {
    grid-template-areas: "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1" !important;
    grid-template-rows: 1fr !important;
    min-height: auto;
    @media (min-width: $break-tablet-landscape) {
      grid-template-areas: " . . se1 se1 se1 se1 se1 se1 se1 se1 . . " !important;
    }
    @media (min-width: $break-desktop-small) {
      grid-template-areas: " . . . se1 se1 se1 se1 se1 se1 . . . " !important;
    }
    & .seal-login__placeholder {
      grid-area: se1 !important;
      min-height: auto !important;
    }
  }
  &.hide {
    grid-template-areas: "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2" !important;
    grid-template-rows: 1fr !important;
    justify-content: center;
    min-height: auto;
    max-width: 560px !important;
    margin: auto;
    @media (min-width: $break-tablet-landscape) {
      min-height: 100vh;
    }
    & .seal-login__content {
      margin: 0;
      grid-area: se2 !important;
    }
  }
}

.seal-login {
  &__input {
    margin-bottom: 3rem;
    margin-right: 3rem;
    width: 100%;
    & input {
      @extend %button;
      cursor: default;
      width: 100%;
      padding-left: 2rem;
      background-color: #fff;
      &:hover {
        color: $color-blue;
      }
      &::-webkit-input-placeholder {
        color: $color-blue;
      }
    }
  }
  &__checkbox {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 14px;
    user-select: none;
    &:before {
      content: "";
      width: 23px;
      height: 23px;
      position: absolute;
      left: 0;
      z-index: 0;
      transition: background-color 0.3s ease;
      border: 1px solid $color-blue;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0165 0.625L3.95817 7.74167L0.983171 4.76667L0.166504 5.58333L3.95817 9.375L11.8332 1.44167L11.0165 0.625Z' fill='white'/%3E%3C/svg%3E");
    }
    &.active {
      &:before {
        background-color: $color-blue;
      }
    }
    & label {
      padding-left: 33px;
      position: relative;
      z-index: 2;
    }
    & input {
      margin-right: 10px;
      display: none;
    }
  }
  &__submit {
    @extend %button;
    color: white;
    &:not(:disabled):before {
      height: 100% !important;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      color: $color-blue;
      &:hover {
        color: $color-blue;
      }
      &:hover:before {
        height: 0;
      }
      &:before {
        height: 0;
      }
    }
  }
}
.link {
  font-weight: bold;
  color: $color-blue;
}
</style>
