<template>
  <div class="terra-x-nav-types">
    <div class="terra-x-nav-types__wrapper">
      <nuxt-link
        class="terra-x-nav-types__link"
        :to="{
          path: $urlResolver($config.terraXProjectsPage),
          query: {
            globe: $store.state.terrax.routeQuery.globe,
          },
        }"
      >
        <span>Projects</span>
      </nuxt-link>
      <!--
      <nuxt-link
        class="terra-x-nav-types__link"
        :to="{
          path: $urlResolver($config.terraXInvestorsPage),
          query: {
            globe: $store.state.terrax.routeQuery.globe,
          },
        }"
        ><span>Investors</span></nuxt-link
      >
      -->
      <nuxt-link
        class="terra-x-nav-types__link"
        :to="{
          path: $urlResolver($config.terraXInsurancePage),
          query: {
            globe: false,
          },
        }"
      >
        <span>Insurance</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "TerraXNavTypes",
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.terra-x-nav-types {
  height: 19rem;
  @media (min-width: $break-terra-x-nav) {
    height: auto;
  }
  &__wrapper {
    display: inline-block;
    transform: rotate(-90deg) translate(-100%, 75%);
    white-space: nowrap;
    transform-origin: center left;
    @media (min-width: $break-terra-x-nav) {
      transform: none;
    }
    .terra-x-nav-types__link {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
  &__link {
    @extend %body1;
    color: white;
    display: inline-block;
    & span {
      display: inline-block;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: white;
        bottom: 0;
        transform-origin: 0 0;
        transition: transform 0.5s cubic-bezier($ease-out-cubic);
        transform: scale(0);
      }
    }
    &:hover span::after {
      transform: scale(1);
    }
    &.nuxt-link-active span::after {
      transform: scale(1);
    }

    & /deep/ .icon-change {
      fill: white;
    }
  }
}
</style>
