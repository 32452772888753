<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 20V5L19 12.7586L6 20Z" />
  </svg>
</template>

<script>
export default {
  name: "IconPlay"
};
</script>
