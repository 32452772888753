<template>
  <main
    class="arc-wrap"
    v-editable="blok"
    :key="blok._uid"
    :class="{ 'search-active': !filterActive }"
  >
    <WSection :margin="true" :class="$options.name">
      <WMaxWidth>
        <div class="archive">
          <div class="grid">
            <div class="grid__item grid__item--header">
              <div class="archive__left">
                <div class="archive__header">
                  <IText
                    :label="blok.label"
                    :title="blok.title"
                    :transition="false"
                    :inverted="false"
                    :fontB1="false"
                  />
                </div>

                <div class="archive__search-wrap">
                  <input
                    type="text"
                    :placeholder="blok.searchText"
                    v-model="search"
                    class="archive__search"
                    @input="changeFilter"
                  />
                </div>
              </div>
            </div>
            <div class="grid__item grid__item--news">
              <div class="archive__filter">
                <input
                  id="archive__check"
                  type="checkbox"
                  v-model="onlySMI"
                  @change="changeFilter"
                />
                <label for="archive__check">{{ blok.toggleText }}</label>
              </div>
              <h3 v-if="filtered.length < 1" class="archive__title">
                {{ blok.SearchNoResultText }}
              </h3>

              <div v-if="filterActive">
                <div
                  v-for="item in filtered"
                  :key="item.id"
                  class="grid__content"
                >
                  <div v-observe-visibility="observed">
                    <RNews :blok="item" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WMaxWidth>
    </WSection>
    <!-- <WListSimple
      :label="blok.label"
      :title="blok.title"
      :content="blok.content"
      :listItems="blok.news"
    >
      <template v-slot:default="{ listItem }">
        <RNews :blok="listItem" />
      </template>
    </WListSimple> -->
  </main>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IText from "@/components/items/I-Text.vue";
import RNews from "@/components/references/R-News.vue";

export default {
  name: "LayouNewsArchive",
  components: {
    WSection,
    WMaxWidth,
    IText,
    RNews,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      onlySMI: false,
      search: "",
      filterActive: true,
    };
  },
  computed: {
    filtered() {
      let news = [];
      if (this.blok.news) {
        news = this.blok.news;
        let search = this.search.toLowerCase();
        if (this.onlySMI && this.search) {
          news = this.blok.news
            .filter((item) => !item.extUrl)
            .filter(
              (item) =>
                item.title.toLowerCase().includes(search) ||
                item.content.toLowerCase().includes(search) ||
                item.label.toLowerCase().includes(search)
            );
        } else if (this.onlySMI) {
          news = this.blok.news.filter((item) => !item.extUrl);
        } else if (this.search) {
          news = this.blok.news.filter(
            (item) =>
              item.title.toLowerCase().includes(search) ||
              item.content.toLowerCase().includes(search) ||
              item.label.toLowerCase().includes(search)
          );
        }
      }

      return news;
    },
  },
  methods: {
    changeFilter() {
      this.filterActive = false;
      setTimeout(() => {
        this.filterActive = true;
      }, 100);
    },
    observed(isVisible, entry) {
      this.animateIn(isVisible, entry);
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  min-height: calc(100vh - #{$nav-height});
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  @extend %grid-column-gap;
  padding-top: 3rem;
  @media (min-width: $break-tablet-landscape) {
    grid-template-columns: 1fr 2fr;
  }
  &__item {
    position: relative;
    &--header {
      @media (min-width: $break-desktop-small) {
        margin-left: 25%;
        padding-right: 4rem;
      }
    }
    &--news {
      padding-top: 1rem;
    }
  }
  &__content {
    animation: fadeInUp 500ms ease-in-out forwards;
    & > div {
      padding-bottom: 2rem;
      border-bottom: 1px solid $color-blue-lighter;
    }
    &:not(:first-child) {
      & > div {
        padding-top: 3rem;
      }
    }
    @media (min-width: $break-tablet-portrait) {
      & > div {
        padding-bottom: 3rem;
      }
      &:not(:first-child) {
        & > div {
          padding-top: 5rem;
        }
      }
    }
  }
}
.archive {
  &__left {
    @media (min-width: $break-tablet-landscape) {
      position: sticky;
      top: 8rem;
      margin-right: 2rem;
    }
  }
  &__search-wrap {
    margin-top: auto;
    & input[type="text"] {
      border-bottom: 2px solid rgba($color-blue, 0.2);
      width: 100%;
      padding-bottom: 0px;
      font-size: 16px;
      &::placeholder {
        color: $color-blue;
        opacity: 1;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  &__filter {
    padding-top: 4rem;
    padding-bottom: 5rem;
    @media (min-width: $break-tablet-landscape) {
      margin-top: -6.5rem;
      padding-bottom: 4rem;
      padding-top: 0;
    }
    display: flex;
    justify-content: flex-end;
    user-select: none;
    & label {
      position: relative;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 12px;
      color: $color-blue;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: 3px;
        right: 20px;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: $color-blue;
        z-index: 2;
        transition: right 0.3s ease;
      }
      &:after {
        content: "";
        z-index: 1;
        position: relative;
        width: 43px;
        height: 24px;
        border-radius: 12px;
        background-color: #ededed;
        margin-left: 10px;
      }
    }
    & input {
      display: none;
      &:checked + label:before {
        right: 3px;
        background-color: #fff;
      }
      &:checked + label:after {
        background-color: $color-blue;
      }
    }
  }
  &__progress {
    border-bottom: 3px solid #f8f8f8;
  }
  &__title {
    animation: fadeInUp 500ms ease-in-out forwards;
  }
}
</style>
