<template>
  <WMaxWidth>
    <div class="square-loop">
      <div class="square-loop__Item square-loop--image">
        <div class="ratio-1-1">
          <WVimeoLoop
            v-if="blok.videoLoopUrl && blok.videoLoopUrl.vimeo_data"
            :vimeoUrl="blok.videoLoopUrl.vimeo_raw"
            :vimeoData="blok.videoLoopUrl.vimeo_data"
            :poster="blok.image"
          />
          <ILazyImage
            v-if="!blok.videoLoopUrl.vimeo_data && blok.image.filename"
            :filename="blok.image.filename"
            :alt="blok.image.alt"
            sizes="(min-width: 600px) 30vw, 100vw"
            :ratioW="16"
            :ratioH="0"
            classes="h100"
          />
        </div>
      </div>
      <div class="square-loop__Item square-loop--text">
        <IText
          :icon="blok.icon"
          :label="blok.label"
          :content="blok.content"
          :buttons="blok.buttons"
          :inverted="false"
          :fontB1="true"
        />
      </div>
    </div>
  </WMaxWidth>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVimeoLoop from "@/components/wrappers/W-VimeoLoop.vue";
import IText from "@/components/items/I-Text.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "compTextImage",
  components: {
    WSection,
    WMaxWidth,
    WVimeoLoop,
    IText,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    settingsAddPadding() {
      return this.blok.settings.includes("addPadding");
    },
    settingsImageLeft() {
      return this.blok.settings.includes("imageLeft");
    },
  },
};
</script>

<style lang="scss" scoped>
.square-loop {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";
  @extend %grid-column-gap;
  @media (min-width: $break-tablet-portrait) {
    min-height: 100vh;
    // We always want the text first on mobile
    grid-template-areas: "se1 se1 se1 se1 se1 se1 . se2 se2 se2 se2 se2";
    align-items: center;
  }
  @media (min-width: $break-desktop-small) {
    // We always want the text first on mobile

    grid-template-areas: ". se1 se1 se1 se1 se1  .  se2 se2 se2 se2 .";
    align-items: center;
  }
  &--text {
    grid-area: se2;
    padding: 30px 0;
    @media (min-width: $break-tablet-portrait) {
      padding: 60px 0;
    }
  }
  &--image {
    grid-area: se1;
  }
}
.ratio-1-1 {
  padding-bottom: 100%;
  position: relative;
  background-blend-mode: multiply;
  filter: brightness(1.03);
  & /deep/ .image {
    position: absolute;
    width: 100%;
    filter: brightness(1.15);
  }
  & /deep/ img {
    height: 100%;
    object-fit: cover;
  }
  & /deep/ .W-VimeoLoop {
    position: absolute;
  }
}
</style>
