<template>
  <div>
    <transition-group tag="div" :name="animation" class="slider" mode="in-out">
      <div
        v-for="(item, i) in slides"
        :key="item._uid"
        v-show="i == current"
        class="slider__group"
      >
        <div
          class="slider__item"
          @click="
            slide(1);
            handleAutoSlide(false);
          "
        >
          <ILazyImage
            class="slider__image"
            :filename="item.image.filename"
            :alt="item.image.alt"
            sizes="(min-width: 575px) 50vw, 100vw"
            :ratioW="ratioW"
            :ratioH="ratioH"
            loading="eager"
            @click="slide(1)"
          />
          <p class="slider__title">{{ item.title }}</p>
        </div>
      </div>
    </transition-group>
    <div
      :class="['slider__dots', dotColor, { center: center }]"
      v-if="showDots"
    >
      <span
        class="slider__dots__item"
        v-for="(item, i) in slides"
        :key="item._uid"
        :class="{ active: i == current }"
        @click="current = i"
      ></span>
    </div>
  </div>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "R-Slider",
  components: {
    ILazyImage,
  },
  props: {
    slides: {
      type: Array,
      default: [""],
    },
    animation: {
      type: String,
      default: "slider",
    },
    center: {
      type: Boolean,
      default: true,
    },
    ratioW: {
      type: Number,
      default: 4,
    },
    ratioH: {
      type: Number,
      default: 3,
    },
    showDots: {
      type: Boolean,
      default: true,
    },
    dotColor: {
      type: String,
      default: "white",
    },
    autoPlay: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      current: 0,
      direction: 1,
      autoSlide: this.autoPlay,
    };
  },
  methods: {
    slide(dir) {
      this.direction = dir;
      var len = this.slides.length;
      this.current = (this.current + (dir % len) + len) % len;
    },
    handleAutoSlide(anim) {
      const that = this;
      if (anim && !this.autoSlideInterval) {
        this.autoSlideInterval = setInterval(() => {
          that.slide(1);
        }, 2000);
      } else {
        clearInterval(this.autoSlideInterval);
      }
    },
  },
  mounted() {
    if (this.autoSlide) {
      this.handleAutoSlide(this.autoSlide);
    }
  },
};
</script>

<style lang="scss" scoped>
.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__group {
    width: 100%;
    height: 100%;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__title {
    position: absolute;
    top: 0px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
  &__dots {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 20;
    &.center {
      right: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &__item {
      content: "";
      cursor: pointer;
      width: 9px;
      height: 9px;
      border-radius: 5px;
      background-color: #fff;
      margin-left: 7px;
      opacity: 0.5;
      transition: 0.3s ease;
      &.active {
        opacity: 1;
      }
      .black & {
        opacity: 0.3;
        background-color: #000;
        &.active {
          opacity: 0.7;
        }
      }
    }
  }
}

/* GO TO NEXT SLIDE */
.slider-enter-active {
  animation-name: sliderIn;
  animation-duration: 1s;
  position: absolute;
  z-index: 0;
}
.slider-leave-active {
  position: absolute;
  animation-name: sliderOut;
  animation-duration: 1s;
  z-index: 10;
}
@keyframes sliderIn {
  0% {
    height: 100%;
  }
  100% {
    height: 100%;
  }
}
@keyframes sliderOut {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
</style>
