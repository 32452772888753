<template>
  <div class="grid-projects" ref="grid">
    <nuxt-link
      v-for="project in xData"
      :key="project.id"
      :to="project.url"
      class="grid-projects__item"
      ref="grid__item"
    >
      <div class="content">
        <div class="project-image">
          <ILazyImage
            v-if="project.image && project.image.filename"
            :filename="project.image.filename"
            :alt="project.image.alt"
            sizes="(min-width: 600px) 30vw, 100vw"
            :ratioW="16"
            :ratioH="9"
          />
        </div>
        <div class="project-content">
          <div class="grid-projects__header">
            <div class="grid-projects__sector">
              {{ formatArray(project.sector, "sectors") }}
            </div>
            <div class="grid-projects__location">
              <IconMap></IconMap>
              <span v-if="project.location">
                {{ showLocation(project.location) }}
              </span>
            </div>
          </div>
          <h4>{{ project.title }}</h4>
          <p>{{ project.description }}</p>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import asideDataFormatMixin from "~/components/terra-x/projectsAndInvestors/asideDataFormatMixin";
import ILazyImage from "@/components/items/I-LazyImage.vue";
import IconMap from "@/components/icons/IconMap.vue";
export default {
  name: "TerraXListProjects",
  components: {
    ILazyImage,
    IconMap,
  },
  mixins: [asideDataFormatMixin],
  props: {
    xData: {
      type: Array,
    },
  },
  watch: {
    xData(newValue, oldValue) {
      if (newValue !== oldValue) {
        setTimeout(() => {
          this.resizeAllGridItems();
        }, 10);
      }
    },
  },
  methods: {
    resizeGridItem(item) {
      let itemEl = item.$el;
      let grid = this.$refs.grid;
      let rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
      );
      let rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
      );
      let rowSpan = Math.ceil(
        (itemEl.querySelector(".content").getBoundingClientRect().height +
          rowGap) /
          (rowHeight + rowGap)
      );
      itemEl.style.gridRowEnd = "span " + rowSpan;
    },
    resizeAllGridItems() {
      let allItems = this.$refs.grid__item;
      for (let x = 0; x < allItems.length; x++) {
        this.resizeGridItem(allItems[x]);
      }
    },
    showLocation(string) {
      if (string.includes(",")) {
        console.log(string);
        return string.split(",")[1];
      } else {
        console.log(string);
        return string;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeAllGridItems);
  },
  mounted() {
    this.resizeAllGridItems();
    window.addEventListener("resize", this.resizeAllGridItems);
  },
};
</script>

<style lang="scss" scoped>
.grid-projects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  animation: fadeInUp 500ms ease-in-out forwards; // keyframe in baseApp.scss
  @media (min-width: $break-mobile-landscape) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 20px;
  }
  @media (min-width: $break-desktop-large) {
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    grid-auto-rows: 20px;
  }
  margin-bottom: 30px;

  &__item {
    background-color: #fff;
    & .project-image {
      height: 200px;
      overflow: hidden;
      & /deep/ .image {
        object-fit: cover;
        height: 100%;
        & img {
          height: 100%;
          object-fit: cover;
          transform: scale(1.01);
          transition: all 250ms;
        }
      }
    }
    &:nth-child(2) {
      & .project-image {
        height: 300px;
        & /deep/ .image {
          object-fit: cover;
          height: 100%;
          & img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        & .project-image {
          & /deep/ .image {
            & img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  &__header {
    margin-bottom: 12px;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-between;
    opacity: 0.5;
    letter-spacing: 0.1rem;
  }
  &__sector {
    max-width: 70%;
    min-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }
  &__location {
    display: flex;
    align-items: center;
    overflow: hidden;
    & span {
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.content {
  position: relative;
}
.project-content {
  color: $color-blue;
  padding: 32px;
  @extend %body2;
  & h4 {
    margin-bottom: 10px;
  }

  & p {
    @media (max-width: $break-mobile-landscape) {
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
  }
}
</style>
