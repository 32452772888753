<template>
  <WSection
    class="organisation"
    v-editable="blok"
    v-observe-visibility="observed"
  >
    <h2 class="organisation__title h3">Organisation data</h2>
    <ROrganisation
      :name="blok.name"
      :logo="blok.logo"
      :location="blok.location"
      :description="blok.description"
      :website="blok.website"
    />
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import CompLogosGrid from "@/components/terra-x/projectsAndInvestors/CompLogosGrid";
export default {
  name: "CompProjectOrganisation",
  components: {
    CompLogosGrid,
    WSection,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation {
  &__title {
    margin-bottom: 2rem;
  }
}
</style>
