<template>
  <main v-editable="blok" :key="blok._uid" ref="scrollParent">
    <SealScrollCircle v-if="windowWidth >= 768" />
    <SealScrollLineMobile v-if="windowWidth < 768" />
    <div :class="['content', { 'only-login': !blok.introTitle }]">
      <!-- <SealIntro
          v-if="blok.introTitle"
          :title="blok.introTitle"
          :description="blok.introDescription"
          @observed="onObserved"
          ref="intro"
          class="seal-component"
          :class="{ hiddens: !introAnimDone }"
        />-->
      <component
        v-for="(item, index) in blok.body"
        :key="item._uid"
        :blok="item"
        :isFirst="index === 0 ? true : false"
        :is="item.component"
        v-editable="item"
        ref="components"
        class="seal-component"
      />

      <SealFooter
        :title="blok.footerTitle"
        :email="blok.footerEmail"
        @observed="onObserved"
        ref="footer"
        class="seal-component seal-footer__wrap"
      />
    </div>
  </main>
</template>

<script>
import CompDownloadsAstra from "../astra/CompDownloadsAstra.vue";
import SealScrollCircle from "@/components/seal/SealScrollCircle";
import SealScrollLineMobile from "@/components/seal/SealScrollLineMobile";
import CompSealStickyIntroDark from "@/components/astra/CompSealStickyIntroDark";
import CompBlockquoteImage from "@/components/comps/CompBlockquoteImage";
import CompBlockquoteVideo from "@/components/comps/CompBlockquoteVideo";
import CompImage from "@/components/comps/CompImage";
import CompTextImageSquare from "@/components/comps/CompTextImageSquare";
import CompArticle from "@/components/comps/CompArticle";
import CompSealCarousell from "@/components/seal/CompSealCarousell";
import CompSealStickyCarousell from "@/components/seal/CompSealStickyCarousell";
import CompSealStickyVideo from "@/components/seal/CompSealStickyVideo";
import CompDownloads from "@/components/comps/CompDownloads.vue";
import CompSealTestimonials from "@/components/seal/CompSealTestimonials";
import CompSealLogin from "@/components/seal/CompSealLogin";
import SealFooter from "@/components/seal/SealFooter";

import SealGraphic from "@/components/seal/SealGraphic";
var VueScrollTo = require("vue-scrollto");

export default {
  name: "LayoutDefault",
  components: {
    CompDownloadsAstra,
    CompDownloads,
    SealScrollCircle,
    SealScrollLineMobile,
    CompSealStickyIntroDark,
    CompSealCarousell,
    CompSealStickyCarousell,
    CompBlockquoteImage,
    CompBlockquoteVideo,
    CompImage,
    CompTextImageSquare,
    CompArticle,
    CompSealTestimonials,
    CompSealLogin,
    SealFooter,
    SealGraphic,
    CompSealStickyVideo
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      introAnimDone: false,
      windowWidth: 0
    };
  },
  computed: {},
  methods: {
    onObserved(componentName, isVisible, entry) {
      // console.log('onObserved', componentName, isVisible, entry)
      this.observed(isVisible, entry);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
main {
  min-height: 100vh;
  position: relative;
  z-index: 2;
  &[name="Contact"] {
    min-height: auto;
  }
}
.seal-component {
  &:not(.seal-login__wrap) {
    position: relative;
  }
  &.seal-footer__wrap {
    z-index: 0;
    padding: 0 0 50px;
    margin-top: -140px;
  }
  transition: opacity 250ms 500ms;
  &.hidden {
    opacity: 0;
  }
}
.only-login {
  & /deep/ .seal-login__wrap {
    @media (min-width: $break-tablet-landscape) {
      padding-top: 0;
      margin-top: -$nav-height;
    }
  }
}
</style>
