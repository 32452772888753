<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth class="wrapper">
      <div
        :class="['C', { inverted: hasBackground }]"
        :style="{
          '--items': blok.listItems.length,
          '--scrollIntroRatio': scrollIntroRatio,
          '--scrollListRatio': scrollListRatio,
          '--scrollOutroRatio': scrollOutroRatio,
        }"
      >
        <WMaxWidth class="wrapper-text">
          <section v-if="blok.introText" class="intro-text">
            <h1>{{ blok.introText }}</h1>
          </section>
        </WMaxWidth>
        <section
          class="list"
          ref="list"
          v-observe-visibility="visibilityChanged"
        >
          <div class="grid">
            <ul class="items">
              <li
                v-for="(listItem, index) in blok.listItems"
                :key="`listHorizontal-item-${index}`"
                v-editable="listItem"
              >
                <IText
                  class="hz-text"
                  :icon="listItem.icon"
                  :label="listItem.label"
                  :title="listItem.title"
                  :showLabel="true"
                  :content="listItem.content"
                  :buttons="listItem.buttons"
                  :inverted="hasBackground"
                  :fontB1="true"
                ></IText>
              </li>
            </ul>
          </div>
        </section>
        <WMaxWidth class="wrapper-text">
          <section v-if="hasOutroText" class="outro-text">
            <h1>{{ blok.outroText }}</h1>
          </section>
        </WMaxWidth>
      </div>
    </WMaxWidth>
    <div class="sticky-area">
      <div :class="['background', { blur: intersectingScroll }]">
        <WVideoSourceSet
          ref="video"
          :video="blok.backgroundVideoLoopFile"
          :enableAutoPlayInPowerSaveMode="true"
        />
        <WVideoSourceSet
          ref="videoBlurred"
          :video="blok.backgroundVideoLoopFileBlurred"
          :enableAutoPlayInPowerSaveMode="true"
        />
      </div>
    </div>
  </WSection>
</template>

<script>
import * as ease from "~/plugins/easings.js";

import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVideoSourceSet from "@/components/wrappers/W-VideoSourceSet.vue";
import IText from "@/components/items/I-Text.vue";

export default {
  name: "C-ListHorizontal",
  components: {
    WSection,
    WMaxWidth,
    WVideoSourceSet,
    IText,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      intersectingScroll: false,
      scrollTop: 0,
      scrollHeight: 0,
      scheduledAnimationFrame: false,
    };
  },
  computed: {
    hasButtons() {
      return this.blok.buttons !== undefined;
    },
    hasIntroText() {
      return this.blok.introText !== undefined;
    },
    introHeight() {
      if (this.hasIntroText) {
        return this.windowHeight;
      }
      return 0;
    },
    hasOutroText() {
      return this.blok.outroText !== undefined;
    },
    outroHeight() {
      if (this.hasOutroText) {
        return this.windowHeight;
      }
      return 0;
    },
    hasBackground() {
      return true;
    },
    hasBackgroundBlur() {
      return this.blok.backgroundVideoLoopFileBlurred ? true : false;
    },
    windowHeight() {
      if (process.client) {
        return window.innerHeight;
      }
    },
    scrollValue() {
      let scrollValue = (this.scrollTop - this.introHeight) * -1;
      scrollValue = Math.max(0, scrollValue);
      scrollValue = Math.min(scrollValue, this.scrollHeight + this.outroHeight);
      return scrollValue;
    },
    scrollIntroRatio() {
      let ratio = (100 / this.introHeight) * this.scrollValue;
      ratio = Math.max(0, ratio);
      ratio = Math.min(ratio, 100);
      return ratio;
    },
    scrollListRatio() {
      let ratio =
        (1 / (this.scrollHeight - this.outroHeight)) *
        (this.scrollValue - this.introHeight);
      ratio = Math.max(0, ratio);
      ratio = Math.min(ratio, 1);

      ratio = ease.easeInOutQuad(ratio);

      ratio *= 100;

      return ratio;
    },
    scrollOutroRatio() {
      let ratio =
        (100 / this.outroHeight) * (this.scrollValue - this.scrollHeight);
      ratio = Math.max(0, ratio);
      ratio = Math.min(ratio, 100);
      return ratio;
    },
  },
  watch: {},
  mounted() {
    if (this.$refs.video && this.$refs.videoBlurred) {
      this.sync = true;
      this.syncVideos();
    }
    //console.log(this.component)

    window.addEventListener("scroll", this.updateScrollPosition);
    this.getScrollPosition();
  },
  beforeDestroy() {
    this.sync = false;
    //this.io.unobserve(this.$refs.list)
    window.removeEventListener("scroll", this.updateScrollPosition);
  },
  activated() {
    // Only used if keep-alive is used
  },
  deactivated() {
    // Only used if keep-alive is used
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.intersectingScroll = isVisible;
    },
    syncVideos() {
      if (!this.sync) {
        return;
      }
      this.$refs.videoBlurred.$el.currentTime =
        this.$refs.video.$el.currentTime;
      setTimeout(() => {
        this.syncVideos();
      }, 4000);
    },
    updateScrollPosition(e) {
      if (this.intersectingScroll) {
        // Prevent multiple rAF callbacks.
        if (this.scheduledAnimationFrame) {
          return;
        }

        this.scheduledAnimationFrame = true;
        requestAnimationFrame(this.getScrollPosition);
      }
    },
    getScrollPosition() {
      this.listBoundingRect = this.$refs.list.getBoundingClientRect();
      this.scrollTop = this.listBoundingRect.top;
      this.scrollHeight = this.listBoundingRect.height;
      this.scheduledAnimationFrame = false;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.hz-text /deep/ h2 {
  @extend %headline3;
  margin-bottom: 15px;
}
// overwrite W-WMaxWidth padding
/deep/.wrapper {
  @media (min-width: $break-mobile-landscape) {
    > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
/deep/.wrapper-text {
  @media (max-width: $break-mobile-landscape - 1) {
    > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
.C {
  --scrollIntroRatio: 0;
  --scrollListRatio: 0;
  --scrollOutroRatio: 0;
  //display: grid;
  //grid-template-columns: repeat(12, 1fr);
  //@extend %grid-column-gap;
  section {
    position: relative;
    width: 100%;
    h1 {
      @extend %title1;
      text-align: center;
    }
    &.intro-text,
    &.outro-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
    &.intro-text {
      opacity: calc(1 - (var(--scrollIntroRatio) * 2 / 100));
      // filter: blur(calc(var(--scrollIntroRatio) / 100 * 1rem));
      transform: translate3d(0, calc(var(--scrollIntroRatio) * 0.5%), 0);
    }
    &.outro-text {
      opacity: calc((var(--scrollOutroRatio) * 2 - 100) / 100);
      // filter: blur(calc((1 - var(--scrollOutroRatio) / 100) * 1rem));
      transform: translate3d(
        0,
        calc((var(--scrollOutroRatio) - 100) * 0.5%),
        0
      );
    }
  }
  .grid {
    @extend %padding-top-bottom-x1;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @extend %grid-column-gap;
    > * {
      align-self: center;
    }
    &.hasHeader {
      grid-template-rows: 0.8fr 1fr;
    }
    header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      grid-column-start: 1;
      grid-column-end: 13;
      //@extend %padding-top-bottom-x1;
    }
    .items {
      grid-column-start: 1;
      grid-column-end: 13;
    }
  }
  .button-list {
    margin-top: 2rem;
    > * {
      &:not(:first-child) {
        margin-left: 3rem;
      }
    }
  }
  .items {
    //overflow-x: auto;
    /*display: grid;
    grid-template-columns: repeat(calc(4 * var(--items) + 1), 1fr);
    width: calc(33vw * var(--items));*/
    li {
      //@extend %padding-right-x3;
      //transition: transform 0.05s cubic-bezier($ease-out-cubic);
      // SETTING: - 1
      //transform: translate3d(calc(var(--scrollListRatio) * (var(--items) - 1) * -1%), 0, 0);
      &:not(:first-child) {
        padding-top: 10em;
      }
    }
    /*&.loose{
    }*/
  }

  @media (min-width: $break-mobile-landscape) {
    section {
      &.list {
        height: calc(40vh * var(--items));
      }
    }
    .grid {
      position: sticky;
      top: 0;
      left: 0;
      height: 100vh;
      overflow: hidden;
      header {
        grid-column-start: 2;
        grid-column-end: span 8;
      }
      .items {
        display: flex;
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }
    .items li {
      min-width: 45vw;
      padding-right: 15em;
      grid-column-start: 2;
      grid-column-end: 12;

      &:not(:first-child) {
        padding-top: 0;
      }

      // SETTING: - 2
      transform: translate3d(
        calc(var(--scrollListRatio) * (var(--items) - 2) * -1%),
        0,
        0
      );
    }
  }
  @media (min-width: $break-tablet-landscape) {
    .grid header {
      grid-column-end: span 5;
    }
    .items li {
      min-width: 29vw;
      padding-right: 10em;
      // SETTING: - 3
      transform: translate3d(
        calc(var(--scrollListRatio) * (var(--items) - 3) * -1%),
        0,
        0
      );
    }
  }

  &.inverted {
    color: white;
  }
}
.sticky-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
  z-index: -2;
}
.background {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: $color-blue;
  /*&:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.7s cubic-bezier($ease-in-out-cubic);

    .no-backdropfilter & {
      background-color: rgba(0,0,0,0.5);
    }

    .backdropfilter & {
      backface-visibility: hidden;
      backdrop-filter: blur(3vw);
    }
  }*/
  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    &:last-child {
      opacity: 0;
      transition: opacity 0.7s cubic-bezier($ease-in-out-cubic);
    }
  }
  &.blur {
    > * {
      &:last-child {
        opacity: 1;
      }
    }
  }
}
</style>
