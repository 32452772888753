var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WSection',{class:_vm.$options.name,attrs:{"margin":true}},[_c('WMaxWidth',[_c('div',{ref:"component",class:[
        'C',
        {
          addPadding: _vm.addPadding,
          videoOnLeftSide: _vm.videoOnLeftSide,
          playing: _vm.playing,
          loading: _vm.loading,
          unGrid: _vm.unGrid,
          transitioning: _vm.transitioning,
          videoOpen: _vm.videoOpen,
        } ]},[_c('div',{ref:"posterSizeReference",staticClass:"poster-size-reference"}),_vm._v(" "),(
          _vm.blok.videoUrl &&
          _vm.blok.videoUrl.vimeo_data &&
          _vm.blok.videoUrl.vimeo_data.id
        )?_c('WAspectRatio',[(_vm.onLoading)?_c('WVimeoSource',{ref:"vimeoSource",attrs:{"title":_vm.label,"vimeoId":_vm.blok.videoUrl.vimeo_data.id},on:{"playing":_vm.onPlaying,"loading":_vm.onLoading}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('section',[_c('IText',{attrs:{"label":_vm.blok.label,"title":_vm.blok.title,"content":_vm.blok.content,"inverted":false,"fontB1":true}})],1),_vm._v(" "),_c('section',{ref:"videoPoster",style:({
          '--poster-left': _vm.posterLeft + 'px',
          '--poster-width': _vm.posterWidth + 'px',
        })},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.observed),expression:"observed"}]},[_c('WVideoPoster',{attrs:{"posterImage":_vm.posterImage,"showPoster":!_vm.playing,"loading":_vm.loading,"sizes":_vm.posterSizes},on:{"play":_vm.onPlay}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }