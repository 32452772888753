<template>
  <WListSimple
    :label="blok.label"
    :title="blok.title"
    :titleH3="!blok.titleLarge"
    :content="blok.content"
    :buttons="blok.buttons"
    :listItems="listItemsSorted"
  >
    <template v-slot:default="{ listItem }">
      <!-- <IListItem :blok="listItem"/> -->
      <RMeeting
        :blok="listItem"
        :onlyDisplayMonthAndYear="settingOnlyDisplayMonthAndYear"
      />
    </template>
  </WListSimple>
</template>

<script>
import WListSimple from "@/components/wrappers/W-ListSimple.vue";
import RMeeting from "@/components/references/R-Meeting.vue";
export default {
  name: "compListMeeting",
  components: {
    WListSimple,
    RMeeting,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // remove this from options?
    // settingStickyScroll() {
    //   return this.blok.settings.includes('scrollingText')
    // },
    settingOnlyDisplayMonthAndYear() {
      return this.blok.settings.includes("onlyMonthYear");
    },
    settingCustomOrder() {
      // = disable date
      return this.blok.settings.includes("customOrder");
    },
    settingReverseOrder() {
      // = reverse date
      return this.blok.settings.includes("reverseOrder");
    },
    listItemsSorted() {
      // If date sorting is disabled, just return the list in the order it came
      if (this.settingCustomOrder) {
        return this.blok.listItems;
      } else if (this.settingReverseOrder) {
        const items = this.blok.listItems.sort((a, b) => {
          const aDate = a.date.replace(/-/g, "/").replace(/T.+/, "");
          const bDate = b.date.replace(/-/g, "/").replace(/T.+/, "");
          return new Date(aDate) - new Date(bDate);
        });
        return items;
      } else {
        const items = this.blok.listItems.sort((a, b) => {
          const aDate = a.date.replace(/-/g, "/").replace(/T.+/, "");
          const bDate = b.date.replace(/-/g, "/").replace(/T.+/, "");
          return new Date(bDate) - new Date(aDate);
        });
        return items;
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
