<template>
  <div class="terra-x-nav-views">
    <button
      :disabled="isGlobeButtonDisabled"
      @click="globeOpen"
      ref="globe"
      :class="[
        'terra-x-nav-views__button',
        'inverted',
        { active: isGlobeOpen },
      ]"
    >
      <IconGlobe />
    </button>
    <button
      @click="globeClose"
      :class="[
        'terra-x-nav-views__button',
        'inverted',
        { active: !isGlobeOpen },
      ]"
    >
      <IconSearchProjects v-if="type === 'projects'" />
      <IconSearchInvestors v-else />
    </button>
  </div>
</template>

<script>
import IconGlobe from "@/components/icons/IconGlobe.vue";
import IconSearchProjects from "@/components/icons/IconSearchProjects.vue";
import IconSearchInvestors from "@/components/icons/IconSearchInvestors.vue";
export default {
  name: "TerraXNavViews",
  components: {
    IconGlobe,
    IconSearchProjects,
    IconSearchInvestors,
  },
  watch: {
    isFilterOpen(newValue, oldValue) {
      this.handleFilterOpen();
    },
  },
  computed: {
    isGlobeButtonDisabled() {
      return this.$store.getters["terrax/isGlobeButtonDisabled"]();
    },
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    routeQuery() {
      return this.$store.getters["terrax/getRouteQuery"]();
    },
    isFilterOpen() {
      return this.$store.getters["terrax/isFilterOpen"]();
    },
    type() {
      let type = this.$store.getters["terrax/getType"]();
      return type;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.getGlobePos();
    },
    handleFilterOpen() {
      setTimeout(() => {
        this.getGlobePos();
      }, 350);
    },
    getGlobePos() {
      const scrollY = window.scrollY;
      let rect = this.$refs.globe.getBoundingClientRect();
      // console.log(
      //   "getGlobePos",
      //   rect.top + scrollY,
      //   "scroll",
      //   scrollY,
      //   "top",
      //   rect.top
      // );
      this.$store.commit("terrax/setGlobeFromTop", rect.top + scrollY);
    },
    globeOpen() {
      this.$store.commit("terrax/globeOpen");
      this.$router.push({ query: this.routeQuery });
    },
    globeClose() {
      this.$store.commit("terrax/globeClose");
      this.$router.push({ query: this.routeQuery });
    },
  },
};
</script>

<style lang="scss" scoped>
.terra-x-nav-views {
  display: flex;
  flex-direction: column;
  z-index: 3;
  @media (min-width: $break-terra-x-nav) {
    flex-direction: initial;
  }
  &__button {
    @extend %button-icon;
    flex-shrink: 0;

    &.inverted:disabled {
      color: #fff;
      cursor: not-allowed;
      &:before {
        display: none;
      }
    }
    &:disabled:hover:before {
      display: none;
    }

    &[disabled="disabled"] {
      position: relative;

      border-color: rgba(255, 255, 255, 0.3);

      & svg {
        opacity: 0.3;
      }
      @media (min-width: $break-terra-x-nav) {
        &::after {
          content: "Globe view is not available";
          color: rgba(255, 255, 255, 0);
          overflow: hidden;
          height: 100%;
          width: 0;
          position: absolute;
          left: -2px;
          z-index: 900;
          top: 0;
          padding: 10px;
          text-align: center;
          background: $color-blue;
          transform: translateX(-100%);
          transition: width 0.25s;
          pointer-events: none;
        }

        &:hover {
          &::after {
            width: 300px;
            color: rgba(255, 255, 255, 1);
            transition: width 0.35s, color 0.35s 0.25s;
          }
        }
      }
    }
  }
}
</style>
