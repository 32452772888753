<template>
  <WSection class="featured-on">
    <div class="featured-on__header" v-observe-visibility="observed">
      <h2 class="featured-on__header-title h3">Featured on</h2>
    </div>
    <CompLogosGrid :logos="logos" :logosPerRow="4" />
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import CompLogosGrid from "@/components/terra-x/projectsAndInvestors/CompLogosGrid";

export default {
  name: "CompProjectFeaturedOn",
  components: {
    CompLogosGrid,
    WSection,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logos() {
      return this.blok.listItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-on {
  flex-direction: column;
  .logos__list {
    filter: grayscale(1);
  }
}
</style>