<template>
  <li
    class="winners-logo"
    v-observe-visibility="observed"
    :class="{ 'winners-logo--has-link': hasLink }"
  >
    <a
      :href="reference.url"
      target="_blank"
      class="winners-logo__link"
      rel="noopener"
    >
      <ILazyImage
        class="winners-logo__link-image"
        :filename="reference.logo.filename"
        :alt="reference.logo.alt"
        sizes="(min-width: 600px) 30vw, 100vw"
        :ratioW="16"
        :ratioH="0"
        loading="eager"
      />
      <div class="winners-logo__link-cta">
        <span>Visit website</span>
      </div>
    </a>
    <div
      class="winners-logo__legal"
      v-if="hasLegalText"
      @click="onLegalClick"
      @mouseenter="onLegalMouseEnter"
      @mouseleave="onLegalMouseLeave"
    >
      <div
        class="winners-logo__legal-handle"
        :class="{ 'winners-logo__legal-handle--active': showLegalText }"
      >
        <span>i</span>
      </div>
      <transition name="fade">
        <div class="winners-logo__legal-text" v-if="showLegalText">
          <span>{{ reference.legaltext }}</span>
        </div>
      </transition>
    </div>
  </li>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";

export default {
  name: "IWinnersLogo",
  components: {
    ILazyImage,
  },
  props: {
    reference: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLegalText: false,
    };
  },
  computed: {
    hasLink() {
      return this.reference.url && this.reference.url != "";
    },
    hasLegalText() {
      return this.reference.legaltext && this.reference.legaltext != "";
    },
  },
  methods: {
    onLegalClick() {
      if (this.$deviceIsMobile) {
        this.showLegalText = !this.showLegalText;
      }
    },
    onLegalMouseEnter() {
      if (!this.$deviceIsMobile) {
        this.showLegalText = true;
      }
    },
    onLegalMouseLeave() {
      if (!this.$deviceIsMobile) {
        this.showLegalText = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.winners-logo {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 81.1224489796%;
  }
  &__legal {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    min-width: 4.5rem;
    height: 4.5rem;
    cursor: help;
  }
  &__legal-handle {
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    top: 50%;
    left: 50%;
    border: 1px solid $color-blue;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $color-blue;
    & span {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      font-size: 1.1rem;
      text-align: center;
    }
    &--active {
      background-color: $color-blue;
      color: #fff;
    }
  }

  &__legal-text {
    position: absolute;
    width: 13rem;
    top: 3.5rem;
    right: 1.5rem;
    box-sizing: border-box;
    display: block;
    padding: 1rem;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 4rem rgba(0, 0, 0, 0.3);
    background-color: #fff;
    color: $color-blue;
    pointer-events: none;
    @media (min-width: $break-mobile-landscape) {
      top: 1.5rem;
      right: 4rem;
    }
    & span {
      font-size: 1.2rem;
      line-height: 1.2em;
      display: block;
    }
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: auto;
  }
  &__link-image {
    width: 100%;
    transition: transform 0.4s cubic-bezier($ease-in-out-cubic);
  }
  &__link-cta {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-blue;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.4s cubic-bezier($ease-in-out-cubic);
    & span {
      @extend %lead;
      color: white;
    }
  }
  &--has-link {
    cursor: pointer;
    pointer-events: unset;
    @media (hover: hover) {
      &:hover {
        .winners-logo__link-image {
          transform: translate3d(0, -2.5rem, 0);
        }
        .winners-logo__link-cta {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
