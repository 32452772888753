<template>
  <div class="testimonial" v-observe-visibility="observed">
    <div :class="['video-pop', { 'is-poped': isPoped }]" ref="video">
      <div class="modal-grid">
        <div class="modal-grid__video" @click="popVideo">
          <WVimeoSource
            v-if="onLoading"
            ref="vimeoSource"
            title="label"
            :vimeoId="videoUrl.vimeo_data.id"
            v-on:playing="onPlaying"
            v-on:loading="onLoading"
            :popup="isPoped"
          />
          <WVideoPoster
            :posterImage="image"
            :showPoster="!playing"
            :loading="loading"
            v-on:play="onPlay"
            :sizes="posterSizes"
            :iconPosRight="iconPosRight"
          />
        </div>
        <div class="modal-grid__close" v-if="isPoped">
          <button @click="closeOverlay()">
            <IconClose />
          </button>
        </div>
        <div class="modal-grid__text" v-if="isPoped">
          <RPerson :blok="person" />
        </div>
      </div>
    </div>

    <RPerson
      class="person"
      :blok="person"
      v-observe-visibility="observed"
      :class="{ 'video-slide': videoSlide }"
    />
  </div>
</template>

<script>
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import WVideoPoster from "@/components/wrappers/W-VideoPoster.vue";
import WVimeoSource from "@/components/wrappers/W-VimeoSource.vue";
import WAspectRatio from "@/components/wrappers/W-AspectRatio.vue";
import IconClose from "@/components/icons/IconClose.vue";
import RPerson from "@/components/references/R-Person.vue";

export default {
  name: "RTestimonial",
  components: {
    WVideoPoster,
    WVimeoSource,
    WAspectRatio,
    IconClose,
    RPerson,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    videoUrl: {
      type: Object,
      default: {},
    },
    image: {
      type: Object,
      default: "",
    },
    iconPosRight: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    videoSlide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animationTime: 1000,
      isPoped: false,
      playing: false,
      loading: false,
    };
  },
  computed: {
    person() {
      return {
        label: this.label,
        name: this.name,
        title: this.title,
      };
    },
    posterSizes() {
      return `
          (min-width: ${this.$breakpoints.widescreen}px) ${
        this.$breakpoints.widescreen * 0.045
      }rem,
          (min-width: ${this.$breakpoints.mobileLandscape}px) 45vw,
          95vw
        `;
    },
    videoPopupActive() {
      return this.$store.state.videoPopup.isActive;
    },
    videoPopupActiveID() {
      return this.$store.state.videoPopup.activeID;
    },
  },
  methods: {
    onPlaying(val) {
      this.playing = val;
    },
    onLoading(val) {
      this.loading = val;
    },
    onPlay() {
      this.$refs.vimeoSource.showLoader();
      setTimeout(() => {
        this.$refs.vimeoSource.play();
      }, 500);
    },
    closeOverlay() {
      this.isPoped = false;
      enableBodyScroll(this.$refs.video);
      document.querySelector("body").classList.remove("popup-active");
    },
    popVideo() {
      this.isPoped = true;
      //disableBodyScroll(this.$refs.video);
      document.querySelector("body").classList.add("popup-active");
      // const video = this.$refs.video;

      // const videoSizes = this.$refs.video.getBoundingClientRect();
      // video.style.setProperty("--video-animation", this.animationTime + "ms");
      // video.style.setProperty("--video-top", videoSizes.y + "px");
      // video.style.setProperty("--video-left", videoSizes.x + "px");
      // video.style.setProperty("--video-width", videoSizes.width + "px");
      // video.style.setProperty("--video-height", videoSizes.height + "px");

      // setTimeout(() => {
      //   video.style.setProperty("--video-top", "0");
      //   video.style.setProperty("--video-left", "0");
      //   video.style.setProperty("--video-width", "100%");
      //   video.style.setProperty("--video-height", "100%");
      // }, 10);

      // setTimeout(() => {
      //   video.classList.add("animation-done");
      // }, this.animationTime);
    },
  },
  mounted() {
    // Get video size set as vars
  },
};
</script>

<style lang="scss" scoped>
.testimonial {
  transform: initial;
  --video-animation: 300ms;
  --video-top: 0;
  --video-left: 0;
  --video-width: 0;
  --video-height: 0;
}
.person {
  margin-top: 2rem;
  &.video-slide {
    position: absolute;
    top: calc(100% - 35px);
    left: 20px;
    z-index: 2;
    transform: translateY(-100%);
  }
}
.modal-grid {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  z-index: 1;
}
.video {
  &-pop {
    transition: width var(--video-animation) ease,
      height var(--video-animation) ease, top var(--video-animation) ease,
      left var(--video-animation) ease;
    &:after {
      content: "";
      background-color: rgba($color-blue, 0);
      position: fixed;
      top: 0;
      left: 0;
    }
    &.is-poped {
      position: fixed;
      // width: var(--video-width);
      // height: var(--video-height);
      // top: var(--video-top);
      // left: var(--video-left);
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9000;
      display: flex;
      align-items: center;
      animation-name: testimonailTransistion;
      animation-duration: 1s;
      animation-fill-mode: forwards;

      &:after {
        background-color: rgba($color-blue, 1);
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      &.animation-done {
        & .modal-grid {
          &__close {
            opacity: 1;
          }
          &__text {
            color: #fff;
            opacity: 1;
          }
        }
      }
      & .modal-grid {
        @extend %padding-left-right-x1;
        width: 100%;
        padding-bottom: 0;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        align-items: center;

        @media (min-width: $break-mobile-landscape) {
          grid-template-columns: 1fr 46px;
          grid-gap: 40px;
        }
        @media (min-width: $break-tablet-portrait) {
          grid-template-columns: 1fr 46px 230px;
        }
        &__video {
          position: relative;
          height: 0;
          padding-bottom: 56.25%;
          width: 100%;
          display: grid;
          grid-template: 60% 10% 30%;

          & .modal-click-area {
            position: absolute;
            background: red;
            width: 100%;
            padding-bottom: 100%;
            top: 0;
            left: 0;
            opacity: 0.7;
            z-index: 100;
          }
        }
        &__close {
          transition: var(--video-animation) ease;
          opacity: 0;
          top: 20px;
          right: 20px;
          animation-name: testimonailTransistion;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
          animation-delay: 0.5s;
          & button {
            cursor: pointer;
          }
          @media (min-width: $break-mobile-landscape) {
            position: relative;
            top: 0;
            right: 0;
          }
        }
        &__text {
          color: #fff;
          position: relative;
          opacity: 0;
          animation-name: testimonailTransistionText;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-delay: 0.75s;
          @media (min-width: $break-tablet-portrait) {
            display: block;
          }
        }
      }

      & + .person {
        margin-top: calc(56.25% + 2rem);
      }
    }
  }
}

@keyframes testimonailTransistion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes testimonailTransistionText {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

<!--
<style>
body.popup-active {
  overflow: hidden;
}
</style>
-->
