<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <figure
        :class="['C', { addPadding: addPadding }]"
        v-observe-visibility="observed"
      >
        <div class="container">
          <WVimeoLoop
            v-if="blok.videoLoopUrl && blok.videoLoopUrl.vimeo_oembed"
            :vimeoUrl="blok.videoLoopUrl.vimeo_raw"
            :vimeoOembed="blok.videoLoopUrl.vimeo_oembed"
            :poster="blok.image"
          />
          <ILazyImage
            v-if="!blok.videoLoopUrl.vimeo_oembed && blok.image.filename"
            :filename="blok.image.filename"
            :alt="blok.image.alt"
            sizes="(min-width: 600px) 30vw, 100vw"
            :ratioW="16"
            :ratioH="0"
            classes="h100"
          />
        </div>
        <figcaption v-if="blok.label">{{ blok.label }}</figcaption>
      </figure>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVimeoLoop from "@/components/wrappers/W-VimeoLoop.vue";
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  name: "compImage",
  components: {
    WSection,
    WMaxWidth,
    WVimeoLoop,
    ILazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    addPadding() {
      return this.blok.settings.includes("addPadding");
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;
  .container {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 13;
  }
  &.videoRatio {
    .container {
      &:before {
        content: "";
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
      }
    }
    .W-VideoSourceSet,
    .W-PictureSourceSet {
      position: absolute;
      top: 0;
    }
  }

  video {
    height: auto;
  }
  figcaption {
    grid-column-start: 2;
    grid-column-end: 12;
    @media (min-width: $break-mobile-landscape) {
      grid-column-start: 3;
      grid-column-end: 11;
    }
    @media (min-width: $break-tablet-portrait) {
      grid-column-start: 4;
      grid-column-end: 10;
    }
    text-align: center;
  }
  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      .container {
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }
  }
}
</style>
