<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <div class="social-feed">
        <div class="social-feed__wrap">
          <div class="social-feed__wrap__text">
            <div class="social-feed__wrap__text__inner">
              <div class="social-feed__wrap__text__inner__social">
                <h3>{{ blok.twitterHeadline }}</h3>
                <p>{{ blok.twitterDescription }}</p>
                <IButton :button="button" :inverted="false"></IButton>
              </div>
              <div class="social-feed__wrap__text__inner__social">
                <h3>{{ blok.linkedinHeadline }}</h3>
                <p>{{ blok.linkedinDescription }}</p>
                <IButton :button="buttonLinkedin" :inverted="false"></IButton>
              </div>
            </div>
          </div>
          <div class="social-feed__wrap__feed">
            <Timeline
              id="TheSmi"
              data-chrome="noheader noborders nofooter"
              source-type="profile"
              widget-class="twitter-feed"
              :options="{ tweetLimit: this.blok.amount }"
            ></Timeline>
          </div>
        </div>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import { Timeline } from "vue-tweet-embed";
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import IButton from "@/components/items/I-Button.vue";
export default {
  name: "CompSocialFeed",
  components: {
    Timeline,
    WSection,
    WMaxWidth,
    IButton,
  },
  data() {
    return {
      button: {
        text: "SMI on Twitter",
        reference: {
          anchor: "",
          cached_url: "https://twitter.com/TheSmi",
          fieldtype: "multilink",
          id: "twitter",
          linktype: "url",
          url: "https://twitter.com/TheSmi",
        },
      },
      buttonLinkedin: {
        text: "SMI on Linkedin",
        reference: {
          anchor: "",
          cached_url: "https://www.linkedin.com/company/thesmi/",
          fieldtype: "multilink",
          id: "twitter",
          linktype: "url",
          url: "https://www.linkedin.com/company/thesmi/",
        },
      },
    };
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.social-feed {
  &__wrap {
    display: grid;
    grid-template-columns: 1fr;
    @extend %grid-column-gap;

    @media (min-width: $break-tablet-landscape) {
      grid-template-columns: 1fr 2fr;
    }

    &__text {
      position: relative;
      &__inner {
        position: sticky;
        top: 8rem;
        padding-right: 2rem;
        margin-left: 0;
        @extend %padding-bottom-x1;
        @media (min-width: $break-desktop-small) {
          margin-left: 25%;
        }

        & p {
          margin-bottom: 1rem;
        }

        &__social {
          @extend %padding-bottom-x1;
        }
      }
    }

    &__feed {
      & .twitter-feed {
        width: 100%;
      }
    }
  }
}
</style>

