<template>
  <main v-editable="blok" :key="blok._uid">
    <component
      v-for="(item, index) in blok.body"
      :key="item._uid"
      :blok="item"
      :isFirst="index === 0 ? true : false"
      :is="item.component"
      v-editable="item"
    />
  </main>
</template>

<script>
import CompArticle from "@/components/comps/CompArticle";
import CompBanner from "@/components/comps/CompBanner";
import CompDownloads from "@/components/comps/CompDownloads";
import CompDisclaimer from "@/components/comps/CompDisclaimer";
import CompCarousel from "@/components/comps/CompCarousel"; // Jake SMI
import CompFloatLink from "@/components/comps/CompFloatLink"; // Jake SMI
import CompImage from "@/components/comps/CompImage";
import CompListFiles from "@/components/comps/CompListFiles";
import CompListChapters from "@/components/comps/CompListChapters";
import CompListHorizontal from "@/components/comps/CompListHorizontal";
import CompListMeetings from "@/components/comps/CompListMeetings";
import CompListPeople from "@/components/comps/CompListPeople";
import CompListUrl from "@/components/comps/CompListUrl";
import CompListEmail from "@/components/comps/CompListEmail";
import CompLogoWall from "@/components/comps/CompLogoWall";
import CompNewsArchive from "@/components/comps/CompNewsArchive";
import CompBlockquoteImage from "@/components/comps/CompBlockquoteImage";
import CompBlockquoteVideo from "@/components/comps/CompBlockquoteVideo";
import CompReTv from "@/components/comps/CompReTv";
import CompStatlist from "@/components/comps/CompStatlist";
import CompTextImage from "@/components/comps/CompTextImage";
import CompTextImageSquare from "@/components/comps/CompTextImageSquare";
import CompTextVideo from "@/components/comps/CompTextVideo";
import CompVideo from "@/components/comps/CompVideo";
import CompStickyCarousell from "@/components/comps/CompStickyCarousell";
import CompStickyIntro from "@/components/comps/CompStickyIntro";
import CompSupporters from "@/components/comps/CompSupporters";
import CompGlobe from "@/components/comps/CompGlobe";
import CompSocialFeed from "@/components/comps/CompSocialFeed";
import CompWinners from "@/components/comps/CompWinners";
export default {
  name: "LayoutDefault",
  components: {
    CompArticle,
    CompBanner,
    CompDownloads,
    CompDisclaimer,
    CompCarousel,
    CompFloatLink,
    CompImage,
    CompListFiles,
    CompListChapters,
    CompListHorizontal,
    CompListMeetings,
    CompListPeople,
    CompListEmail,
    CompListUrl,
    CompLogoWall,
    CompNewsArchive,
    CompBlockquoteImage,
    CompBlockquoteVideo,
    CompReTv,
    CompStatlist,
    CompTextImage,
    CompTextImageSquare,
    CompTextVideo,
    CompVideo,
    CompStickyCarousell,
    CompStickyIntro,
    CompSupporters,
    CompGlobe,
    CompSocialFeed,
    CompWinners
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
// removes the clitch on page change, the footer want to say hi!!
main {
  min-height: 100vh;
  &[name="Contact"] {
    min-height: auto;
  }
}
</style>
