<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <div :class="['C', { 'C-grid': isGrid }]">
        <header class="C-header" v-observe-visibility="observed">
          <div class="C-content">
            <IText
              :label="label"
              :title="title"
              :countIt="countIt"
              :content="content"
              :titleH3="titleH3"
              :transition="false"
              :inverted="false"
              :fontB1="false"
            />
            <IText
              class="C-desktop"
              :inverted="false"
              :transition="false"
              :buttons="buttons"
            ></IText>
          </div>
        </header>
        <ul :class="[{ 'C-list': !isGrid }, { 'C-grid-list': isGrid }]">
          <li
            :class="[
              { 'C-list-item': !isGrid },
              { 'C-grid-list-item': isGrid },
            ]"
            v-for="listItem in listItems"
            :key="listItem._uid"
            v-observe-visibility="observed"
            v-editable="listItem"
          >
            <slot :listItem="listItem"> [list item placeholder] </slot>
          </li>
        </ul>
        <IText class="C-mobile" :inverted="false" :buttons="buttons"></IText>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WSection from "@/components/wrappers/W-Section.vue";
import IText from "@/components/items/I-Text.vue";

export default {
  name: "W-ListSimple",
  components: {
    WMaxWidth,
    WSection,
    IText,
  },
  props: {
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    titleH3: {
      type: Boolean,
      default: true,
    },
    isGrid: {
      type: Boolean,
      default: false,
    },
    countIt: {
      type: Number,
      required: false,
    },
    content: {
      type: [Object, String],
    },
    buttons: {
      type: Array,
    },
    listItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        stickyScroll: true,
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.C {
  display: grid;
  grid-template-columns: 1fr;
  @extend %grid-column-gap;
  @media (min-width: $break-tablet-landscape) {
    grid-template-columns: 1fr 2fr;
  }
  &-header {
    //hyphens: auto;
    display: block;
    align-items: center;
    @extend %padding-bottom-x1;
    margin-left: 0;
    @media (min-width: $break-desktop-small) {
      margin-left: 25%;
    }
  }
  &-content {
    position: sticky;
    top: $nav-height;
    margin-right: 2rem;
  }
  &-list {
    &-item {
      border-bottom: 1px solid $color-blue-lighter;
      padding-bottom: 2rem;
      &:not(:first-child) {
        padding-top: 3rem;
      }
      @media (min-width: $break-tablet-portrait) {
        padding-bottom: 3rem;
        &:not(:first-child) {
          padding-top: 5rem;
        }
      }
    }
  }
  &-grid {
    &-list {
      display: grid;
      grid-template-columns: 1fr;
      @extend %grid-column-gap;

      @media (min-width: $break-mobile-landscape) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: $break-mobile-portrait + ($break-mobile-landscape - $break-mobile-portrait) / 2) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: $break-tablet-landscape) {
        grid-template-columns: repeat(3, 1fr);
      }
      &-item {
        padding: 3em 0;
      }
    }
  }
  &-desktop {
    display: none;
    @media (min-width: $break-tablet-landscape) {
      display: block;
    }
  }
  &-mobile {
    display: block;
    @media (min-width: $break-tablet-landscape) {
      display: none;
    }
  }
}
</style>
