<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <div
        ref="component"
        :class="[
          'C',
          {
            addPadding: addPadding,
            videoOnLeftSide: videoOnLeftSide,
            playing: playing,
            loading: loading,
            unGrid: unGrid,
            transitioning: transitioning,
            videoOpen: videoOpen,
          },
        ]"
      >
        <div class="poster-size-reference" ref="posterSizeReference"></div>
        <WAspectRatio
          v-if="
            blok.videoUrl &&
            blok.videoUrl.vimeo_data &&
            blok.videoUrl.vimeo_data.id
          "
        >
          <WVimeoSource
            v-if="onLoading"
            ref="vimeoSource"
            :title="label"
            :vimeoId="blok.videoUrl.vimeo_data.id"
            v-on:playing="onPlaying"
            v-on:loading="onLoading"
          />
        </WAspectRatio>
        <section>
          <IText
            :label="blok.label"
            :title="blok.title"
            :content="blok.content"
            :inverted="false"
            :fontB1="true"
          />
        </section>
        <section
          ref="videoPoster"
          :style="{
            '--poster-left': posterLeft + 'px',
            '--poster-width': posterWidth + 'px',
          }"
        >
          <div v-observe-visibility="observed">
            <WVideoPoster
              :posterImage="posterImage"
              :showPoster="!playing"
              :loading="loading"
              v-on:play="onPlay"
              :sizes="posterSizes"
            />
          </div>
        </section>
      </div>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVideoPoster from "@/components/wrappers/W-VideoPoster.vue";
import WVimeoSource from "@/components/wrappers/W-VimeoSource.vue";
import WAspectRatio from "@/components/wrappers/W-AspectRatio.vue";
import IText from "@/components/items/I-Text.vue";
export default {
  name: "compTextVideo",
  components: {
    WSection,
    WMaxWidth,
    WVideoPoster,
    WVimeoSource,
    WAspectRatio,
    IText,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
      unGrid: false,
      transitioning: false,
      posterLeft: 0,
      posterWidth: 0,
      videoOpen: false,
      loading: false,
    };
  },
  computed: {
    label() {
      return this.blok.label;
    },
    addPadding() {
      return this.blok.settings.includes("addPadding");
    },
    videoOnLeftSide() {
      return this.blok.videoOnLeftSide;
    },
    posterImage() {
      return this.blok.videoPosterImage;
    },
    icon() {
      return this.blok.icon;
    },
    content() {
      return this.blok.content;
    },
    buttons() {
      return this.blok.buttons;
    },
    citation() {
      return this.blok.citation;
    },
    posterSizes() {
      return `
          (min-width: ${this.$breakpoints.widescreen}px) ${
        this.$breakpoints.widescreen * 0.045
      }rem,
          (min-width: ${this.$breakpoints.mobileLandscape}px) 45vw,
          95vw
        `;
    },
  },
  watch: {
    playing(val) {
      if (!val) {
        this.unGrid = true;
        this.transitioning = true;
        this.videoOpen = true;
        this.setVideoPos();
        setTimeout(() => {
          this.transitioning = true;
          this.videoOpen = false;
          this.setPosterPos();
          setTimeout(() => {
            this.transitioning = false;
            this.unGrid = false;
            this.posterLeft = 0;
            this.posterwidth = 0;
          }, 500);
        }, 500);
      }
    },
  },
  mounted() {
    //console.log(this.component)
  },
  activated() {
    // Only used if keep-alive is used
  },
  deactivated() {
    // Only used if keep-alive is used
  },
  methods: {
    onPlaying(val) {
      this.playing = val;
    },
    onLoading(val) {
      this.loading = val;
    },
    onPlay() {
      this.$refs.vimeoSource.showLoader();
      this.unGrid = true;
      this.transitioning = false;
      this.videoOpen = false;
      this.setPosterPos();
      setTimeout(() => {
        this.transitioning = true;
        this.videoOpen = true;
        this.setVideoPos();
        setTimeout(() => {
          this.$refs.vimeoSource.play();
        }, 500);
      }, 0);
    },
    setPosterPos() {
      const componentRef = this.$refs.component.getBoundingClientRect();
      const posterRef = this.$refs.posterSizeReference.getBoundingClientRect();
      this.posterLeft = posterRef.left - componentRef.left;
      this.posterWidth = posterRef.width;
    },
    setVideoPos() {
      const componentRef = this.$refs.component.getBoundingClientRect();
      const videoRef = this.$refs.vimeoSource.$el.getBoundingClientRect();
      this.posterLeft = videoRef.left - componentRef.left;
      this.posterWidth = videoRef.width;
    },
  },
};
</script>

<style lang="scss" scoped>
.C-TextVideo {
  overflow: hidden;
}
.C {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  grid-template-areas:
    "se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1 se1"
    "se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2 se2";

  @media (min-width: $break-mobile-landscape) {
    grid-template-areas: "se1 se1 se1 se1 se1 se1  .  se2 se2 se2 se2 se2";

    &.videoOnLeftSide {
      grid-template-areas: "se2 se2 se2 se2 se2  .  se1 se1 se1 se1 se1 se1";
    }
  }

  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      grid-template-areas: " .  se1 se1 se1 se1 se1  .  se2 se2 se2 se2  . ";
      &.videoOnLeftSide {
        grid-template-areas: " .  se2 se2 se2 se2  .  se1 se1 se1 se1 se1  . ";
      }
    }
  }
  .W-AspectRatio {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 13;
    pointer-events: none;
    transition: opacity 0s 0.5s cubic-bezier($ease-out-cubic),
      transform 500ms cubic-bezier($ease-out-cubic);
    opacity: 0;
    @media (min-width: $break-mobile-landscape) {
      grid-row: 1;
    }
  }
  .W-VideoPoster {
    transition: transform 500ms cubic-bezier($ease-out-cubic);
  }
  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      .W-AspectRatio {
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }
  }
  section {
    position: relative;
    &:first-of-type {
      grid-area: se1;
      @extend %margin-top-bottom-x1;
      display: flex;
      align-items: center;
      transition: transform 0.5s cubic-bezier($ease-out-cubic),
        opacity 0.5s cubic-bezier($ease-out-cubic);
    }
    &:last-of-type {
      grid-area: se2;
      > div {
        height: 100%;
      }
    }
  }
  .poster-size-reference {
    pointer-events: none;
    grid-area: se2;
  }

  &.unGrid {
    section {
      &:last-of-type {
        @media (min-width: $break-mobile-landscape) {
          position: absolute;
          top: 0;
          height: 100%;
          grid-area: auto;
          transform: translate3d(var(--poster-left), 0, 0);
          width: var(--poster-width);
        }
      }
    }
  }
  &.videoOpen {
    section {
      pointer-events: none;
      &:first-of-type {
        transition: transform 0.5s cubic-bezier($ease-out-cubic),
          opacity 0.5s cubic-bezier($ease-out-cubic);
        @media (min-width: $break-mobile-landscape) {
          transform: translate3d(-120%, 0, 0);
          opacity: 0;
        }
      }
    }
  }
  &.transitioning {
    &.unGrid {
      section {
        &:last-of-type {
          transition: transform 0.5s cubic-bezier(($ease-out-cubic)),
            width 0.5s cubic-bezier(($ease-out-cubic));
        }
      }
    }
  }
  &.playing {
    .W-AspectRatio {
      transition: opacity 0s cubic-bezier($ease-out-cubic),
        transform 500ms cubic-bezier($ease-out-cubic);
      opacity: 1;
      transform: scale(1.13);
      pointer-events: auto;
      @media (min-width: $break-mobile-landscape) {
        transform: none;
      }
    }
    .W-VideoPoster {
      transform: scale(1.13);
      @media (min-width: $break-mobile-landscape) {
        transform: none;
      }
    }
    section {
      &:last-of-type {
      }
    }
  }
}
</style>
